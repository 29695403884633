import React, { FunctionComponent, useEffect, useState } from "react";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import Select from "../../../../global/atoms/Select";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import Button from "rsuite/Button";
import VisitsReportConnection from "../../../../utils/connections/visitsReport";
import { useHistory, useParams } from "react-router-dom";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import LatestReports from "./LatestReports";
import SelectVisitsList, { MAX_VISITS_RESPONSE } from "./SelectVisitsList";
import { ToastNotificationPush, ToastTypes } from "global/ToastNotification";
import { handleToast } from "utils/helpers";
import { Col, IconButton, Message, Row, SelectPicker } from "rsuite";
import { ISelect, IVisitStatus } from "utils/models";
import EditIcon from "@rsuite/icons/Edit";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Peoples from "@rsuite/icons/Peoples";
import CharacterLock from "@rsuite/icons/CharacterLock";
import FormControlLabel from "rsuite/FormControlLabel";
import InfoOutline from "@rsuite/icons/InfoOutline";
import TrashIcon from "@rsuite/icons/Trash";
import { confirmModalHandler } from "../../../../redux/store";

export interface IVisitsReportSave {
  templateId: string;
  startDate: string;
  endDate: string;
  projectId: string;
  format: "xlsx" | "csv";
  // filters: IFilters;
  selectedVisits: string[];
}

export interface IVisitsReportForm {
  templates: ISelect;
  disabledElements: Array<string>;
  tasks: ISelect<{
    id: string;
    name: string;
    activities: Array<{
      id: string;
      name: string;
    }>;
  }>;
  voivodeships: ISelect<{
    id: string;
    name: string;
    locale: string;
  }>;
  projectMerchRoles: ISelect;
  users: ISelect<{
    roleName: string;
    roleId: string;
    username: string;
    userRoleId: string;
    userId: string;
    locationId: string;
    location: string;
  }>;
  networks: ISelect<{
    id: string;
    name: string;
    type: string;
  }>;
  visitStatus: ISelect<IVisitStatus>;
  locationCodes: ISelect;
}

const VisitsReport: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<IVisitsReportSave>({
    templateId: "",
    startDate: "",
    endDate: "",
    projectId: id,
    format: "xlsx",
    // filters: getDefaultFiltersState(id),
    selectedVisits: [],
  });
  const [form, setForm] = useState<IVisitsReportForm | null>(null);
  const history = useHistory();
  const [chosenVisits, setChosenVisits] = useState<Array<string>>([]);
  const [reloadReports, setReloadReports] = useState<boolean>(false);
  const [visitsCount, setVisitsCount] = useState<number>(0);
  // const [filters, setFilters] = useState<IFilters>(getDefaultFiltersState(id));

  useEffect(() => {
    VisitsReportConnection.getGenerateReportViewData(id).then((response) => {
      if (response?.data.templates.options.length > 0) {
        setState({
          ...state,
          ...{
            templateId: response?.data.templates.options[0].id,
          },
        });
      }
      setForm(response?.data);
    });
  }, []);

  const handleEditTemplate = () => {
    if (state.templateId == "") return;
    history.push(
      `/projects/${id}/reports-visits/template/edit/${state.templateId}`
    );
  };

  const handleAddTemplate = () => {
    history.push(`/projects/${id}/reports-visits/template/add`);
  };

  const handleGenerateReport = () => {
    if (state.templateId === "") {
      return false;
    }

    if (visitsCount <= MAX_VISITS_RESPONSE && chosenVisits.length <= 0) {
      ToastNotificationPush(ToastTypes.error, "Nie wybrano wizyt");
      return false;
    }

    const stateClone = { ...state };
    // stateClone.filters = filters;
    stateClone.selectedVisits = chosenVisits;
    handleToast(VisitsReportConnection.saveReport(stateClone)).then(() => {
      setReloadReports(true);
    });
  };

  const handleDeleteTemplate = () => {
    if (!state.templateId) return;
    const template = form?.templates.options.find(
      (t) => t.id == state.templateId
    );
    if (template?.disabledElements?.includes("deleteTemplateDisabled")) {
      ToastNotificationPush(ToastTypes.error, "Nie można usunąć tego szablonu");
      return;
    }
    handleToast(VisitsReportConnection.deleteTemplate(state.templateId))
      .then(() => {
        ToastNotificationPush(
          ToastTypes.success,
          "Szablon został trwale usunięty"
        );
        setState((s) => ({
          ...s,
          templateId: "",
        }));
        setForm((f) =>
          f
            ? {
                ...f,
                templates: {
                  ...f.templates,
                  options: f.templates.options.filter(
                    (t) => t.id != state.templateId
                  ),
                },
              }
            : null
        );
      })
      .catch(() => {
        ToastNotificationPush(
          ToastTypes.error,
          "Nie udało się usunąć szablonu"
        );
      });
  };

  if (form === null) return <></>;
  const isEditDisabled = () => {
    if (!state.templateId) return true;
    return form.templates.options
      .find((t) => t.id == state.templateId)
      ?.disabledElements?.includes("editTemplateDisabled");
  };
  const isTemplateDeleteDisabled = () => {
    if (!state.templateId) return true;
    return form.templates.options
      .find((t) => t.id == state.templateId)
      ?.disabledElements?.includes("deleteTemplateDisabled");
  };

  const isAddNewDisabled = () => {
    return !!form?.disabledElements?.includes("addNewTemplateDisabled");
  };
  return (
    <>
      <SelectVisitsList
        form={form}
        chosenVisits={chosenVisits}
        setChosenVisits={setChosenVisits}
        setVisitsCount={setVisitsCount}
        visitsCount={visitsCount}
      />

      <SeparatorEmpty />

      <WhiteCard padding={true}>
        <Row style={{ display: "flex", alignItems: "flex-end" }}>
          <Col xs={24} md={8}>
            <FormControlLabel>Wybierz szablon</FormControlLabel>
            <SelectPicker
              block
              size={"sm"}
              placeholder={"Wybierz szablon"}
              name={"templateId"}
              value={state.templateId}
              data={form.templates.options}
              valueKey={"id"}
              labelKey={"name"}
              renderMenuItem={(label, item) => {
                return (
                  <div>
                    {item?.isGlobal ? <Peoples /> : <CharacterLock />}&nbsp;
                    {label}{" "}
                  </div>
                );
              }}
              //@ts-ignore
              renderValue={(
                value,
                item: { name: string; isGlobal: boolean }
              ) => {
                return (
                  <>
                    {item?.isGlobal ? <Peoples /> : <CharacterLock />}&nbsp;
                    {item?.name}
                  </>
                );
              }}
              onChange={(value) => {
                setState((s) => ({ ...s, templateId: value ?? "" }));
              }}
            />
          </Col>
          <Col xs={24} md={6}>
            <ActionsContainer style={{ justifyContent: "flex-start" }}>
              <IconButton
                size={"sm"}
                icon={isEditDisabled() ? <InfoOutline /> : <EditIcon />}
                appearance={!isEditDisabled() ? "ghost" : "default"}
                disabled={state.templateId === ""}
                onClick={handleEditTemplate}>
                {isEditDisabled() ? "Podgląd" : "Edytuj"}
              </IconButton>
              <IconButton
                size={"sm"}
                disabled={isAddNewDisabled()}
                icon={<AddOutlineIcon />}
                appearance={!isAddNewDisabled() ? "ghost" : "default"}
                onClick={handleAddTemplate}>
                Dodaj nowy
              </IconButton>

              <IconButton
                size={"sm"}
                icon={<TrashIcon />}
                appearance={!isTemplateDeleteDisabled() ? "ghost" : "default"}
                disabled={isTemplateDeleteDisabled()}
                onClick={confirmModalHandler(
                  <Message
                    style={{ textAlign: "left" }}
                    showIcon
                    type={"warning"}
                    header={`Uwaga! szablon zostanie trwale usunięty`}>
                    <b>
                      {
                        form.templates.options?.find(
                          (t) => t.id == state.templateId
                        )?.name
                      }
                      :
                    </b>
                    <br />
                    Czy na pewno chcesz usunąć ten szablon?
                  </Message>,
                  handleDeleteTemplate
                )}>
                Usuń
              </IconButton>
            </ActionsContainer>
          </Col>
          <Col md={4} xs={24}>
            <Select
              size={"sm"}
              placeholder={"Format"}
              name={"format"}
              value={state.format}
              options={[
                { id: "xlsx", name: "xlsx" },
                // { id: 'csv', name: 'csv' },
              ]}
              setState={setState}
              state={state}
            />
          </Col>
          <Col md={6} xs={24}>
            Liczba wizyt w raporcie: <strong>{chosenVisits.length}</strong>
            <Button
              block
              size={"sm"}
              disabled={chosenVisits.length === 0}
              appearance={"primary"}
              onClick={handleGenerateReport}>
              Wygeneruj raport
            </Button>
          </Col>
        </Row>
      </WhiteCard>
      <SeparatorEmpty />
      <LatestReports
        reloadReports={reloadReports}
        setReloadReports={setReloadReports}
      />
      <div>&nbsp;</div>
    </>
  );
};

export default VisitsReport;
