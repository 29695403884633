import React, { useState } from "react";
import { FunctionComponent } from "react";
import TargetConnections from "./TargetConnections";
import { _paginate30 } from "../../../../utils/states";
import { IHeader, IPagination, ISort } from "../../../../utils/models";
import { generatePath, useHistory, useParams } from "react-router-dom";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import { handleSortColumn, handleToast } from "../../../../utils/helpers";

import PaginatedTableViewWrapper from "../../../../global/PaginatedTableViewWrapper";
import Table from "rsuite/Table";
import {
  BatchActionDropdownItem,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../utils/toggleToDeleteHelper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import styles from "../warehouse/styles.module.scss";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import OptionsDropdown from "../../../../global/atoms/OptionsDropdown";
import { Dropdown } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PlusIcon from "@rsuite/icons/Plus";
import dayjs from "dayjs";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import DatePickerSlider from "./DatePickerSlider";

export interface ITargetDefinitionsFilters {
  name: string;
  currentDate: string | null;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

interface ITargetDefinitionData {
  id: string;
  name: string;
  description: string;
  type: string;
  status: string;
  startDate: string;
  endDate: string;
}

interface ITargetDefinitionsData {
  columns: any;
  header: IHeader;
  data: Array<ITargetDefinitionData>;
  count: number;
}

const TargetsDefinitionsList: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const defaultFilters: ITargetDefinitionsFilters = {
    name: "",
    currentDate: dayjs().format("YYYY-MM"),
    ..._paginate30,
  };

  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<ITargetDefinitionsData>();
  const [filters, setFilters] =
    useState<ITargetDefinitionsFilters>(defaultFilters);
  const [form, setForm] = useState<any>(null);
  const [chosenTargetDefinitions, setChosenTargetDefinitions] = useState<
    Array<string>
  >([]);
  const history = useHistory();

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenTargetDefinitions.map((id) => ({ id: id }));
    if (deleteId !== undefined) toDelete = [{ id: deleteId }];
    handleToast(TargetConnections.removeTargetDefinition(id, toDelete)).then(
      () => {
        setChosenTargetDefinitions([]);
        setTriggerLoad(Date.now());
      }
    );
  };
  return (
    <>
      <HeaderButtons>
        <DatePickerSlider
          name={"currentDate"}
          setData={setFilters}
          format="yyyy-MM"
          placeholder="Bieżący miesiąc"
          value={
            filters.currentDate
              ? dayjs(filters.currentDate, "YYYY-MM").toDate()
              : null
          }
          reloadTrigger={() => {
            setTriggerLoad(Date.now());
          }}
          ranges={[{ label: "Bieżący miesiąc", value: dayjs().toDate() }]}
        />
        <OptionsDropdown>
          <Dropdown.Item
            onClick={() =>
              history.push(
                generatePath("/projects/:id/targets/add", {
                  id: id,
                })
              )
            }
            icon={<PlusIcon />}>
            Dodaj nową definicję...
          </Dropdown.Item>
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            icon={<TrashIcon />}
            chosenRows={chosenTargetDefinitions}
            title={`Usuń zaznaczone definicje targetów (${chosenTargetDefinitions.length})`}
            message={
              "Czy na pewno chcesz usunąć zaznaczone definicje targetów?"
            }
          />
        </OptionsDropdown>
      </HeaderButtons>
      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column width={40} align="left">
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data?.data}
                  state={chosenTargetDefinitions}
                  setState={setChosenTargetDefinitions}
                  keyIdName={"id"}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ToggleSingleCheckbox
                    state={chosenTargetDefinitions}
                    setState={setChosenTargetDefinitions}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2}>
              <Table.HeaderCell>
                <div>
                  <div>Nazwa</div>
                  <div className={styles.style_header}>Utworzono/przez</div>
                </div>
              </Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) => (
                  <>
                    <ButtonListRedirect
                      redirectUrl={generatePath(
                        "/projects/:id/targets/:targetId",
                        {
                          id: id,
                          targetId: rowData.id,
                        }
                      )}>
                      {rowData.name}
                    </ButtonListRedirect>
                    <div
                      style={{
                        position: "absolute",
                        display: "block",
                        color: "rgba(134,134,134,0.48)",
                        fontSize: "0.7em",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}>
                      {rowData.updatedAt} {rowData.updateUserName}
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="type">
                {(rowData) => {
                  return (
                    <>
                      <div>{rowData.type.name}</div>
                      <div className={styles.style}>&nbsp;</div>
                    </>
                  );
                }}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Aktywne od</Table.HeaderCell>
              <Table.Cell dataKey="startDate" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Aktywne do</Table.HeaderCell>
              <Table.Cell dataKey="endDate" />
            </Table.Column>

            <Table.Column width={140} align="center">
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="status">
                {(rowData) => (
                  <BadgeStatus color={rowData.status.color}>
                    {rowData.status.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={140} align="center">
              <Table.HeaderCell>Cykliczny</Table.HeaderCell>
              <Table.Cell>
                {(rowData) =>
                  rowData.isRefreshNextMonth ? (
                    <CheckOutlineIcon style={{ color: "E09616" }} />
                  ) : (
                    <CloseOutlineIcon style={{ color: "E09616" }} />
                  )
                }
              </Table.Cell>
            </Table.Column>
            <Table.Column width={140} align="center">
              <Table.HeaderCell>Cykl</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <BadgeStatus color={rowData.periodType.color}>
                    {rowData.periodType.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column fixed="right" width={70} align="center">
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <DeleteSingleButton
                      callback={handleDeleteSelected.bind(null, rowData.id)}
                      form={form}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />

      <Pagination
        count={data?.count ?? 0}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />

      <UniversalFilters
        load={TargetConnections.getTargetDefinitions.bind(null, id)}
        formGet={TargetConnections.getTargetDefinitionsFilterForm.bind(
          null,
          id
        )}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`target-definition-list`}
        getFormCallback={(_form) => setForm(_form)}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default TargetsDefinitionsList;
