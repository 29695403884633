import {
  Checkbox,
  ColumnProps,
  IconButton,
  Input,
  InputGroup,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import ReloadIcon from "@rsuite/icons/Reload";
import CloseIcon from "@rsuite/icons/Close";
import SearchIcon from "@rsuite/icons/Search";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import FunnelIcon from "@rsuite/icons/Funnel";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import { cloneDeep, isEqual } from "lodash";
import { FCC, SetState } from "../../../utils/models";
import { validate } from "uuid";
export const getFromStorage = (storageFilterKey: string) => {
  try {
    return JSON.parse(localStorage.getItem(storageFilterKey) ?? "{}");
  } catch (error) {
    console.warn(error);
  }
  return {};
};

export const getFiltersFromStorage = (storageFilterKey: string) => {
  return getFromStorage(storageFilterKey).filters;
};

export const getColumnsFromStorage = (storageFilterKey: string) => {
  return getFromStorage(storageFilterKey).columns;
};

interface IColumn {
  name: string;
  label: string | ReactElement;
  dataKey?: string;
  props?: ColumnProps;
  filter?: any;
  filterKey?: string;
  multiselect?: boolean;
  defaultVisible?: boolean;
  visible?: boolean;
}

interface ITableColumnFilter {
  column: IColumn;
  filters: any;
  setFilters: SetState;
  filtersData: any;
}

interface IBaseOption {
  id: string;
  name: string;
}

const TableColumnFilter: FC<ITableColumnFilter> = (props) => {
  const filterKey = props.column.filterKey ?? "";
  if (!filterKey)
    console.warn("Filter key is not defined for column: ", props.column.name);
  const [textFilter, setTextFilter] = useState<string>("");
  const [selectedFilters, setSelectedFilters] = useState<
    Array<string> | undefined
  >(props.filters?.[filterKey]);

  const handleFilterChange = (value: ValueType, checked: boolean) => {
    if (!filterKey) return;
    let filterDataCurrent: Array<string> = cloneDeep(selectedFilters) || [];

    if (checked) {
      filterDataCurrent.push(value as unknown as string);
    } else {
      filterDataCurrent = filterDataCurrent.filter((item) => item !== value);
    }
    setSelectedFilters(filterDataCurrent);
  };

  if (!filterKey) return <></>;
  return (
    <>
      <Whisper
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {
          setTextFilter("");
          props.setFilters((f) => ({
            ...f,
            [filterKey]: selectedFilters,
          }));
        }}
        placement="bottomStart"
        trigger="click"
        enterable
        controlId={props.column.name}
        speaker={
          <Popover
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                {props.column.label}
                <div style={{ display: "flex", gap: "5px" }}>
                  <IconButton
                    size={"xs"}
                    appearance={"ghost"}
                    icon={<CloseOutlineIcon />}
                    onClick={() => {
                      props.setFilters((f) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { [filterKey]: removed, ...rest } = f;
                        return rest;
                      });
                      setSelectedFilters(undefined);
                    }}
                  />
                  <IconButton
                    size={"xs"}
                    appearance={"ghost"}
                    icon={<ReloadIcon />}
                    onClick={() => {
                      props.setFilters((f) => ({
                        ...f,
                        [filterKey]: selectedFilters,
                      }));
                    }}
                  />
                </div>
              </div>
            }
            onClick={(event) => event.stopPropagation()}>
            <InputGroup style={{ marginBottom: "5px" }} size={"xs"}>
              <Input
                placeholder={"wyszukaj..."}
                value={textFilter}
                onChange={(value) => {
                  setTextFilter(value);
                }}
              />
              {textFilter && (
                <InputGroup.Addon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTextFilter("");
                  }}>
                  <CloseIcon color={"#E09616"} />
                </InputGroup.Addon>
              )}
              {!textFilter && (
                <InputGroup.Addon>
                  <SearchIcon color={"#E09616"} />
                </InputGroup.Addon>
              )}
            </InputGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "300px",
                overflow: "auto",
              }}>
              {(
                (
                  props.filtersData?.[props.column.filter]?.options ?? []
                ).filter((o) =>
                  textFilter
                    ? o.name.toLowerCase().includes(textFilter?.toLowerCase())
                    : true
                ) ?? []
              ).map((option: IBaseOption) => (
                <Checkbox
                  value={option.id}
                  checked={(selectedFilters ?? []).includes(option.id)}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(value, checked) => {
                    handleFilterChange(value, checked);
                  }}
                  key={option.id}>
                  {option.name}
                </Checkbox>
              ))}
              <SeparatorEmpty />
            </div>
          </Popover>
        }>
        <FunnelIcon color={selectedFilters ? "#E09616" : "inherit"} />
      </Whisper>
    </>
  );
};

interface ITableFilterState {
  storageFilterKey: string;
  columns: IColumn[];
  setColumns: SetState;
  filters: any;
  setFilters: SetState;
  loadData: () => void;
}

/** This component is responsible for managing and applying filters to the table columns.*/
/** It retrieves the filter configuration from localStorage and updates the filters and columns accordingly. */
const TableFilter: FCC<ITableFilterState> = (props) => {
  const [internalFilterState, setInternalFilterState] = useState<any>(
    props?.filters
  );

  useEffect(() => {
    props.filters && props.loadData?.();
  }, []);

  useEffect(() => {
    const filterState = {
      columns: props.columns
        .filter((c) => !validate(c.name))
        .map((c) => ({
          name: c.name,
          label: c.label,
          visible: c.visible ?? c.defaultVisible,
          props: { width: c.props?.width },
        })),
      filters: props.filters,
    };
    localStorage.setItem(props.storageFilterKey, JSON.stringify(filterState));
    if (!isEqual(internalFilterState, props.filters)) {
      setInternalFilterState(props.filters);
      props.filters && props.loadData?.();
    }
  }, [props.columns, props.filters]);

  return <>{props.children}</>;
};

const TableComponents = {
  Table: Table,
  Column: Table.Column,
  HeaderCell: Table.HeaderCell,
  Cell: Table.Cell,
};

const TableFilters = {
  TableFilter: TableFilter,
  ColumnFilter: TableColumnFilter,
};

const TableFiltered = {
  ...TableComponents,
  ...TableFilters,
};

export default TableFiltered;
