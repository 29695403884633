import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "rsuite/Modal";
import { Col, DatePicker, IconButton, Nav, SelectPicker } from "rsuite";
import { IModalCellData, ScheduleInterval } from "../Schedule";
import { IUserSimple } from "../../../utils/models";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import SchedulesConnection from "utils/connections/schedules";
import SpinnerSmall from "global/atoms/Spinner/SpinnerSmall";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import FormControlLabel from "rsuite/FormControlLabel";
import customParseFormat from "dayjs/plugin/customParseFormat";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import FormErrorMessage from "rsuite/FormErrorMessage";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import ToastNotification, {
  ToastTypes,
} from "../../../global/ToastNotification";
import ActionsContainer from "../../../global/atoms/ActionsContainer";
import scheduleHoursSinglePicker, {
  scheduleHoursRecurringPicker,
} from "./ScheduleModalElements/ScheduleHoursSinglePicker";
import {
  COMMON_FOR_WEEK,
  DAILY,
  HOURS_EXPLICITLY,
  parseData,
  validateHoursData,
} from "./ScheduleModalElements/functions";

dayjs.locale(localePl);
dayjs.extend(customParseFormat);

interface IScheduleModal {
  modalCellData: IModalCellData | null;
  onModalClose: () => void;
  onScheduleRemove: () => void;
  onModalSubmit: () => void;
  requestData: IScheduleRequestData;
}

export interface IHourData {
  day?: number;
  hours?: number | string | null;
  start?: string | null;
  end?: string | null;
  type: string;
}

export interface IScheduleModalData {
  type: ScheduleInterval;
  startDate: Date | null;
  endDate: Date | null | undefined;
  fromDate: Date | null | undefined;
  days: Array<number>;
  projectId: string;
  taskId: string;
  locationId: string;
  userId: string;
  hours?: Array<IHourData>;
  isApproved: boolean;
  users?: Array<IUserSimple>;
}

const DEFAULT_DAY = {
  day: undefined,
  hours: undefined,
  start: undefined,
  end: undefined,
  type: HOURS_EXPLICITLY,
};

const ScheduleModal: FunctionComponent<IScheduleModal> = (props) => {
  const [errors, setErrors] = useState<any>(null);
  const [modalData, setModalData] = useState<IScheduleModalData | null>(null);
  const [hoursData, setHoursData] = useState<Array<IHourData> | null>(null);
  const [hoursDataSingle, setHoursDataSingle] = useState<IHourData | null>(
    null
  );
  const [open, setOpen] = useState(false);

  const hoursStrategy = props.modalCellData?.hoursStrategy ?? DAILY;
  /** `isEnabled=true` when `props.modalData.type` in create or modify mode*/
  const isEnabled = ["create", "modify"].includes(
    props?.modalCellData?.type || ""
  );
  /** `isModify=true` when `props.modalData.type` in modify mode*/
  const isModify = ["modify"].includes(props?.modalCellData?.type || "");
  /** `isCreate=true` when `props.modalData.type` is in create mode */
  const isCreate = ["create"].includes(props?.modalCellData?.type || "");
  /** `isisView=true` when `props.modalData.type` is in preview mode */
  const isView = ["view"].includes(props?.modalCellData?.type || "");

  const scheduleType = modalData?.type ?? ScheduleInterval.Single;
  useEffect(() => {
    if (props.modalCellData && (isEnabled || isView)) {
      setOpen(true);
      SchedulesConnection.modalData(
        props.modalCellData.rowId,
        props.modalCellData.timeTableId
      ).then((res) => {
        const tmp = res.data;
        if (!props.modalCellData?.timeTableId && props.modalCellData?.date) {
          tmp.startDate = props.modalCellData.date;
        }
        // tmp fix - date parser not applying for some reason on that endpoint
        if (typeof tmp.startDate !== "object" && tmp.startDate)
          tmp.startDate = dayjs(tmp.startDate).toDate();
        if (typeof tmp.endDate !== "object" && tmp.endDate)
          tmp.endDate = dayjs(tmp.endDate).toDate();
        tmp.days = Object.values(tmp.days);
        setModalData(tmp);
        if (tmp?.type === ScheduleInterval.Single)
          setHoursDataSingle((tmp?.hours ?? [])[0] ?? DEFAULT_DAY);
        else {
          setHoursData(tmp.hours ?? []);
          /** `setHoursDataSingle` is set to first day of the week */
          if (hoursStrategy == COMMON_FOR_WEEK) {
            setHoursDataSingle((tmp?.hours ?? [])[0] ?? DEFAULT_DAY);
          }
        }
      });
    } else {
      setOpen(false);
      setModalData(null);
      setHoursData(null);
      setHoursDataSingle(null);
    }
  }, [props.modalCellData]);

  const isReadyToSave = (): boolean => {
    if (!modalData) return false;
    if (!modalData.userId) return false;
    if (!modalData.projectId) return false;
    if (!modalData?.taskId) return false;

    if (modalData.type !== ScheduleInterval.Single) {
      // recur schedule
      if (!modalData.days?.length) return false; // no-days selected in recur schedule
      if (!modalData.startDate) return false; // empty start date
    }

    return true;
  };

  const updateState = (value: Partial<IScheduleModalData>) => {
    setModalData((s) => {
      if (!s) return s;
      return { ...s, ...value };
    });
  };

  useEffect(() => {
    if (
      hoursStrategy == COMMON_FOR_WEEK &&
      modalData &&
      modalData.type !== ScheduleInterval.Single
    ) {
      setHoursData(() => {
        return modalData.days.map((d) => {
          return { ...hoursDataSingle, day: d } as IHourData;
        });
      });
    }
  }, [hoursDataSingle, modalData?.days, hoursStrategy]);

  useEffect(() => {
    validateHoursData(
      setErrors,
      modalData?.type ?? ScheduleInterval.Single,
      hoursDataSingle,
      hoursData
    );
  }, [hoursDataSingle, hoursData, modalData?.type]);

  const parseErrors = (err, data) => {
    {
      if (err.response.data.message) {
        ToastNotification(ToastTypes.warning, err.response.data.message);
      }
      if (err.response.status == 400) {
        ToastNotification(ToastTypes.warning, "Formularz zawiera błędy");
        const _errors = err.response.data.errors;
        if (_errors.hours) {
          const mappedErrors = data.days.map((day: number, index: number) => ({
            day: day,
            ...(_errors.hours[index] ?? []),
          }));
          setErrors({ ..._errors, hours: mappedErrors });
        } else {
          setErrors(err.response.data.errors);
        }
      }
    }
  };

  const handleSubmitAdd = () => {
    if (errors) {
      ToastNotification(ToastTypes.warning, "Formularz zawiera błędy");
      return;
    }
    const _data = parseData(
      scheduleType,
      hoursDataSingle,
      hoursData,
      modalData,
      props.modalCellData?.date
    );
    SchedulesConnection.add(_data)
      .then((response) => {
        if (response.status == 200) {
          ToastNotification(ToastTypes.info, "Harmonogram został dodany");
          props.onModalSubmit?.();
        }
      })
      .catch((err) => {
        {
          if (err.response.data.message) {
            ToastNotification(ToastTypes.warning, err.response.data.message);
          }
          if (err.response.status == 400) {
            ToastNotification(ToastTypes.warning, "Formularz zawiera błędy");
            setErrors((e) => ({
              ...e,
              ...err.response.data.errors,
              hours: err.response.data.errors.hours[0],
            }));
          }
        }
      });
  };
  const handleSave = () => {
    if (errors) {
      ToastNotification(ToastTypes.warning, "Formularz zawiera błędy");
      return;
    }

    if (modalData && props?.modalCellData?.timeTableId) {
      const _data = parseData(
        scheduleType,
        hoursDataSingle,
        hoursData,
        modalData,
        props.modalCellData?.date
      );
      SchedulesConnection.scheduleUpdate({
        ..._data,
        id: props.modalCellData?.timeTableId,
      })
        .then((response) => {
          if (response.status == 200) {
            ToastNotification(ToastTypes.info, "Zmiany zostały zapisane");
            props.onModalSubmit?.();
          }
        })
        .catch((err) => parseErrors(err, _data));
    }
  };

  return (
    <>
      <Modal
        open={open}
        size={"sm"}
        onExited={props.onModalClose}
        onClose={() => setOpen(false)}>
        <Modal.Header>
          <h4>
            {isCreate && "Nowy harmonogram"}
            {isModify && "Edycja harmonogramu"}
            {!isEnabled && "Podgląd harmonogramu"}
          </h4>
        </Modal.Header>

        <Modal.Body>
          <>
            {!modalData ? (
              <SpinnerSmall />
            ) : (
              <>
                {!isView && !isModify && (
                  <Col xs={24}>
                    <Nav
                      appearance="default"
                      justified
                      onSelect={(eventKey: string) =>
                        updateState({
                          type:
                            eventKey === "single"
                              ? ScheduleInterval.Single
                              : ScheduleInterval.Weekly,
                        })
                      }>
                      {/* @ts-ignore */}
                      <Nav.Item
                        eventKey={"single"}
                        active={modalData.type === ScheduleInterval.Single}>
                        Jednorazowy
                      </Nav.Item>
                      <Nav.Item
                        eventKey={"recurrent"}
                        active={modalData.type !== ScheduleInterval.Single}>
                        Cykliczny
                      </Nav.Item>
                    </Nav>
                  </Col>
                )}
                <SeparatorEmpty />
                <Col xs={24}>
                  <FormControlLabel>
                    <strong>Raportujący</strong>
                  </FormControlLabel>
                  <SelectPicker
                    data={modalData.users ?? []}
                    value={modalData.userId ?? undefined}
                    disabled={modalData.userId !== null || !isEnabled} //
                    valueKey={"id"}
                    labelKey={"fullName"}
                    cleanable={false}
                    searchable={true}
                    placeholder={"wybierz"}
                    block
                    onChange={(value) =>
                      updateState({ userId: value ?? undefined })
                    }
                  />
                </Col>
                <SeparatorEmpty />

                {/* hours for single */}
                {modalData.type === ScheduleInterval.Single &&
                  scheduleHoursSinglePicker(
                    isEnabled,
                    errors,
                    hoursDataSingle,
                    setHoursDataSingle
                  )}
                {modalData.type === ScheduleInterval.Single && (
                  <Col xs={24}>
                    <SeparatorEmpty size={2} />
                    <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                      {dayjs(modalData.startDate).format("dddd, D MMMM YYYY")}
                    </h5>
                  </Col>
                )}
                <SeparatorEmpty />
                <Col xs={24}>
                  {modalData.type !== ScheduleInterval.Single &&
                    scheduleHoursRecurringPicker(
                      isEnabled,
                      errors,
                      hoursDataSingle,
                      setHoursDataSingle,
                      hoursData,
                      setHoursData,
                      modalData,
                      hoursStrategy,
                      props.modalCellData?.type ?? "create",
                      props.modalCellData?.date,
                      updateState
                    )}
                </Col>
              </>
            )}
          </>
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <ActionsContainer style={{ alignItems: "flex-end" }}>
            {props.modalCellData &&
              props.modalCellData.timeTableId &&
              dayjs(props.modalCellData.date).isAfter(dayjs(), "day") && (
                <IconButton
                  icon={<TrashIcon />}
                  appearance={"ghost"}
                  color={"red"}
                  onClick={props.onScheduleRemove}>
                  Usuń
                </IconButton>
              )}
            <IconButton
              icon={<CloseOutlineIcon />}
              appearance={"ghost"}
              onClick={() => {
                setHoursData(null);
                setHoursDataSingle(null);
                props.onModalClose();
              }}>
              Zamknij
            </IconButton>
            {isCreate && (
              <IconButton
                icon={<EditIcon />}
                appearance={"primary"}
                disabled={!isReadyToSave()}
                onClick={handleSubmitAdd}>
                Utwórz
              </IconButton>
            )}
            {isModify &&
              props.modalCellData &&
              dayjs(props.modalCellData.date).isAfter(dayjs(), "day") && (
                <>
                  <div
                    style={{
                      flexGrow: "2",
                      alignItems: "center",
                      textAlign: "left",
                    }}>
                    <FormControlLabel>
                      <strong>Zmiana obowiązuje od:</strong>
                    </FormControlLabel>
                    <DatePicker
                      block
                      isoWeek
                      oneTap
                      cleanable={false}
                      placement={"topStart"}
                      shouldDisableDate={(date) =>
                        dayjs(date).isBefore(
                          dayjs(modalData?.startDate).add(1, "day")
                        )
                      }
                      format={"yyyy-MM-dd"}
                      value={modalData?.fromDate}
                      onChange={(date) => updateState({ fromDate: date })}
                    />
                    <FormErrorMessage show={errors?.formatDate}>
                      {errors?.formatDate}
                    </FormErrorMessage>
                  </div>
                  <IconButton
                    disabled={!isEnabled || !modalData?.fromDate}
                    icon={<EditIcon />}
                    appearance={modalData?.fromDate ? "ghost" : "subtle"}
                    onClick={handleSave}>
                    Zapisz
                  </IconButton>
                </>
              )}
          </ActionsContainer>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModal;
