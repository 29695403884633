import { IOptionBase, IOptionsTyped } from "../../../../utils/models";
import React, { FC, useEffect, useState } from "react";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import VisitReportsConnection from "../../../../utils/connections/VisitReports";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  IconButton,
  InputGroup,
  InputNumber,
  Panel,
  Popover,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  TagPicker,
  Toggle,
  Whisper,
} from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import dayjs from "dayjs";
import DoubleSidePicker from "../../../../global/atoms/dooubleSidePicker/DoubleSidePicker";
import { useHistory, useParams } from "react-router-dom";
import InputGroupAddon from "rsuite/esm/InputGroup/InputGroupAddon";
import CloseIcon from "@rsuite/icons/Close";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import { Helmet } from "react-helmet-async";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";

import customParseFormat from "dayjs/plugin/customParseFormat";
import ToastNotification, {
  ToastTypes,
} from "../../../../global/ToastNotification";
import FormErrorMessage from "rsuite/FormErrorMessage";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import { sortBy } from "lodash";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";

dayjs.extend(customParseFormat);

const sortedUniqueNumberDays = (days: Array<number | string>): number[] => {
  const numericDays = days
    .filter((day) => !isNaN(Number(day)) && day !== "")
    .map((day) => Number(day));

  return Array.from(new Set(numericDays)).sort((a, b) => a - b);
};

type DateRangeName = "DateRange" | "NumericRange";
type DateRangeType =
  | "CustomDate"
  | "TodayRange"
  | "YesterdayRange"
  | "BeginningOfMonthRange"
  | "EndOfMonthRange";

interface ITasksOption extends IOptionBase {
  projectId: string;
  projectName: string;
}

interface IFormModel {
  frequencyDayTypes: IOptionsTyped<IOptionBase>;
  frequencyTimeTypes: IOptionsTyped<IOptionBase>;
  templates: IOptionsTyped<IOptionBase>;
  projects: IOptionsTyped<IOptionBase>;
  prefixDateFormats: IOptionsTyped<IOptionBase>;
  tasks: IOptionsTyped<ITasksOption>;
  rangeTypes: {
    options: Array<{
      id: DateRangeName;
      name: string;
      options?: Array<{
        id: DateRangeType;
        name: string;
        stub?: string;
      }>;
    }>;
    value?: string;
    disabled: boolean;
  };
}

interface IFrequencySettings {
  dayType: string;
  timeType: string;
  dateStart: Date | string | null;
  dateEnd?: Date | string | null;
  timeStart?: Date | string | null;
  timeEnd?: Date | string | null;
  interval?: number | null;
  days?: number[];
}

interface IDataForm {
  frequencySettings: IFrequencySettings;
  projectId?: string;
  taskIds: string[];
  notificationUserIds: string[];
  templateId: string;
  prefixName?: string;
  prefixDateFormat?: string;
  range: {
    type: DateRangeName;
    rangeStart?: {
      type: DateRangeType;
      value?: string;
      stub?: string;
    };
    rangeEnd?: {
      type: DateRangeType;
      value?: string;
      stub?: string;
    };
    value?: number | string;
  };
}

interface IProjectTasks {
  projectId: string;
  specificTasks: [] | null;
}

interface IDataCreateRequest {
  templateId: string;
  projectsTasks: Array<IProjectTasks>;
  notificationUserIds: { id: string }[];
  frequencySettings: IFrequencySettings;
  prefixName?: string;
  prefixDateFormat?: string;
  range: {
    type: DateRangeName;
    start?: {
      type: DateRangeType;
      value?: string;
    };
    end?: {
      type: DateRangeType;
      value?: string;
    };
  };
}

interface IProjectUser {
  id: string;
  name: string;
  userName: string;
  userId: string;
}

const VisitReportSchedulerForm: FC = () => {
  const { schedulerId } = useParams<{ schedulerId: string }>();
  const isNew = !schedulerId;
  const [form, setForm] = useState<IFormModel | null>(null);
  const [users, setUsers] = useState<Array<IProjectUser> | null>(null);
  const [errors, setErrors] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [data, setData] = useState<IDataForm>({
    frequencySettings: {
      dayType: "LAST_DAY_OF_MONTH",
      timeType: "ONCE_PER_DAY",
      dateStart: dayjs().toDate(),
      dateEnd: undefined,
      timeStart: dayjs().toDate(),
      timeEnd: undefined,
      days: [],
    },
    projectId: "",
    taskIds: [],
    notificationUserIds: [],
    templateId: "",
    range: {
      type: "DateRange",
      rangeStart: {
        type: "BeginningOfMonthRange",
        value: dayjs().format("YYYY-MM-01"),
      },
      rangeEnd: {
        type: "EndOfMonthRange",
        value: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
    },
  });
  const history = useHistory();
  useEffect(() => {
    if (isNew) {
      VisitReportsConnection.schedulerForm().then((response) => {
        setForm((s) => ({
          ...s,
          ...response.data,
        }));
        setLoading(false);
      });
    } else {
      VisitReportsConnection.getSchedulerEditForm(schedulerId).then(
        (response) => {
          const _data = response.data.model;
          const timeToDate = (time: string) =>
            time ? dayjs(time, "HH:mm:ss").toDate() : undefined;

          const tasks = (_data.projectsTasks ?? []).flatMap((pt) => {
            if (pt.specificTasks === null) return [pt.projectId];
            else return (pt.specificTasks ?? []).map((t) => t.id);
          });
          setData((s) => ({
            ...s,
            ..._data,
            frequencySettings: {
              ..._data.frequencySettings,
              dateStart: _data?.frequencySettings.dateStart
                ? dayjs(_data?.frequencySettings.dateStart).toDate()
                : undefined,
              dateEnd: _data?.frequencySettings.dateEnd
                ? dayjs(_data?.frequencySettings.dateEnd).toDate()
                : undefined,
              timeStart: timeToDate(_data?.frequencySettings.timeStart),
              timeEnd: timeToDate(_data?.frequencySettings.timeEnd),
              days: sortedUniqueNumberDays(_data?.frequencySettings.days ?? []),
            },
            taskIds: tasks,
            notificationUserIds: (_data.notificationUsers ?? []).map(
              (u) => u.id
            ),
            templateId: _data?.template?.id,
            range: {
              ..._data.range,
              rangeStart: { ..._data.range.start },
              rangeEnd: { ..._data.range.end },
            },
          }));
          const _projectSelected = _data.projectsTasks
            .filter((ps) => ps.specificTasks === null)
            .reduce((acc, p) => ({ ...acc, [p.projectId]: true }), {});
          setProjectsSelected(_projectSelected);
          setForm((s) => ({
            ...s,
            ...response.data,
          }));
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    VisitReportsConnection.getUsersFilterForm().then((response) => {
      setUsers(
        response.data.users.data.map((user: IProjectUser) => ({
          ...user,
          id: user.userId,
          name: user.userName,
        }))
      );
    });
  }, []);

  const [projectsSelected, setProjectsSelected] = useState<
    Record<string, boolean>
  >({});
  const taskOptions = () => {
    if (!form) return [];
    const tasks = (form?.tasks.options ?? [])
      .map((t) => ({ ...t, disabled: projectsSelected[t.projectId] ?? false }))
      .filter((ft) => !ft.disabled);
    Object.keys(projectsSelected).forEach((pId) => {
      const projectName =
        form?.projects.options?.find((p) => p.id === pId)?.name ?? "";
      tasks.push({
        id: pId,
        name: `${projectName} (Wszystkie zadania)`,
        projectName: projectName,
        projectId: pId,
        disabled: true,
      });
    });

    return sortBy(tasks, ["projectName", "name"]);
  };

  if (loading) return <Spinner />;

  /** `data.taskIds` is an array of task ids, but it can also contain project ids. */
  const parseTasks = (): IProjectTasks[] => {
    const projectTasks: Array<any> = [];
    data.taskIds.forEach((elementId) => {
      const project = form?.projects.options.find(
        (project) => project.id === elementId
      );
      if (project) {
        projectTasks[project.id] = null;
      } else {
        const task = form?.tasks.options.find((task) => task.id == elementId);
        if (task) {
          if (!projectTasks[task.projectId]) {
            projectTasks[task.projectId] = [];
          }
          projectTasks[task.projectId].push(elementId);
        }
      }
    });
    return Object.entries(projectTasks).map(([projectId, tasks]) => ({
      projectId: projectId,
      specificTasks: tasks ? tasks.map((taskId) => ({ id: taskId })) : null,
    }));
  };

  const handleSubmit = () => {
    const dataToSend: IDataCreateRequest = {
      templateId: data?.templateId ?? "",
      projectsTasks: parseTasks(),
      notificationUserIds: (data?.notificationUserIds ?? []).map((id) => ({
        id: id,
      })),
      frequencySettings: {
        ...data.frequencySettings,
        dateStart: data?.frequencySettings.dateStart
          ? dayjs(data.frequencySettings.dateStart).format(
              "YYYY-MM-DDT00:00:00+00:00"
            )
          : null,
        dateEnd: data?.frequencySettings.dateEnd
          ? dayjs(data.frequencySettings.dateEnd).format(
              "YYYY-MM-DDT00:00:00+00:00"
            )
          : null,
        timeStart: data?.frequencySettings.timeStart
          ? dayjs(data.frequencySettings.timeStart).format("HH:mm:00")
          : null,
        timeEnd: data?.frequencySettings.timeEnd
          ? dayjs(data.frequencySettings.timeEnd).format("HH:mm:00")
          : null,
        days: sortedUniqueNumberDays(data?.frequencySettings.days ?? []),
        interval: data?.frequencySettings.interval
          ? Number(data?.frequencySettings.interval)
          : null,
      },
      prefixName: data.prefixName,
      prefixDateFormat: data.prefixDateFormat,
      range: data.range,
    };
    const promise = isNew
      ? VisitReportsConnection.requestCreateSchedule(dataToSend)
      : VisitReportsConnection.requestUpdateSchedule(schedulerId, dataToSend);

    promise
      .then((response) => {
        if ([200, 201, 204].includes(response.status)) {
          history.replace("/global-reports/visit-report/scheduler");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          ToastNotification(
            ToastTypes.error,
            "Formularz zawiera błędy",
            "Nie udało się zapisać reguły"
          );
          setErrors(err?.response?.data?.errors);
        }
        if (err?.response?.data?.message) {
          ToastNotification(
            ToastTypes.error,
            "Formularz zawiera błędy",
            err?.response?.data?.message
          );
        }
      });
  };
  const rangeStartOptions = () => {
    return (
      form?.rangeTypes?.options?.find(
        (rangeType) => rangeType.id === data.range.type
      )?.options ?? []
    );
  };
  const rangeEndOptions = () => {
    let startStub: string;
    if (data.range.rangeStart?.type == "CustomDate") {
      startStub = dayjs(data.range.rangeStart?.value).format("YYYY-MM-DD");
    } else {
      startStub =
        form?.rangeTypes?.options
          ?.find((rangeType) => rangeType.id === data.range.type)
          ?.options?.find((option) => option.id === data.range.rangeStart?.type)
          ?.stub ?? "";
    }

    return (
      form?.rangeTypes?.options
        ?.find((rangeType) => rangeType.id === data.range.type)
        ?.options?.filter(
          (option) => (option.stub ?? startStub) >= startStub
        ) ?? []
    );
  };

  const tasksPicker = () => {
    return (
      <Form.Group>
        <Form.ControlLabel>Zadania</Form.ControlLabel>
        <InputGroup>
          <Form.Control
            style={{ width: "100%" }}
            name={"taskIds"}
            groupBy="projectName"
            accepter={TagPicker}
            data={taskOptions() as ITasksOption[]}
            valueKey={"id"}
            labelKey={"name"}
            searchable
            searchBy={(keyword, label, item) => {
              return (
                (label as string)
                  .toLowerCase()
                  .includes(keyword.toLowerCase()) ||
                item.projectName.toLowerCase().includes(keyword.toLowerCase())
              );
            }}
            value={data?.taskIds}
            errorMessage={errors?.projectsTasks}
            errorPlacement={"bottomEnd"}
            onClean={() => {
              setProjectsSelected({});
            }}
            renderMenuItem={(label, item) => {
              if (item.id === item.projectId)
                return <div style={{ color: "silver" }}>{label}</div>;
              return label;
            }}
            disabledItemValues={taskOptions()
              .filter((t1) => t1.disabled)
              .map((t) => t.id)}
            renderMenuGroup={(title, taskItem) => {
              const projectId = taskItem?.children?.[0]?.projectId;
              const isSelected = projectsSelected?.[projectId] ?? false;
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    indeterminate={
                      !(taskItem?.children ?? []).every(
                        (e) => data?.taskIds.includes(e.id)
                      ) &&
                      (taskItem?.children ?? []).some(
                        (e) => data?.taskIds.includes(e.id)
                      )
                    }
                    checked={(taskItem?.children ?? []).every(
                      (e) => data?.taskIds.includes(e.id)
                    )}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(value, checked) => {
                      const tasks =
                        (taskItem?.children ?? []).map((e) => e.id) ?? [];
                      if (checked) {
                        setData((s) => ({
                          ...s,
                          taskIds: [...data.taskIds, ...tasks],
                        }));
                      } else {
                        setData((s) => ({
                          ...s,
                          taskIds: data.taskIds.filter(
                            (id) => !tasks.includes(id)
                          ),
                        }));
                      }
                    }}>
                    {title}{" "}
                    <Whisper
                      speaker={
                        <Popover title={`Projekt ${title}`}>
                          Zaznacz odznacz wszystkie
                        </Popover>
                      }>
                      <HelpOutlineIcon color={"#E09616"} />
                    </Whisper>
                  </Checkbox>
                  <Toggle
                    style={{ marginRight: "110px" }}
                    checked={isSelected}
                    checkedChildren={"Wszystkie zadania"}
                    unCheckedChildren={"Tylko wybrane"}
                    onChange={(checked, event) => {
                      event.stopPropagation();
                      setProjectsSelected((ps) => {
                        const newPs = { ...ps };
                        if (!checked) {
                          delete newPs[projectId];
                        } else {
                          newPs[projectId] = checked;
                        }
                        return newPs;
                      });
                      const _tasksFromProject = form?.tasks.options
                        .filter((t) => t.projectId == projectId)
                        .map((el) => el.id);
                      if (checked) {
                        setData((s) => ({
                          ...s,
                          taskIds: [
                            ...s.taskIds.filter(
                              (t) => !_tasksFromProject?.includes(t)
                            ),
                            projectId,
                          ],
                        }));
                      } else {
                        setData((s) => ({
                          ...s,
                          taskIds: s.taskIds.filter((t) => t != projectId),
                        }));
                      }
                    }}
                  />
                </div>
              );
            }}
          />
          {form?.tasks.options &&
            data?.taskIds?.length < form?.tasks.options.length && (
              <InputGroupAddon
                title={"Wybierz wszystkie"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (data.taskIds)
                    setData((s) => ({
                      ...s,
                      taskIds: taskOptions().map((item) => item.id),
                    }));
                }}>
                <CheckOutlineIcon />
              </InputGroupAddon>
            )}
        </InputGroup>
      </Form.Group>
    );
  };

  const Ranges = () => {
    return (
      <Form fluid>
        <Row>
          {data.range.type == "DateRange" && (
            <>
              <Col xs={10}>
                <Col
                  xs={data.range?.rangeStart?.type == "CustomDate" ? 12 : 24}>
                  <Form.Group>
                    <Form.ControlLabel>Pobieraj wizyty od</Form.ControlLabel>
                    <SelectPicker
                      style={{ width: "100%" }}
                      data={rangeStartOptions()}
                      valueKey={"id"}
                      labelKey={"name"}
                      value={data.range?.rangeStart?.type}
                      onChange={(value) => {
                        setData((d) => {
                          d.range.rangeStart = {
                            ...d.range.rangeStart,
                            type: value as DateRangeType,
                            value:
                              rangeStartOptions().find(
                                (option) => option.id === value
                              )?.stub ?? undefined,
                          };
                          return {
                            ...d,
                            range: {
                              ...d.range,
                              rangeStart: d.range.rangeStart,
                            },
                          };
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                {data.range?.rangeStart?.type == "CustomDate" && (
                  <Col xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>&nbsp;</Form.ControlLabel>
                      <DatePicker
                        isoWeek
                        oneTap
                        block
                        showWeekNumbers
                        value={
                          data?.range.rangeStart?.value
                            ? dayjs(data?.range.rangeStart?.value).toDate()
                            : undefined
                        }
                        onChange={(value) => {
                          setData((d) => {
                            d.range.rangeStart = {
                              ...d.range.rangeStart,
                              type: "CustomDate",
                              value: value
                                ? dayjs(value).format("YYYY-MM-DD")
                                : undefined,
                            };
                            return {
                              ...d,
                              range: {
                                ...d.range,
                                rangeStart: d.range.rangeStart,
                              },
                            };
                          });
                        }}
                        ranges={rangeStartOptions()
                          .filter((r) => r.stub)
                          .map((range) => ({
                            label: range.name,
                            value: dayjs(range.stub).toDate(),
                          }))}
                      />
                      <FormErrorMessage>
                        {errors?.range?.rangeStart}
                      </FormErrorMessage>
                    </Form.Group>
                  </Col>
                )}
              </Col>
              <Col xs={10}>
                <Col xs={data.range?.rangeEnd?.type == "CustomDate" ? 12 : 24}>
                  <Form.Group>
                    <Form.ControlLabel>Pobieraj wizyty do</Form.ControlLabel>
                    <SelectPicker
                      style={{ width: "100%" }}
                      data={rangeEndOptions()}
                      valueKey={"id"}
                      labelKey={"name"}
                      value={data.range?.rangeEnd?.type}
                      onChange={(value) => {
                        setData((d) => {
                          d.range.rangeEnd = {
                            ...d.range.rangeEnd,
                            type: value as DateRangeType,
                            value:
                              rangeEndOptions().find(
                                (option) => option.id === value
                              )?.stub ?? undefined,
                          };
                          return {
                            ...d,
                            range: {
                              ...d.range,
                              rangeEnd: d.range.rangeEnd,
                            },
                          };
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                {data.range?.rangeEnd?.type == "CustomDate" && (
                  <Col xs={12}>
                    <Form.Group>
                      <Form.ControlLabel>&nbsp;</Form.ControlLabel>
                      <DatePicker
                        isoWeek
                        oneTap
                        block
                        showWeekNumbers
                        shouldDisableDate={(d) =>
                          dayjs(d).isBefore(
                            data?.range.rangeStart?.value,
                            "days"
                          )
                        }
                        value={
                          data?.range.rangeEnd?.value
                            ? dayjs(data.range.rangeEnd.value).toDate()
                            : undefined
                        }
                        onChange={(value) => {
                          setData((d) => {
                            d.range.rangeEnd = {
                              ...d.range.rangeEnd,
                              type: "CustomDate",
                              value: value
                                ? dayjs(value).format("YYYY-MM-DD")
                                : undefined,
                            };
                            return {
                              ...d,
                              range: {
                                ...d.range,
                                rangeEnd: d.range.rangeEnd,
                              },
                            };
                          });
                        }}
                        ranges={rangeEndOptions()
                          .filter((r) => r.stub)
                          .map((range) => ({
                            label: range.name,
                            value: dayjs(range.stub).toDate(),
                          }))}
                      />
                      <FormErrorMessage>
                        {errors?.range?.rangeStart}
                      </FormErrorMessage>
                    </Form.Group>
                  </Col>
                )}
              </Col>
            </>
          )}
          {data.range.type == "NumericRange" && (
            <Col xs={20}>
              <Form.Group>
                <Form.ControlLabel>
                  Pobieraj wizyty z n ostatnich dni
                </Form.ControlLabel>
                <InputGroup>
                  <Form.Control
                    style={{ width: "100%" }}
                    name={""}
                    type="number"
                    value={data?.range.value}
                    errorMessage={errors?.amountOfDays}
                    errorPlacement={"bottomEnd"}
                    onChange={(value) => {
                      setData((d) => {
                        d.range.value = value;
                        return { ...d, range: d.range };
                      });
                    }}
                  />
                  {data?.range.value && (
                    <InputGroupAddon>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setData((d) => {
                            d.range.value = "";
                            return { ...d, range: d.range };
                          });
                        }}
                      />
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          )}
          <Col xs={4} style={{ position: "relative" }}>
            <Form.Control
              style={{ position: "absolute", top: "35px" }}
              name={"range.type"}
              accepter={Toggle}
              size={"md"}
              checked={data.range.type == "DateRange"}
              onChange={(checked) => {
                setData((d) => {
                  d.range.type = checked ? "DateRange" : "NumericRange";
                  return { ...d, range: d.range };
                });
              }}
              checkedChildren={"Zakres dat"}
              unCheckedChildren={"Liczba dni"}
            />
          </Col>
        </Row>
      </Form>
    );
  };

  const isNotReadyToSend = () => {
    if (
      data.frequencySettings?.days?.length == 0 &&
      data.frequencySettings.dayType == "WEEKLY"
    ) {
      return true;
    }
  };
  const ActionButtons = () => {
    return (
      <>
        <ActionsContainer>
          <IconButton
            icon={<CloseOutlineIcon />}
            appearance={"ghost"}
            onClick={() =>
              history.push("/global-reports/visit-report/scheduler")
            }>
            Anuluj
          </IconButton>
          <IconButton
            disabled={isNotReadyToSend()}
            icon={<AddOutlineIcon />}
            appearance={"ghost"}
            onClick={handleSubmit}>
            {isNew ? "Dodaj" : "Zapisz"} regułę
          </IconButton>
        </ActionsContainer>
      </>
    );
  };

  return (
    <>
      <HeaderButtons>
        <ActionButtons />
      </HeaderButtons>
      <WhiteCard style={{ marginBottom: "20px" }} shaded bodyFill={true}>
        <Panel header={"Nazwa pliku"} shaded>
          <Form
            fluid
            onChange={(model) => {
              setData((s: IDataForm) => ({ ...s, ...model }));
            }}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Prefiks raportu</Form.ControlLabel>
                <InputGroup>
                  <Form.Control
                    style={{ width: "100%" }}
                    name={"prefixName"}
                    value={data?.prefixName}
                    errorMessage={errors?.prefixName}
                    errorPlacement={"bottomEnd"}
                  />
                  {data?.prefixName && (
                    <InputGroupAddon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (data.prefixName)
                          setData((s) => ({ ...s, prefixName: "" }));
                      }}>
                      <CloseIcon />
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>
                  Format daty rozpoczęcia generowania raportu dołączony do
                  prefiksu
                </Form.ControlLabel>
                <Form.Control
                  style={{ width: "100%" }}
                  name={"prefixDateFormat"}
                  accepter={SelectPicker}
                  data={form?.prefixDateFormats.options ?? []}
                  valueKey={"id"}
                  labelKey={"name"}
                  value={data?.prefixDateFormat ?? null}
                  errorMessage={errors?.prefixDateFormats}
                  errorPlacement={"bottomEnd"}
                />
              </Form.Group>
            </Col>
            <SeparatorEmpty />
          </Form>
        </Panel>
        <SeparatorEmpty />
        <Panel header={"Dane"} shaded>
          <Ranges />
          <Form
            fluid
            onChange={(model) => {
              setData((s: IDataForm) => ({ ...s, ...model }));
            }}>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>Szablon</Form.ControlLabel>
                <Form.Control
                  style={{ width: "100%" }}
                  name={"templateId"}
                  accepter={SelectPicker}
                  data={form?.templates.options ?? []}
                  valueKey={"id"}
                  labelKey={"name"}
                  value={data?.templateId}
                  errorMessage={errors?.templateId}
                  errorPlacement={"bottomEnd"}
                />
              </Form.Group>
            </Col>
            <Col style={{ width: "100%" }}>{tasksPicker()}</Col>
          </Form>
          <SeparatorEmpty />
          <SeparatorEmpty />
        </Panel>
        <SeparatorEmpty />
        <Form
          fluid
          onChange={(model) => {
            setData((s) => ({
              ...s,
              frequencySettings: { ...s.frequencySettings, ...model },
            }));
          }}>
          <Panel header={"Reguły generowania raportów"} shaded>
            <Row>
              <Col xs={6}>
                <Form.Group>
                  <Form.ControlLabel>Reguła obowiązuje od</Form.ControlLabel>
                  <Form.Control
                    isoWeek
                    oneTap
                    block
                    shouldDisableDate={beforeToday()}
                    showWeekNumbers
                    value={
                      (data.frequencySettings.dateStart as Date) ?? undefined
                    }
                    name={"dateStart"}
                    accepter={DatePicker}
                    ranges={[
                      {
                        label: "Dziś",
                        value: new Date(),
                      },
                      {
                        label: "Pocz. nast. mies.",
                        value: dayjs()
                          .add(1, "month")
                          .startOf("month")
                          .toDate(),
                      },
                    ]}
                    errorMessage={errors?.nextGenerationDateTime}
                    errorPlacement={"bottomEnd"}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <Form.ControlLabel>Reguła obowiązuje do </Form.ControlLabel>
                  <Form.Control
                    isoWeek
                    oneTap
                    block
                    shouldDisableDate={beforeToday()}
                    showWeekNumbers
                    value={data.frequencySettings.dateEnd as Date}
                    name={"dateEnd"}
                    accepter={DatePicker}
                    ranges={[
                      {
                        label: "Dziś",
                        value: new Date(),
                      },
                      {
                        label: "Koniec mies.",
                        value: dayjs().endOf("month").toDate(),
                      },
                      {
                        label: "Koniec nast. mies.",
                        value: dayjs().add(1, "month").endOf("month").toDate(),
                      },
                    ]}
                    errorMessage={errors?.frequencySettings?.dateEnd}
                    errorPlacement={"bottomEnd"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <SeparatorEmpty />
            <Panel header={"Częstotliwość dni"} bordered>
              <Col xs={12}>
                <Form.Group>
                  <Form.Control
                    name={"dayType"}
                    accepter={RadioGroup}
                    inline
                    value={data?.frequencySettings?.dayType}
                    errorMessage={errors?.frequencySettings?.dayType}
                    errorPlacement={"bottomEnd"}>
                    {form?.frequencyDayTypes?.options?.map((option: any) => (
                      <>
                        <Radio value={option.id}>{option.name}</Radio>
                      </>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <SeparatorEmpty />
              {data?.frequencySettings.dayType == "WEEKLY" && (
                <Col xs={24}>
                  <Form.Group>
                    <Form.ControlLabel>
                      Powtarzaj w{" "}
                      {(data?.frequencySettings?.days ?? []).length == 0 && (
                        <span style={{ color: "red" }}>
                          *(Wybierz co najmniej jeden dzień)
                        </span>
                      )}
                    </Form.ControlLabel>
                    <InputGroup>
                      <Form.Control
                        block
                        name={"days"}
                        accepter={TagPicker}
                        value={sortedUniqueNumberDays(
                          data?.frequencySettings?.days ?? []
                        )}
                        errorMessage={errors?.frequencySettings?.days}
                        errorPlacement={"bottomEnd"}
                        data={[
                          { value: 1, label: "Poniedziałek" },
                          { value: 2, label: "Wtorek" },
                          { value: 3, label: "Środa" },
                          { value: 4, label: "Czwartek" },
                          { value: 5, label: "Piątek" },
                          { value: 6, label: "Sobota" },
                          { value: 7, label: "Niedziela" },
                        ]}
                      />
                      {(data.frequencySettings.days ?? []).length < 7 && (
                        <InputGroupAddon
                          title={"Zaznacz wszystkie"}
                          style={{ cursor: "pointer" }}>
                          <CheckOutlineIcon
                            onClick={() => {
                              setData((d) => ({
                                ...d,
                                frequencySettings: {
                                  ...d.frequencySettings,
                                  days: [1, 2, 3, 4, 5, 6, 7],
                                },
                              }));
                            }}
                          />
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <SeparatorEmpty />
                </Col>
              )}
            </Panel>
            <SeparatorEmpty />
            <Panel header={"Częstotliwość (godziny)"} bordered>
              <Col xs={12}>
                <Form.Group>
                  <Form.Control
                    name={"timeType"}
                    accepter={RadioGroup}
                    inline
                    value={data?.frequencySettings.timeType}
                    errorMessage={errors?.frequencyTimeType?.timeType}
                    errorPlacement={"bottomEnd"}>
                    {form?.frequencyTimeTypes?.options?.map((option: any) => (
                      <>
                        <Radio value={option.id}>{option.name}</Radio>
                      </>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Col xs={24}>
                  <Form.Group>
                    <Form.ControlLabel>
                      {data?.frequencySettings.timeType === "ONCE_PER_DAY"
                        ? "Rozpocznij generowanie raportu o:"
                        : "Rozpocznij generowanie raportów o:"}
                    </Form.ControlLabel>
                    <Form.Control
                      value={
                        data.frequencySettings.timeStart
                          ? (data.frequencySettings.timeStart as Date)
                          : undefined
                      }
                      name="timeStart"
                      style={{ width: "100%" }}
                      accepter={DatePicker}
                      format={"HH:mm"}
                      errorMessage={errors?.frequencySettings?.timeStart}
                      errorPlacement={"bottomEnd"}
                    />
                  </Form.Group>
                  {data.frequencySettings.timeType === "RECURRING" && (
                    <>
                      <Form.Group>
                        <Form.ControlLabel>
                          Zakończ generowanie o
                        </Form.ControlLabel>
                        <Form.Control
                          value={
                            data.frequencySettings.timeEnd
                              ? (data.frequencySettings.timeEnd as Date)
                              : undefined
                          }
                          style={{ width: "100%" }}
                          name="timeEnd"
                          accepter={DatePicker}
                          format={"HH:mm"}
                          errorMessage={errors?.frequencySettings?.timeEnd}
                          errorPlacement={"bottomEnd"}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.ControlLabel>
                          Generuj co [min] (od ostatniego wygenerowania):
                        </Form.ControlLabel>
                        <InputGroup>
                          <Form.Control
                            style={{ width: "100%" }}
                            accepter={InputNumber}
                            name={"interval"}
                            value={data?.frequencySettings.interval}
                            errorMessage={errors?.frequencySettings?.interval}
                            errorPlacement={"bottomEnd"}
                          />
                        </InputGroup>
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Col>
              <SeparatorEmpty />
            </Panel>
          </Panel>
        </Form>
        <SeparatorEmpty />

        <Col xs={24}>
          <SeparatorEmpty size={0.5} />
          <div style={{ position: "relative" }}>
            <FormErrorMessage
              placement={"bottomStart"}
              show={errors?.notificationUserIds}>
              {errors?.notificationUserIds}
            </FormErrorMessage>
          </div>
          {!users && <SpinnerSmall />}
          {users && (
            <DoubleSidePicker
              loading={!users}
              heading={"Odbiorcy"}
              emptyMessageAssigned={"Wszyscy"}
              emptyMessageNotAssigned={"Brak dostępnych użytkowników"}
              assigned={
                users?.filter((user) =>
                  data.notificationUserIds.includes(user.id)
                ) ?? []
              }
              unassigned={
                users?.filter(
                  (item) => !data.notificationUserIds.includes(item.id)
                ) ?? []
              }
              onChange={(items) => {
                setData((state) => ({
                  ...state,
                  notificationUserIds: items,
                }));
              }}
              itemType={"userGlobal"}
            />
          )}
        </Col>
        <SeparatorEmpty />
        <ActionButtons />
      </WhiteCard>
      <div id={"filterRefDiv"} />
      <Helmet>
        <style>{`
              .rs-picker-menu-group-title {
                padding: 0 !important;
              }
              
              .rs-check-item {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 10px !important;
              }
              
              .rs-form-group {
                margin-bottom: 0 !important;
              }
        `}</style>
      </Helmet>
    </>
  );
};
export default VisitReportSchedulerForm;
