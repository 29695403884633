import React, { FunctionComponent, useEffect, useState } from "react";
import Modal from "rsuite/Modal";
import {
  Checkbox,
  Col,
  IconButton,
  Message,
  Nav,
  SelectPicker,
  TagPicker,
} from "rsuite";
import { IModalBatchAddData, ScheduleInterval } from "../Schedule";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import FormControlLabel from "rsuite/FormControlLabel";
import { useParams } from "react-router-dom";
import { IScheduleDynamicForm } from "../Hooks/useForm";
import SeparatorLine from "../../../global/atoms/separators/SeparatorLine";
import toastNotification, {
  ToastTypes,
} from "../../../global/ToastNotification";
import FormErrorMessage from "rsuite/FormErrorMessage";
import SendIcon from "@rsuite/icons/Send";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SchedulesConnection from "../../../utils/connections/schedules";
import {
  COMMON_FOR_WEEK,
  DAILY,
  HOURS_EXPLICITLY,
  IScheduleModalBatchAddData,
  parseHourData,
  THoursStrategy,
  validateHoursData,
} from "./ScheduleModalElements/functions";
import scheduleHoursSinglePicker, {
  scheduleHoursRecurringPicker,
} from "./ScheduleModalElements/ScheduleHoursSinglePicker";
import { IHourData } from "./ScheduleModal";
import ToastNotification from "../../../global/ToastNotification";

dayjs.locale(localePl);
dayjs.extend(customParseFormat);

const SINGLE_DAY_INDEX = -1;

const DEFAULT_DAY = {
  day: SINGLE_DAY_INDEX,
  hours: undefined,
  start: undefined,
  end: undefined,
  type: HOURS_EXPLICITLY,
};

interface IScheduleModalBatchAdd {
  hoursStrategy?: THoursStrategy;
  onModalSubmit: (data: IScheduleRequestData) => void;
  requestData: IScheduleRequestData;
  open: boolean;
  setOpen: (open: boolean) => void;
  modalConfig?: IModalBatchAddData;
  form: IScheduleDynamicForm | null;
}

const ScheduleModalBatchAdd: FunctionComponent<IScheduleModalBatchAdd> = ({
  requestData,
  form,
  ...props
}) => {
  const { id } = useParams<{ id: string }>();

  //@ts-ignore
  const initialModalData: IScheduleModalData = {
    ...requestData,
    projectId: id,
    type: ScheduleInterval.Single,
    days: [dayjs(props?.modalConfig?.date ?? new Date()).day()],
    hours: null,
    startDate: props?.modalConfig?.date ?? new Date(),
    endDate: undefined,
    frequency: ScheduleInterval.Weekly,
  };

  const [modalData, setModalData] =
    useState<IScheduleModalBatchAddData>(initialModalData);

  const [hoursData, setHoursData] = useState<Array<IHourData> | null>([
    DEFAULT_DAY,
  ]);
  const [hoursDataSingle, setHoursDataSingle] = useState<IHourData | null>(
    DEFAULT_DAY
  );

  const [errors, setErrors] = useState<any>();
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const hoursStrategy = props.hoursStrategy ?? DAILY;

  const getFiltersName = () => {
    const names: Array<string> = [];
    let locationsName = form?.locations
      ?.filter((l) => (modalData?.locations ?? []).includes(l.id))
      .map((l) => l.name)
      .join(", ");
    if (!locationsName) locationsName = `(wszystkie)`;
    names.push(`Lokalizacje: ${locationsName}`);
    const userName =
      form?.users?.find((u) => modalData?.userId == u.id)?.name ?? `(wszyscy)`;
    names.push(`Wykonawca: ${userName}`);
    const taskName =
      form?.tasks?.find((t) => modalData?.taskId == t.id)?.name ??
      `(wszystkie)`;
    names.push(`Zadanie: ${taskName}`);
    const networkName =
      form?.networks?.find((n) => modalData?.networkId == n.id)?.name ??
      `(wszystkie)`;
    names.push(`Sieć: ${networkName}`);
    return names;
  };

  useEffect(() => {
    if (
      hoursStrategy == COMMON_FOR_WEEK &&
      modalData &&
      modalData.type !== ScheduleInterval.Single
    ) {
      setHoursData(() => {
        return modalData.days.map((d) => {
          return { ...hoursDataSingle, day: d } as IHourData;
        });
      });
    }
  }, [hoursDataSingle, modalData?.days, hoursStrategy]);

  useEffect(() => {
    validateHoursData(
      setErrors,
      modalData?.type ?? ScheduleInterval.Single,
      hoursDataSingle,
      hoursData
    );
  }, [hoursDataSingle, hoursData, modalData?.type]);

  const isReadyToSave = (): boolean => {
    if (!modalData) {
      toastNotification(ToastTypes.info, "Dane niekompletne");
      return false;
    }
    if (modalData.type !== ScheduleInterval.Single) {
      // recur schedule
      if (!modalData.days?.length) {
        toastNotification(ToastTypes.info, "Wybierz dni tygodnia");
        return false; // no-days selected in recur schedule
      }
      if (!modalData.startDate) {
        toastNotification(ToastTypes.info, "Wybierz datę początkową");
        return false; // empty start date
      }
    }
    return true;
  };

  const filteredLocations = () => {
    return (form?.locations ?? []).filter(
      (l) =>
        (modalData.projectId
          ? l.projects.includes(modalData.projectId)
          : true) &&
        (modalData.networkId ? l.networks.includes(modalData.networkId) : true)
    );
  };
  const filteredTasks = () => {
    return (form?.tasks ?? []).filter((t) =>
      modalData.projectId ? t.projects.includes(modalData.projectId) : true
    );
  };

  const updateState = (value: Partial<IScheduleModalBatchAddData>) => {
    setModalData((s) => {
      if (!s) return s;
      return { ...s, ...value };
    });
  };

  const handleBatchCreate = () => {
    if (errors) {
      ToastNotification(ToastTypes.warning, "Formularz zawiera błędy");
      return;
    }

    const days = parseHourData(
      modalData?.type ?? ScheduleInterval.Single,
      hoursDataSingle,
      hoursData,
      modalData.days
    );
    setIsWorking(true);
    SchedulesConnection.batchCreate({
      startDate: modalData.startDate,
      endDate: modalData.endDate,
      frequency: modalData.frequency,
      days: modalData.days,
      filter: {
        ...requestData,
        projectId: modalData.projectId,
        taskId: modalData.taskId,
        networkId: modalData.networkId,
        locations: modalData.locations,
        userId: modalData.userId,
      },
      hours: days,
      avoidDuplicates: modalData.avoidDuplicates,
    })
      .then((resp) => {
        if (resp.status == 201) {
          toastNotification(
            ToastTypes.loading,
            "Wpisy w harmonogramie utworzone"
          );
          props.onModalSubmit(requestData);
          setIsWorking(false);
          props.setOpen(false);
        }
      })
      .catch((error) => {
        setErrors({
          ...error?.response?.data?.errors,
          ...error?.response?.data?.errors?.filters,
        });
        setIsWorking(false);
      });
  };

  return (
    <>
      <Modal open={props.open} size={"lg"} onClose={() => props.setOpen(false)}>
        <Modal.Header>
          <h6>
            {props.modalConfig?.type === "batchAdd" &&
              "Nowy harmonogram (wielokrotne wpisy)"}
          </h6>
        </Modal.Header>

        <Modal.Body>
          <Nav
            appearance="default"
            justified
            onSelect={(eventKey: string) =>
              updateState({
                type:
                  eventKey === "single"
                    ? ScheduleInterval.Single
                    : ScheduleInterval.Weekly,
              })
            }>
            <Nav.Item
              eventKey={"single"}
              active={modalData.type === ScheduleInterval.Single}>
              Jednorazowy
            </Nav.Item>
            <Nav.Item
              eventKey={"recurrent"}
              active={modalData.type !== ScheduleInterval.Single}>
              Cykliczny
            </Nav.Item>
          </Nav>
          <SeparatorEmpty />
          <Col xs={12}>
            <FormControlLabel>Projekt</FormControlLabel>
            <SelectPicker
              size={"xs"}
              readOnly={true}
              disabled={true}
              data={form?.projects ?? []}
              value={modalData.projectId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz projekt..."}
              block
              onChange={(project) =>
                updateState({ projectId: project ?? undefined })
              }
            />
            <FormErrorMessage placement={"bottomEnd"} show={errors?.projectId}>
              {errors?.projectId}
            </FormErrorMessage>
          </Col>
          <Col xs={12}>
            <FormControlLabel>Zadanie</FormControlLabel>
            <SelectPicker
              size={"xs"}
              data={filteredTasks()}
              value={modalData.taskId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz zadanie..."}
              block
              onChange={(project) =>
                updateState({ taskId: project ?? undefined })
              }
            />
            <FormErrorMessage placement={"bottomEnd"} show={errors?.taskId}>
              {errors?.taskId}
            </FormErrorMessage>
          </Col>
          <Col xs={12}>
            <FormControlLabel>Sieć</FormControlLabel>
            <SelectPicker
              size={"xs"}
              data={form?.networks ?? []}
              value={modalData?.networkId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz..."}
              block
              onChange={(network) =>
                updateState({
                  networkId: network ?? undefined,
                  locations: [],
                })
              }
            />
          </Col>
          <Col xs={12}>
            <FormControlLabel>Lokalizacje</FormControlLabel>
            <TagPicker
              size={"xs"}
              data={filteredLocations()}
              value={modalData.locations ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              searchable={true}
              placeholder={"wybierz"}
              block
              onChange={(locations) =>
                updateState({ locations: locations ?? undefined })
              }
            />
          </Col>

          <Col xs={12}>
            <FormControlLabel>Raportujący</FormControlLabel>
            <SelectPicker
              size={"xs"}
              data={form?.users ?? []}
              value={modalData.userId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              searchable={true}
              placeholder={"wybierz"}
              block
              onChange={(value) => updateState({ userId: value ?? undefined })}
            />
          </Col>
          <Col xs={12} style={{ marginTop: "15px" }}>
            <Checkbox
              checked={modalData.avoidDuplicates}
              onChange={(value, checked) =>
                updateState({ avoidDuplicates: checked })
              }>
              Unikaj duplikatów
            </Checkbox>
          </Col>
          <SeparatorEmpty />
          <Col xs={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            {/* hours for single */}
            {modalData.type === ScheduleInterval.Single &&
              scheduleHoursSinglePicker(
                true,
                errors,
                hoursDataSingle,
                setHoursDataSingle
              )}
          </Col>
          {modalData.type === ScheduleInterval.Single && (
            <>
              <SeparatorEmpty size={2} />
              <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                {dayjs(modalData.startDate).format("dddd, D MMMM YYYY")}
              </h5>
            </>
          )}
          {modalData.type !== ScheduleInterval.Single &&
            scheduleHoursRecurringPicker(
              true,
              errors,
              hoursDataSingle,
              setHoursDataSingle,
              hoursData,
              setHoursData,
              modalData,
              DAILY, //props.hoursStrategy??DAILY,
              "create",
              props.modalConfig?.date,
              updateState,
              true
            )}
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <SeparatorLine />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Message
              showIcon
              header={"Uwaga!"}
              type={"info"}
              style={{ width: "75%", textAlign: "left" }}>
              {getFiltersName().map((p) => (
                <>
                  <span>{p}</span>
                </>
              ))}
            </Message>
            <div>
              <IconButton
                icon={isWorking ? <SpinnerIcon spin={true} /> : <SendIcon />}
                appearance={isWorking ? "link" : "ghost"}
                disabled={!isReadyToSave() || isWorking}
                onClick={handleBatchCreate}>
                Utwórz
              </IconButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModalBatchAdd;
