import React, { FunctionComponent } from "react";
import CSS from "csstype";
import styles from "./atoms.module.scss";
import { findErrorByName } from "../../utils/formErrorsHelper";
import { Form, SelectPicker } from "rsuite";

interface ISelect {
  size?: "lg" | "md" | "sm";
  style?: CSS.Properties;
  placeholder: string;
  name: string;
  onChange2?: (selectedOption: {
    label: string;
    value: string | number;
  }) => void; // todo: tymczasowo bo sie wywala połowa widoków
  onChange?: any;
  value?: string | number | null | Array<any>;
  options: Array<{
    id: string | number;
    name: string;
  }>;
  multiple?: boolean;
  disabled?: boolean;
  setState?: any;
  state?: any;
  errors?: any;
  debugData?: any;
  limit?: number;
  filterOptionsCustom?: any;
  inline?: boolean;
}

// export const controlStyles = (isFocused: boolean) => {
//   return {
//     height: '40px',
//     width: '100%',
//     border: isFocused ? '1px solid #ffaf38' : '1px solid rgba(93, 92, 92, 0.16)',
//     boxShadow: 'none',
//     outline: 'none',
//     borderRadius: '8px',
//     color: '#131315',
//     fontFamily: 'Poppins, sans-serif',
//     fontSize: '1rem',
//     letterSpacing: '0.4px',
//     padding: '0 8px',
//     cursor: 'pointer',
//     ':hover': {
//       border: '1px solid rgba(93, 92, 92, 0.16)',
//     },
//   };
// };

// export const optionStyles = (isSelected: boolean, isFocused: boolean) => {
//   return {
//     color: isSelected ? '#ffffff' : '#131315',
//     backgroundColor: isSelected ? '#ffaf38' : isFocused ? 'rgba(255, 175, 56, 0.3)' : '#ffffff',
//     cursor: 'pointer',
//     ':hover': {
//       backgroundColor: isSelected ? '#ffaf38' : 'rgba(255, 175, 56, 0.3)',
//     },
//   };
// };

const SelectComponent: FunctionComponent<ISelect> = ({
  size,
  style,
  placeholder,
  value,
  options,
  name,
  disabled,
  setState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state,
  onChange,
  onChange2,
  errors,
  debugData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  limit,
  inline = false,
}) => {
  const optionsParsed = options.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const _onChange = (value) => {
    if (onChange2) {
      return onChange2({
        label: optionsParsed.find((op) => op.value === value)?.label ?? "-",
        value: value,
      });
    } else if (onChange) {
      return onChange(value);
    } else if (setState && name) {
      setState((s) => ({ ...s, [name]: value }));
    }
  };

  return (
    <div className={styles.inputContainer} style={style} data-debug={debugData}>
      <Form.Group>
        <Form.ControlLabel>{placeholder}</Form.ControlLabel>
        <SelectPicker
          size={size ? size : "md"}
          block={!inline}
          labelKey={"label"}
          valueKey={"value"}
          value={value}
          placeholder={"Wybierz"}
          data={optionsParsed}
          disabled={disabled}
          onChange={_onChange}
        />
        {errors && (
          <span className={styles.errors}>{findErrorByName(name, errors)}</span>
        )}
      </Form.Group>
    </div>
  );

  // return (
  //   <div className={styles.inputContainer} style={style} data-debug={debugData}>
  //     <Select
  //       filterOption={filterOptions}
  //       onInputChange={() => {
  //         i = 0;
  //       }}
  //       isMulti={multiple}
  //       //@ts-ignore
  //       onChange={(onChange2 ? onChange2 : onChange ?? handleChange)}
  //       name={name}
  //       options={optionsParsed}
  //       placeholder={''}
  //       value={optionsParsed.filter(o => (o.value === value))}
  //       isDisabled={disabled}
  //       styles={{
  //         control: (styles, { isFocused }) => ({
  //           ...styles,
  //           ...controlStyles(isFocused),
  //         }),
  //         option: (styles, { isSelected, isFocused }) => ({
  //           ...styles,
  //           ...optionStyles(isSelected, isFocused),
  //         }),
  //       }}
  //     />
  //     <label className={styles.inputSelectLabel} htmlFor={name}>
  //       {placeholder}
  //     </label>
  //     {errors && <span className={styles.errors}>{findErrorByName(name, errors)}</span>}
  //   </div>
  // );
};

export default SelectComponent;
