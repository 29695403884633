import React, { FC, useEffect, useState } from "react";
import { Col, DatePicker, Drawer, IconButton, Table, TagPicker } from "rsuite";
import HeaderButtons from "../../../../global/atoms/headerButtons/HeaderButtons";
import CloseIcon from "@rsuite/icons/Close";
import ReloadIcon from "@rsuite/icons/Reload";
import GpsLocationIcon from "@rsuite/icons/Location";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import dayjs from "dayjs";
import FormControlLabel from "rsuite/FormControlLabel";
import GpsConnection from "../../../../utils/connections/gps";
import { isEmpty } from "lodash";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as XLSX from "xlsx";
import ToastNotification, {
  ToastTypes,
} from "../../../../global/ToastNotification";
import { SortType } from "../../../../global/table/ScTable";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import GlobalIcon from "@rsuite/icons/Global";

interface IGpsRouteReport {
  userId: string | null;
  dateFrom: Date | null;
  users: {
    id: string;
    name: string;
  }[];
}

export interface IGpsReportRequest {
  userIds: Array<string>;
  dateFrom: Date | null;
  dateTo: Date | null;
  requestOrder: {
    field: string;
    order: SortType;
  };
}

interface IRoutePoint {
  projectId: string;
  projectName: string;
  locationName: string;
  userName: string;
  routeDate: string;
  pointDate: string;
  deviceId: string;
  pointDistance: string;
  workStart: string;
  workEnd: string;
  locationDistance: string;
  activityStartEndDistance: string;
  routeLocationStartDistance: string;
  routeLocationAddressDistance: string;
  locationAddressDistance: string;
  routeDistance: string;
  routeNextDate: string;
  geoDistanceTolerance: string;
  geoDistanceSource: string;
}

interface IRoutesData {
  activityPoint: IRoutePoint;
  routePoints: IRoutePoint[];
}

const GpsRouteReport: FC<IGpsRouteReport> = (props) => {
  const defaultRequest = {
    userIds: [],
    dateFrom: null,
    dateTo: null,
    requestOrder: {
      field: "",
      order: undefined,
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState<IGpsReportRequest>(defaultRequest);
  const [routesData, setRoutesData] = useState<IRoutesData[] | undefined>(
    undefined
  );

  const distanceFormat = (geoDistanceTolerance: string | null): string => {
    if (!geoDistanceTolerance) return "";
    if (Number(geoDistanceTolerance) > 1000) {
      return (Number(geoDistanceTolerance) / 1000).toFixed(2) + " km";
    }
    return geoDistanceTolerance + " m";
  };
  const handleLoad = () => {
    setLoading(true);
    setRoutesData(undefined);
    const requestData = { ...request };
    if (isEmpty(requestData.userIds)) {
      requestData.userIds = props?.users?.map((u) => u.id);
    }
    GpsConnection.routeReport(requestData)
      .then((response) => {
        setLoading(false);
        setRoutesData(response.data.routes.data);
      })
      .catch((error) => {
        ToastNotification(
          ToastTypes.error,
          "Błąd",
          "Nie udało się pobrać danych"
        );
        setLoading(false);
        console.log(error.response);
      });
  };

  useEffect(() => {
    handleLoad();
  }, [request.requestOrder]);

  const handleExcel = () => {
    if (!routesData) return;

    const mappedData = routeTableData?.map((point) => {
      return {
        Projekt: point.projectName,
        Lokalizacja: point.locationName,
        Osoba: point.userName,
        Data: point.pointDate,
        Punkt:
          point.type == "ROUTE"
            ? point.locationName
            : "Punkt " + point.pointNumber,
        "GPS (godzina start)": point.workStart,
        "Godzina start": point.type == "ROUTE" ? point.routeDate : "",
        "Godzina koniec": point.workEnd,
        "ID telefonu": point.deviceId,

        ["Odległość od punktu"]:
          point.type == "ROUTE"
            ? point.routeLocationAddressDistance
            : point.locationAddressDistance,
        ["Źródło"]: point.geoDistanceSource,
        ["Tolerancja odległości"]: distanceFormat(point.geoDistanceTolerance),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedData || []);

    worksheet["!cols"] = [
      { wch: 15 }, // "Projekt"
      { wch: 20 }, // "Lokalizacja"
      { wch: 15 }, // "Osoba"
      { wch: 12 }, // "Data"
      { wch: 10 }, // "Punkt"
      { wch: 18 }, // "GPS (godzina start)"
      { wch: 15 }, // "Godzina start"
      { wch: 15 }, // "Godzina koniec"
      { wch: 18 }, // "ID telefonu"
      { wch: 20 }, // "Odległość od punktu"
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Trasy");

    XLSX.writeFile(workbook, "Raport tras.xlsx");
  };

  useEffect(() => {
    setRequest((s) => ({
      ...s,
      userIds: props.userId ? [props.userId] : [],
      dateFrom: props.dateFrom,
      dateTo: null,
    }));
  }, [props.userId, props.dateFrom]);

  const routeTableData = routesData?.flatMap((point) => [
    {
      ...point.activityPoint,
      type: "ACTIVITY",
      pointNumber: "", //String.fromCharCode(ai + 65),
    },
    ...(point.routePoints?.map((routePoint, ri) => ({
      ...routePoint,
      type: "ROUTE",
      pointNumber: ri + 1,
    })) ?? []),
  ]);

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          icon={<GpsLocationIcon />}
          onClick={() => {
            setIsOpen(true);
          }}>
          Raport tras
        </IconButton>
      </HeaderButtons>
      <Drawer
        placement={"right"}
        style={{ height: "100%" }}
        size={"full"}
        key={"drawer-gps-routes"}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Drawer.Header>
          <Drawer.Title>Raport tras</Drawer.Title>
          <Drawer.Actions>
            <IconButton
              icon={<CloseIcon />}
              appearance={"ghost"}
              onClick={() => {
                setIsOpen(false);
              }}>
              Zamknij
            </IconButton>
            <IconButton
              icon={<ReloadIcon />}
              onClick={handleLoad}
              appearance="ghost">
              Pobierz
            </IconButton>
            <IconButton
              disabled={routesData === undefined}
              icon={<FileDownloadIcon />}
              onClick={handleExcel}
              appearance="ghost">
              .XLS
            </IconButton>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "20px" }}>
          <Col xs={12}>
            <FormControlLabel>Użytkownik:</FormControlLabel>
            <TagPicker
              block
              value={request?.userIds}
              labelKey={"name"}
              valueKey={"id"}
              data={props.users}
              onChange={(values) => {
                setRequest((ps) => ({ ...ps, userIds: values }));
              }}
              placeholder={"Wybierz użytkowników"}
            />
          </Col>
          <Col xs={6}>
            <FormControlLabel>Data od:</FormControlLabel>
            <DatePicker
              block
              oneTap
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              value={
                request?.dateFrom ? dayjs(request.dateFrom).toDate() : null
              }
              onChange={(date) => {
                setRequest((ps) => ({ ...ps, dateFrom: date }));
              }}
              ranges={[
                {
                  label: "Początek tygodnia",
                  value: dayjs().startOf("week").toDate(),
                },
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              placeholder={dayjs().startOf("month").format("YYYY-MM-DD")}
            />
          </Col>
          <Col xs={6}>
            <FormControlLabel>Data do:</FormControlLabel>
            <DatePicker
              block
              oneTap
              isoWeek
              showWeekNumbers
              format={"yyyy-MM-dd"}
              value={request?.dateTo ? dayjs(request.dateTo).toDate() : null}
              onChange={(date) => {
                setRequest((ps) => ({ ...ps, dateTo: date }));
              }}
              ranges={[
                {
                  label: "Koniec tygodnia",
                  value: dayjs().endOf("week").toDate(),
                },
                {
                  label: "Dziś",
                  value: new Date(),
                },
              ]}
              placeholder={dayjs().format("YYYY-MM-DD")}
            />
          </Col>
          <SeparatorEmpty />
          {routeTableData && (
            <Col xs={24}>
              <Table
                autoHeight
                headerHeight={50}
                rowHeight={25}
                style={{ width: "100%" }}
                data={routeTableData}
                sortColumn={request?.requestOrder.field}
                sortType={request?.requestOrder.order}
                onSortColumn={(column, type) => {
                  setRequest((s) => ({
                    ...s,
                    requestOrder: {
                      field: column,
                      order: type,
                    },
                  }));
                }}
                loading={loading}>
                <Table.Column flexGrow={1} resizable>
                  <Table.HeaderCell>
                    <div>
                      <CloseOutlineIcon
                        color={
                          request.requestOrder.field ? "#E09616" : "inherit"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setRequest((s) => ({
                            ...s,
                            requestOrder: {
                              field: "",
                              order: undefined,
                            },
                          }));
                        }}
                      />
                      &nbsp;Projekt
                    </div>
                  </Table.HeaderCell>
                  <Table.Cell dataKey={"projectName"} />
                </Table.Column>
                <Table.Column flexGrow={1} resizable sortable>
                  <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
                  <Table.Cell dataKey={"locationName"}>
                    {(rowData) => {
                      return <>{rowData.locationName}</>;
                    }}
                  </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1} resizable sortable>
                  <Table.HeaderCell>Użytkownik</Table.HeaderCell>
                  <Table.Cell dataKey={"userName"}>
                    {(row) => <>{row.userName}</>}
                  </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1} resizable sortable>
                  <Table.HeaderCell>Data</Table.HeaderCell>
                  <Table.Cell dataKey={"pointDate"} />
                </Table.Column>

                <Table.Column flexGrow={1} resizable sortable>
                  <Table.HeaderCell>Punkt</Table.HeaderCell>
                  <Table.Cell dataKey={"locationName"}>
                    {(rowData) => {
                      if (rowData.type == "ACTIVITY")
                        return (
                          <>
                            {rowData.pointNumber}
                            {rowData.locationName}
                          </>
                        );
                      if (rowData.type == "ROUTE")
                        return <>P-{rowData.pointNumber}</>;
                      return <></>;
                    }}
                  </Table.Cell>
                </Table.Column>
                <Table.ColumnGroup header={"GPS"}>
                  <Table.Column flexGrow={1} resizable>
                    <Table.HeaderCell>Start</Table.HeaderCell>
                    <Table.Cell dataKey={"workStart"}>
                      {(rowData) => {
                        return <>{rowData.workStart}</>;
                      }}
                    </Table.Cell>
                  </Table.Column>
                  <Table.Column flexGrow={1} resizable>
                    <Table.HeaderCell>Punkt</Table.HeaderCell>
                    <Table.Cell dataKey={"routeDate"}>
                      {(rowData) => {
                        if (rowData.type == "ROUTE")
                          return <>{rowData.routeDate}</>;
                        return <></>;
                      }}
                    </Table.Cell>
                  </Table.Column>
                  <Table.Column flexGrow={1} resizable>
                    <Table.HeaderCell>Koniec</Table.HeaderCell>
                    <Table.Cell dataKey={"workEnd"}>
                      {(rowData) => {
                        return <>{rowData.workEnd}</>;
                      }}
                    </Table.Cell>
                  </Table.Column>
                </Table.ColumnGroup>

                <Table.Column flexGrow={1} resizable>
                  <Table.HeaderCell>Id telefonu</Table.HeaderCell>
                  <Table.Cell dataKey={"deviceId"} />
                </Table.Column>

                <Table.Column fixed={"right"} resizable fullText>
                  <Table.HeaderCell>Odległość od lokalizacji</Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      if (rowData.type == "ACTIVITY")
                        return <>{rowData.locationAddressDistance}</>;
                      if (rowData.type == "ROUTE")
                        return <>{rowData.routeLocationAddressDistance}</>;
                      return <></>;
                    }}
                  </Table.Cell>
                </Table.Column>
                <Table.Column fixed={"right"} resizable fullText>
                  <Table.HeaderCell>GPS (+/-)</Table.HeaderCell>
                  <Table.Cell>
                    {(rowData) => {
                      if (!rowData.geoDistanceTolerance) return <></>;
                      return (
                        <>
                          {rowData.geoDistanceSource == "GPS" ? (
                            <GpsLocationIcon />
                          ) : (
                            <GlobalIcon />
                          )}{" "}
                          &nbsp;
                          <span>
                            {distanceFormat(rowData.geoDistanceTolerance)}
                          </span>
                        </>
                      );
                    }}
                  </Table.Cell>
                </Table.Column>
              </Table>
            </Col>
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default GpsRouteReport;
