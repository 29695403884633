import React, { useEffect, useState } from "react";
import WhiteCard from "../../../global/atoms/WhiteCard";
import Section from "../../../global/atoms/section/Section";
import { deepClone, handleToast } from "../../../utils/helpers";
import Input from "../../../global/atoms/Input";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import ButtonSubmit from "../../../global/atoms/ButtonSubmit";
import ActionsContainer from "../../../global/atoms/ActionsContainer";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import { Checkbox, Col, Dropdown, IconButton, Row } from "rsuite";
import { FCC } from "utils/models";
import SortableList from "../../../global/atoms/dnd/SortableList";
import HeaderButtons from "../../../global/atoms/headerButtons/HeaderButtons";
import NavOptionsWrapper from "../../../utils/NavOptionsWrapper";
import TrashIcon from "@rsuite/icons/Trash";
import VisitReportsConnection from "../../../utils/connections/VisitReports";
import styles from "./styles.module.scss";
import EditIcon from "@rsuite/icons/Edit";
import { DragHandle } from "../../../global/atoms/SortableItem";
import FunnelIcon from "@rsuite/icons/Funnel";
import { AxiosPromise } from "axios";

export interface IReportTemplateData {
  name: string;
  data: any;
  projectId: string;
  type: number;
}

type columnsDataType = Array<{
  label: string;
  name: string;
  active: boolean;
  options: Array<{
    name: string;
    label: string;
    active: boolean;
  }>;
}>;

export interface IReportTemplateState {
  templateName: string;
  columnsData: columnsDataType;
}

export interface ITemplate {
  state: IReportTemplateState;
  setState: (state: IReportTemplateState) => void;
  initialState?: IReportTemplateState;
  sortable?: boolean;
}

const GlobalReportTemplate: FCC<ITemplate> = ({
  state,
  setState,
  children,
  initialState,
  sortable = false,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { templateId } = useParams<{ templateId: string }>();
  const history = useHistory();

  useEffect(() => {
    let promise: AxiosPromise<any>;
    if (templateId) {
      promise = VisitReportsConnection.getTemplate(templateId);
    } else {
      promise = VisitReportsConnection.getAddTemplate();
    }
    promise.then((response) => {
      const data = response.data.model;
      const roles = (
        response.data?.roles?.data?.filter((r) => r.roleType.type == "MERCH") ??
        []
      ).map((r) => ({
        name: r.roleId,
        label: r.roleName,
        active: false,
      }));
      const columnsData =
        data?.data?.columnsData ?? initialState?.columnsData ?? [];
      roles.forEach((r) => {
        const option = columnsData[0].options.find((o) => r.name == o.name);
        if (!option) {
          columnsData[0].options.push(r);
        }
      });

      setState({
        templateName: data?.name,
        columnsData: columnsData,
      });
      setLoading(false);
    });
  }, []);

  const handleToggleSection = (index, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].active = checked;
    setState({ ...state, columnsData: dataClone });
  };

  const handleToggleOption = (index, value, checked) => {
    const dataClone = deepClone(state.columnsData);
    dataClone[index].options[value].active = checked;
    setState({ ...state, columnsData: dataClone });
  };

  const handleSave = () => {
    if (state.templateName === "") {
      return false;
    }
    const dataPost = {
      name: state.templateName,
      data: state,
    };
    const redirectUrl = `/global-reports/visit-report`;

    if (templateId !== undefined) {
      handleToast(
        VisitReportsConnection.updateTemplate(templateId, dataPost)
      ).then(() => {
        history.push(redirectUrl);
      });
      return true;
    }
    handleToast(VisitReportsConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };
  const handleRemove = () => {
    if (state.templateName === "") {
      return false;
    }
    const dataPost = {
      name: state.templateName,
      data: state,
    };
    const redirectUrl = `/global-reports/visit-report`;

    if (templateId !== undefined) {
      handleToast(VisitReportsConnection.removeTemplate(templateId)).then(
        () => {
          history.push(redirectUrl);
        }
      );
      return true;
    }
    handleToast(VisitReportsConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };
  const onSortEnd = (index, sortedOptions) => {
    const data = deepClone(state);
    data.columnsData[index].options = sortedOptions;
    setState(data);
  };

  const allSelected = (item) => {
    if (item.length == 0) return undefined;
    return (
      item?.options?.filter((e: any) => e.active).length == item.options.length
    );
  };
  const toggleAll = (section): void => {
    let toSet = false;
    if (!allSelected(section)) toSet = true;
    const newOptions = section.options.map((el) => ({ ...el, active: toSet }));
    const ColumnsData = deepClone(state.columnsData);
    const newColumnsData = ColumnsData.map((s) =>
      s.name == section.name ? { ...s, options: newOptions } : s
    );
    //@ts-ignore
    setState((s) => ({ ...s, columnsData: newColumnsData }));
  };
  if (loading) return <Spinner />;

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          {!!initialState && (
            <>
              <Dropdown.Item
                onClick={() => {
                  setState(initialState);
                }}
                icon={<FunnelIcon />}>
                Ustaw wartości domyślne
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!templateId}
                onClick={handleRemove}
                icon={<TrashIcon />}>
                Usuń szablon &quot;{state.templateName}&quot;
              </Dropdown.Item>
            </>
          )}
        </NavOptionsWrapper>
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={12}>
            <Input
              name={"templateName"}
              value={state.templateName}
              placeholder={"Nazwa szablonu"}
              setState={setState}
              state={state}
            />
            {!state.templateName && (
              <div
                style={{
                  position: "absolute",
                  fontSize: "10px",
                  color: "red",
                }}>
                <sup>*</sup>Nazwa szablonu jest wymagana
              </div>
            )}
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          {state.columnsData.map((item, index) => (
            <Col xs={!index ? 18 : 6} key={index}>
              <Section title={item.label} className={styles.sectionContainer}>
                <div
                  key={`cc-${index}`}
                  className={!item.active ? styles.sectionDisabled : ""}
                />
                <Checkbox
                  style={{ margin: 0 }}
                  className={styles.topRightCornerCheckbox}
                  checked={item.active}
                  value={index}
                  onChange={handleToggleSection}
                />

                {item?.options?.length && (
                  <Checkbox
                    checked={allSelected(item)}
                    onClick={() => {
                      toggleAll(item);
                    }}>
                    Zaznacz/Odznacz wszystkie
                  </Checkbox>
                )}
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column wrap",
                    justifyContent: "flex-start",
                    maxHeight: "calc(100vh - 375px)",
                    overflow: "auto",
                  }}>
                  <SortableList
                    onSortEnd={onSortEnd.bind(null, index)}
                    data={item.options}
                    idKeyName={"name"}
                    disabled={!sortable}
                    dragHandleActivator={true}
                    overlay
                    mapFunction={(option, indexOption) => (
                      <>
                        <div
                          key={index + " " + indexOption}
                          style={{ display: "flex", flexGrow: "1" }}>
                          <Checkbox
                            style={{ width: "90%" }}
                            checked={option.active}
                            value={indexOption}
                            onChange={handleToggleOption.bind(null, index)}>
                            {option.label}
                          </Checkbox>
                          {sortable && <DragHandle />}
                        </div>
                      </>
                    )}
                  />
                </div>
              </Section>
            </Col>
          ))}
        </Row>
        {children}
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          {!!initialState && (
            <IconButton
              appearance={"ghost"}
              disabled={!initialState}
              onClick={() => {
                setState({ ...state, columnsData: initialState.columnsData });
              }}
              icon={<FunnelIcon />}>
              Ustaw wartości domyślne
            </IconButton>
          )}
          <IconButton
            icon={<EditIcon />}
            appearance={"ghost"}
            onClick={() => {
              history.goBack();
            }}>
            Anuluj
          </IconButton>
          <ButtonSubmit disabled={!state.templateName} onClick={handleSave} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default GlobalReportTemplate;
