import { Checkbox, Panel } from "rsuite";
import React, { useEffect, useState } from "react";
import SortableList from "../../../../global/atoms/dnd/SortableList";
import { DragHandle } from "../../../../global/atoms/SortableItem";
import { ICreateProject, SetState } from "../../../../utils/models";
import { isEmpty } from "lodash";

interface IElement {
  name: string;
  id: string;
  isActive: boolean;
}

interface IGroups {
  id: string;
  label: string;
  elements: IElement[];
}

interface IPhotoWatermarkPicker {
  data: ICreateProject;
  setData: SetState;
  shortConfig?: boolean;
}

const PhotoWatermarkPicker = (props: IPhotoWatermarkPicker) => {
  const { data, setData, shortConfig } = props;

  const otherConfigElements: Array<IGroups> = [
    {
      id: "project",
      label: "Projekt",
      elements: [
        { name: "Projekt", id: "project_name", isActive: false },
        { name: "Klient", id: "customer_name", isActive: false },
      ],
    },
    {
      id: "location",
      label: "Lokalizacja",
      elements: [
        { name: "Lokalizacja", id: "location_name", isActive: true },
        { name: "Zadanie", id: "task_name", isActive: false },
        { name: "Aktywność", id: "activity_name", isActive: false },
      ],
    },
    {
      id: "location_address",
      label: "Adres",
      elements: [
        { name: "Ulica", id: "street_name", isActive: true },
        { name: "Numer", id: "street_number", isActive: true },
        { name: "KodPocztowy", id: "postal_code", isActive: true },
        { name: "Miasto", id: "city_name", isActive: true },
      ],
    },
    {
      id: "provice",
      label: "Woj.(Powiat)",
      elements: [
        { name: "Województwo", id: "voivodeship_name", isActive: true },
        { name: "Powiat", id: "district_name", isActive: true },
      ],
    },
  ];

  const shortConfigElements: Array<IGroups> = [
    {
      id: "visit_date",
      label: "Data",
      elements: [
        { name: "D-MM-YYYY", id: "visit_date", isActive: true },
        { name: "GG:MM", id: "visit_time", isActive: true },
        { name: "SS", id: "visit_seconds", isActive: true },
      ],
    },
  ];

  let waterMarkElements: Array<IGroups>;
  if (!shortConfig) {
    waterMarkElements = [...otherConfigElements, ...shortConfigElements];
  } else {
    waterMarkElements = shortConfigElements;
  }

  const [tempData, setTempData] = useState<Array<IGroups>>(() => {
    if (!isEmpty(data.projectSettings.photoWatermarkPattern)) {
      let modelKeys = Object.keys(data.projectSettings.photoWatermarkPattern);
      const elementKeys = waterMarkElements.map((el) => el.id);
      if (!shortConfig && modelKeys.length !== elementKeys.length) {
        modelKeys = [
          ...modelKeys,
          ...elementKeys.filter((el) => !modelKeys.includes(el)),
        ];
      }

      const elements = modelKeys
        /** when organizations config is change */
        .filter((key) => waterMarkElements.map((el) => el.id).includes(key))
        .map((groupId) =>
          waterMarkElements.find((e) => e.id === groupId)
        ) as IGroups[];

      return elements.map((group) => ({
        ...group,
        elements: group.elements.map((element) => ({
          ...element,
          isActive:
            data.projectSettings.photoWatermarkPattern?.[group.id]?.[
              element.id
            ] ?? element.isActive,
        })),
      })) as IGroups[];
    }

    return waterMarkElements.map((group) => ({
      ...group,
      elements: group.elements.map((element) => ({
        ...element,
        isActive:
          data.projectSettings.photoWatermarkPattern?.[element.id] ??
          element.isActive,
      })),
    })) as IGroups[];
  });
  const onSortEnd = (sortedData) => {
    setTempData(sortedData);
  };
  const onSortEndElements = (sortedData) => {
    const id = sortedData[0].id;
    const indexSection = tempData.findIndex((g: IGroups) =>
      g.elements.some((i) => i.id === id)
    );
    setTempData((d) => {
      const newTempData = [...d];
      newTempData[indexSection].elements = sortedData;
      return newTempData;
    });
  };
  const handleChange = (element, value, checked) => {
    setTempData((d) =>
      d.map((r) => {
        if (r.id === element.parentId) {
          return {
            ...r,
            elements: r.elements.map((e) => {
              if (e.id === element.elementId) {
                return { ...e, isActive: checked };
              }
              return e;
            }),
          };
        }
        return r;
      })
    );
  };

  useEffect(() => {
    const photoWatermarkPattern = (tempData as IGroups[]).reduce((acc, r) => {
      if (r) {
        acc[r.id] = r.elements.reduce((elemAcc, e) => {
          elemAcc[e.id] = e.isActive;
          return elemAcc;
        }, {});
      }
      return acc;
    }, {});
    setData((d) => ({
      ...d,
      projectSettings: {
        ...d.projectSettings,
        photoWatermarkPattern: photoWatermarkPattern,
      },
    }));
  }, [tempData]);

  return (
    <Panel bordered shaded style={{ position: "relative", bottom: "25px" }}>
      <SortableList
        onSortEnd={onSortEnd}
        data={tempData}
        idKeyName={"id"}
        dragHandleActivator
        floating
        overlay
        mapFunction={(el) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <DragHandle />
              <span style={{ display: "inline-block", width: "120px" }}>
                {el.label}:
              </span>
              <SortableList
                horizontal
                floating
                dragHandleActivator
                overlay
                onSortEnd={onSortEndElements}
                data={el.elements}
                idKeyName={"id"}
                mapFunction={(element) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}>
                      <Checkbox
                        value={element.id}
                        checked={element.isActive}
                        onChange={handleChange.bind(null, {
                          parentId: el.id,
                          elementId: element.id,
                        })}>
                        {element.name}
                      </Checkbox>
                      <DragHandle />
                    </div>
                  );
                }}
              />
            </div>
          );
        }}
      />
    </Panel>
  );
};

export default PhotoWatermarkPicker;
