import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  IconButton,
  InputGroup,
  Message,
  SelectPicker,
  Steps,
  Toggle,
} from "rsuite";
import CloseIcon from "@rsuite/icons/Close";
import TargetConnections from "./TargetConnections";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import Section from "../../../../global/atoms/section/Section";
import WhiteCard from "../../../../global/atoms/WhiteCard";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { validate } from "uuid";
import { handleToast } from "../../../../utils/helpers";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { IOptionBase, ISelect } from "../../../../utils/models";
import EditIcon from "@rsuite/icons/Edit";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import TargetUsers from "./TargetUsers";
import styles from "../editProject/styles.module.scss";
import FolderMoveIcon from "@rsuite/icons/FolderMove";
import { sortBy, uniqBy } from "lodash";
import ItemDoublePicker, {
  IFilterForm,
} from "../../../../global/atoms/dooubleSidePicker/ItemDoublePicker";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import DatePickerSlider from "./DatePickerSlider";

interface ITargetsEditProps {
  targetDefinitionId?: string;
}

type TPeriodType = "MONTH" | "CUSTOM";
export interface ITargetModel {
  targetId: string;
  name: string;
  description: string;
  type: string;
  status: string;
  startDate: Date;
  endDate: Date;
  users: Array<{ userId: string; value: string }>;
  items: Array<string>;
  locations: Array<string>;
  questions: Array<string>;
  isRefreshNextMonth: boolean;
  periodType: TPeriodType;
}

interface ITargetModelRequest {
  targetId: string;
  name: string;
  description: string;
  type: { id: string };
  status: { id: string };
  startDate: Date;
  endDate: Date;
  periodType: { id: string; name: string };
}

export interface IUserEntity {
  userId: string;
  userName: string;
  networkId: string;
  networkName: string;
  locationId: string;
  locationName: string;
}

interface IItem {
  itemName: string;
  itemId: string;
  brandId: string;
  brandName: string;
  groupId: string;
  groupName: string;
  categoryId: string;
  categoryName: string;
}

interface IQuestion {
  questionName: string;
  questionId: string;
  taskId: string;
  taskName: string;
  activityId: string;
  activityName: string;
}

interface ILocation {
  locationName: string;
  locationId: string;
  networkId: string;
  networkName: string;
  userId: string;
  userName: string;
}
export interface ITargetForm {
  types: ISelect;
  statuses: ISelect;
  users: Array<IUserEntity>;
  items: Array<IItem>;
  networks: Array<{ id: string; name: string }>;
  locations: Array<ILocation>;
  questions: Array<IQuestion>;
  predefinedBody: { options: { [key: string]: string } };
}

const TargetsEdit: FunctionComponent<ITargetsEditProps> = () => {
  const history = useHistory();
  const { id, targetId } = useParams<{ id: string; targetId: string }>();
  const [_targetId, setTargetId] = useState<string>(targetId);
  const [currentMonthOption, setCurrentMonthOption] = useState<boolean>(false);

  const TargetModelDefault: ITargetModel = {
    targetId: _targetId,
    name: "",
    description: "",
    type: "",
    status: "",
    startDate: new Date(),
    endDate: dayjs().endOf("month").toDate(),
    users: [],
    items: [],
    locations: [],
    questions: [],
    isRefreshNextMonth: false,
    periodType: "MONTH",
  };

  const [targetModel, setTargetModel] =
    useState<Partial<ITargetModel>>(TargetModelDefault);
  const [targetForm, setTargetForm] = useState<Partial<ITargetForm>>();
  const [errors, setErrors] = useState<any>(null);

  const mode = _targetId ? "edit" : "add";
  const getItemsForm = () => {
    TargetConnections.getTargetItemsForm(id, _targetId).then((response) => {
      setTargetForm((s) => ({ ...s, items: response.data.items.data }));
    });
  };
  const getLocationsForm = () => {
    TargetConnections.getTargetLocationsForm(id, _targetId).then((response) => {
      interface INotParsedType {
        networkId: string;
        networkName: string;
      }
      const networks = response.data.locations.data.reduce(
        (acc: IOptionBase[], loc: INotParsedType) => {
          if (!acc.find((n) => n.id === loc.networkId))
            return [...acc, { id: loc.networkId, name: loc.networkName }];
          return acc;
        },
        []
      );
      setTargetForm((s) => ({
        ...s,
        locations: response.data.locations.data,
        networks: networks,
      }));
    });
  };
  const getQuestionsForm = () => {
    TargetConnections.getTargetQuestionForm(id, _targetId).then((response) => {
      setTargetForm((s) => ({ ...s, questions: response.data.questions.data }));
    });
  };

  useEffect(() => {
    if (_targetId) {
      getItemsForm();
      getLocationsForm();
      getQuestionsForm();
    }
  }, [_targetId]);
  useEffect(() => {
    if (mode == "edit") {
      TargetConnections.getTargetModel(id, _targetId).then(
        (response: AxiosResponse<any>) => {
          const model = response.data.model as ITargetModelRequest;
          setTargetModel({
            ...model,
            type: model.type.id,
            status: model.status.id,
            startDate: dayjs(model.startDate).toDate(),
            endDate: dayjs(model.endDate).toDate(),
            periodType: model.periodType.id as TPeriodType,
            description: model.description
              ? model.description
              : response.data.predefinedBody.options[model.type.id],
          });
          setTargetForm((s) => ({ ...s, ...response.data }));
          setCurrentMonthOption(model.periodType.id === "CUSTOM");
        }
      );
    } else {
      TargetConnections.getTargetForm(id).then(
        (response: AxiosResponse<any>) => {
          setTargetForm((s) => ({ ...s, ...response.data }));
        }
      );
    }
  }, []);

  const funcItemMap = (item: {
    itemName: string;
    itemId: string;
  }): { name: string; id: string } => {
    return {
      ...item,
      name: item.itemName,
      id: item.itemId,
    };
  };

  const funcDataMap = (
    data: { [dataKey: string]: string },
    dataKey: string,
    dataLabel: string
  ): {
    name: string;
    id: string;
  } => {
    return {
      ...data,
      name: data?.[dataKey],
      id: data?.[dataLabel],
    };
  };
  const funcLocationMap = (location: {
    locationName: string;
    locationId: string;
  }): { name: string; id: string } => {
    return funcDataMap(location, "locationName", "locationId");
  };
  const funcQuestionMap = (question: {
    questionName: string;
    questionId: string;
  }): { name: string; id: string } => {
    return funcDataMap(question, "questionName", "questionId");
  };
  const getItemAssigned = () => {
    return (targetForm?.items ?? [])
      .filter((p) => (targetModel.items ?? []).indexOf(p.itemId) >= 0)
      .map(funcItemMap);
  };
  const getItemUnAssigned = () => {
    return (targetForm?.items ?? [])
      .filter((p) => (targetModel.items ?? []).indexOf(p.itemId) < 0)
      .map(funcItemMap);
  };
  const getLocationsAssigned = () => {
    return uniqBy(
      (targetForm?.locations ?? []).filter(
        (p) => (targetModel.locations ?? []).indexOf(p.locationId) >= 0
      ),
      "locationId"
    ).map(funcLocationMap);
  };
  const pickedUsers =
    targetModel?.users?.filter((u) => u.value).map((u) => u.userId) ?? [];
  const getLocationsUnAssigned = () => {
    return uniqBy(
      (targetForm?.locations ?? [])
        .filter((ul) => pickedUsers.includes(ul.userId))
        .filter((p) => (targetModel.locations ?? []).indexOf(p.locationId) < 0),
      "locationId"
    ).map(funcLocationMap);
  };
  const getQuestionsAssigned = () => {
    return (targetForm?.questions ?? [])
      .filter((p) => (targetModel.questions ?? []).indexOf(p.questionId) >= 0)
      .map(funcQuestionMap);
  };
  const getQuestionsUnAssigned = () => {
    const questions = (targetForm?.questions ?? [])
      .filter((p) => (targetModel.questions ?? []).indexOf(p.questionId) < 0)
      .map(funcQuestionMap);
    return questions.reduce<{ name: string; id: string }[]>((qs, q) => {
      if (qs.find((_q) => _q.id === q.id)) return qs;
      return [...qs, q];
    }, []);
  };
  const handleSubmit = () => {
    const targetIsMonthPeriodType = targetModel.periodType == "MONTH";
    const users = targetModel?.users
      ?.map((u) => ({ userId: u.userId, value: parseInt(u.value) }))
      ?.filter((u) => u.value > 0);
    const toSend = {
      ...targetModel,
      isRefreshNextMonth:
        targetModel.periodType != "CUSTOM"
          ? targetModel.isRefreshNextMonth
          : false,
      startDate:
        targetIsMonthPeriodType && mode == "add"
          ? null
          : dayjs(targetModel.startDate).format("YYYY-MM-DD"),
      endDate: targetIsMonthPeriodType
        ? null
        : dayjs(targetModel.endDate).format("YYYY-MM-DD"),
      users: users,
    };
    if (mode == "add") {
      handleToast(TargetConnections.addTargetForm(id, toSend), setErrors).then(
        (response) => {
          if (validate(response.data.targetId)) {
            setTargetId(response.data.targetId);
            nextStep();
            //history.push(`/projects/${id}/targets/${response.data.targetId}`);
          }
        }
      );
    } else {
      handleToast(
        TargetConnections.editTargetForm(id, _targetId, toSend),
        setErrors
      ).then(() => {
        history.push(`/projects/${id}/targets`);
      });
    }
  };

  const isQuestionType = targetModel.type == "QUESTION";

  const [step, setStep] = useState(0);
  const baseData = 0;
  const usersDataStep = 1;
  const itemDataStep = 2;
  const locationDataStep = 3;
  const questionDataStep = 4;
  const lastStep = isQuestionType ? questionDataStep : locationDataStep;

  function nextStep() {
    if (step < lastStep) {
      setStep(step + 1);
      return;
    }
    setStep(lastStep);
  }

  const baseStepHasErrors = () => {
    return !!errors?.length;
  };

  if (!targetForm) return <Spinner content={"Ładowanie..."} />;

  const productFilterData = (): IFilterForm => {
    return {
      groups: {
        filterLabel: "Grupa",
        filterId: "groupId",
        options: sortBy(
          uniqBy(targetForm?.items ?? [], "groupId").map((g) => ({
            id: g.groupId,
            name: g.groupName,
          })),
          "name"
        ),
      },
      categories: {
        filterLabel: "Kategoria",
        filterId: "categoryId",
        parentFilterId: "groupId",
        options: sortBy(
          uniqBy(targetForm?.items ?? [], "categoryId")
            .filter((e) => e.categoryId !== null)
            .map((c) => ({
              id: c.categoryId,
              name: c.categoryName,
              groupId: c.groupId,
            })),
          "name"
        ),
      },
      brands: {
        filterLabel: "Marka",
        filterId: "brandId",
        parentFilterId: ["categoryId", "groupId"],
        options: sortBy(
          uniqBy(targetForm?.items ?? [], "brandId").map((b) => ({
            id: b.brandId,
            name: b.brandName,
            groupId: b.groupId,
            categoryId: b.categoryId,
          })),
          "name"
        ),
      },
    };
  };

  const locationFilterData = (): IFilterForm => {
    return {
      networks: {
        filterLabel: "Sieć",
        filterId: "networkId",
        options: uniqBy(
          targetForm?.locations?.filter((u) =>
            pickedUsers.includes(u.userId)
          ) ?? [],
          "networkId"
        ).map((g) => ({
          id: g.networkId,
          name: g.networkName,
        })),
      },
      users: {
        filterLabel: "Użytkownik",
        filterId: "userId",
        options: uniqBy(
          targetForm?.locations?.filter((u) =>
            pickedUsers.includes(u.userId)
          ) ?? [],
          "userId"
        ).map((g) => ({
          id: g.userId,
          name: g.userName,
        })),
      },
    };
  };
  const questionFilterData = (): IFilterForm => {
    return {
      tasks: {
        filterLabel: "Zadanie",
        filterId: "taskId",
        options: uniqBy(targetForm?.questions ?? [], "taskId").map((g) => ({
          id: g.taskId,
          name: g.taskName,
        })),
      },
      activities: {
        filterLabel: "Aktywność",
        filterId: "activityId",
        parentFilterId: "taskId",
        options: uniqBy(targetForm?.questions ?? [], "activityId").map((g) => ({
          id: g.activityId,
          name: g.activityName,
          taskId: g.taskId,
        })),
      },
    };
  };

  const TagsRender = () => {
    const tags = [
      { tagKey: "{PROJECT}", tagLabel: "Projekt" },
      { tagKey: "{TARGET}", tagLabel: "Cel" },
      { tagKey: "{SCORE}", tagLabel: "Wynik" },
      { tagKey: "{PERCENT_SCORE}", tagLabel: "Wynik w [%]" },
    ];
    const defaultMessage =
      targetForm?.predefinedBody?.options?.[targetModel.type ?? ""] ?? "";
    return (
      <>
        <Message
          type={"info"}
          header={
            <div key={"message-one"} style={{ textAlign: "center" }}>
              Budowa wiadomości
            </div>
          }>
          <div key={"tags"} className={styles.configContainer}>
            {tags.map((tag, index) => {
              const disabled = targetModel.description?.includes(tag.tagKey);
              return (
                <div key={`tag-${index}`} className={styles.configContainerBox}>
                  <span
                    draggable={!disabled}
                    onDragStart={(e) => {
                      e.dataTransfer.setData("text/plain", tag.tagKey);
                      e.currentTarget.style.opacity = "0.5";
                    }}
                    onDragEnd={(e) => {
                      e.currentTarget.style.opacity = "1.0";
                    }}>
                    {!disabled && (
                      <>
                        <FolderMoveIcon />
                        &nbsp;
                      </>
                    )}
                    <b style={{ width: "200px" }}>{tag.tagKey}</b>
                  </span>
                  <br /> {tag.tagLabel}
                </div>
              );
            })}
          </div>
          <div className={styles.configContainer}></div>
          {defaultMessage && (
            <div
              key={"default"}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", defaultMessage);
                e.currentTarget.style.opacity = "0.5";
              }}
              onDragEnd={(e) => {
                e.currentTarget.style.opacity = "1.0";
              }}>
              <FolderMoveIcon />
              &nbsp;{defaultMessage}
            </div>
          )}
        </Message>
      </>
    );
  };
  const isInitiallyDisabled = mode == "add" && !targetModel.type;

  const periodOptionsRenderer = () => {
    const isEditDisabled = mode == "edit" && !!targetModel.type;
    return (
      <Col xs={24}>
        <Section title={"Okres realizacji"}>
          <Col xs={24}>
            <Col xs={6}>
              <Form.Group controlId={"isRefreshNextMonth"}>
                <Form.ControlLabel>
                  Powtarzaj w następnych okresach
                </Form.ControlLabel>
                <Form.Control
                  disabled={currentMonthOption || isInitiallyDisabled}
                  name={"isRefreshNextMonth"}
                  value={targetModel.isRefreshNextMonth}
                  unCheckedChildren={"tylko bieżący miesiąc"}
                  checkedChildren={"Powtarzaj w następnych okresach"}
                  accepter={Toggle}
                  size={"lg"}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId={"currentMonthOption"}>
                <Form.ControlLabel>Obowiązuje dla</Form.ControlLabel>
                <Form.Control
                  disabled={isInitiallyDisabled || isEditDisabled}
                  name={"currentMonthOption"}
                  value={currentMonthOption}
                  unCheckedChildren={"Bieżący miesiąc"}
                  checkedChildren={"Niestandardowa data"}
                  onChange={(checked: boolean) => {
                    setCurrentMonthOption(checked);
                    if (!checked) {
                      setTargetModel((s) => ({
                        ...s,
                        endDate: undefined,
                        periodType: "MONTH",
                      }));
                    } else {
                      setTargetModel((s) => ({
                        ...s,
                        startDate: targetModel.startDate
                          ? dayjs(targetModel.startDate, "YYYY-MM").toDate()
                          : dayjs().startOf("month").toDate(),
                        endDate: dayjs().endOf("month").toDate(),
                        periodType: "CUSTOM",
                        isRefreshNextMonth: false,
                      }));
                    }
                  }}
                  accepter={Toggle}
                  size={"lg"}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId={"startDate"}>
                <Form.ControlLabel>Data startu</Form.ControlLabel>
                <Form.Control
                  disabled={isInitiallyDisabled || isEditDisabled}
                  style={{ width: "100%" }}
                  value={targetModel?.startDate}
                  name={"startDate"}
                  accepter={DatePickerSlider}
                  shouldDisableDate={(date) =>
                    dayjs(date) < dayjs().startOf("month")
                  }
                  format={
                    targetModel.periodType == "MONTH" ? "yyyy-MM" : "yyyy-MM-dd"
                  }
                  ranges={[
                    {
                      label: "Dziś",
                      value: new Date(),
                    },
                  ]}
                  errorMessage={errors?.startDate}
                  errorPlacement={"bottomEnd"}
                />
              </Form.Group>
            </Col>
            {currentMonthOption && (
              <>
                <Col xs={6}>
                  <Form.Group controlId={"endDate"}>
                    <Form.ControlLabel>Data zakończenia</Form.ControlLabel>
                    <Form.Control
                      disabled={isInitiallyDisabled || isEditDisabled}
                      size={"md"}
                      value={targetModel?.endDate}
                      cleanable
                      name={"endDate"}
                      shouldDisableDate={(date) =>
                        dayjs(date) < dayjs().startOf("month")
                      }
                      format={
                        targetModel.periodType == "MONTH"
                          ? "yyyy-MM"
                          : "yyyy-MM-dd"
                      }
                      accepter={DatePickerSlider}
                      ranges={[
                        {
                          label: "Dziś",
                          value: new Date(),
                        },
                      ]}
                      errorMessage={errors?.endDate}
                      errorPlacement={"bottomEnd"}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Col>
          <SeparatorEmpty />
        </Section>
      </Col>
    );
  };

  return (
    <>
      <WhiteCard
        style={{
          position: "relative",
          overflow: "hidden",
          height: "calc(100vh - 100px)",
        }}>
        <Col xs={4}>
          <Steps vertical current={step} style={{ opacity: ".6" }}>
            <Steps.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                setStep(baseData);
              }}
              title="Dane podstawowe"
              description={
                errors &&
                errors?.length > 0 && (
                  <div style={{ color: baseStepHasErrors() ? "red" : "" }}>
                    dane podstawowe zawierają błędy&nbsp;
                    <span>{errors?.length > 0 ? errors?.length : ""}</span>
                  </div>
                )
              }
            />
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisania użytkowników"
                onClick={() => {
                  setStep(usersDataStep);
                }}
              />
            )}
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisania produktów"
                onClick={() => {
                  setStep(itemDataStep);
                }}
              />
            )}
            {mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisanie lokalizacji"
                onClick={() => {
                  setStep(isQuestionType ? locationDataStep : lastStep);
                }}
              />
            )}
            {isQuestionType && mode == "edit" && (
              <Steps.Item
                style={{ cursor: "pointer" }}
                title="Przypisanie pytań"
                onClick={() => {
                  setStep(questionDataStep);
                }}
              />
            )}
          </Steps>
          {errors?.message && <span>errors.message</span>}
          {mode == "edit" && (
            <IconButton
              onClick={() => {
                if (step == lastStep) handleSubmit();
                else nextStep();
              }}
              appearance={"ghost"}
              icon={step == lastStep ? <EditIcon /> : <ArrowRightLineIcon />}>
              {step == lastStep ? "Zapisz konfigurację" : "Dalej"}
            </IconButton>
          )}
        </Col>
        <Col
          xs={20}
          style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
          {step == baseData && (
            <Section
              title={"Dane podstawowe"}
              style={{ backgroundColor: "white" }}>
              <Form
                fluid
                onChange={(form: Partial<ITargetModel>) => {
                  setTargetModel((model) => ({ ...model, ...form }));
                  Object.keys(form).forEach((key) => {
                    if (form[key] && errors && errors[key])
                      errors[key] = undefined;
                  });
                }}>
                <Col xs={12}>
                  <Form.Group controlId={"type"}>
                    <Form.ControlLabel>Typ</Form.ControlLabel>
                    <Form.Control
                      disabled={mode == "edit"}
                      style={{ width: "100%" }}
                      size={"md"}
                      value={targetModel?.type}
                      cleanable
                      accepter={SelectPicker}
                      data={targetForm?.types?.options ?? []}
                      labelKey={"name"}
                      valueKey={"id"}
                      name={"type"}
                      errorMessage={errors?.type}
                      errorPlacement={"bottomEnd"}
                      onChange={(type) => {
                        if (
                          type &&
                          targetForm?.predefinedBody?.options?.[type]
                        ) {
                          setTargetModel((s) => ({
                            ...s,
                            description:
                              targetForm.predefinedBody?.options?.[type] ?? "",
                          }));
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group controlId={"status"}>
                    <Form.ControlLabel>Status</Form.ControlLabel>
                    <Form.Control
                      disabled={isInitiallyDisabled}
                      style={{ width: "100%" }}
                      size={"md"}
                      value={targetModel?.status}
                      cleanable
                      accepter={SelectPicker}
                      data={targetForm?.statuses?.options ?? []}
                      labelKey={"name"}
                      valueKey={"id"}
                      name={"status"}
                      errorMessage={errors?.status}
                      errorPlacement={"bottomEnd"}
                    />
                  </Form.Group>
                </Col>
                <SeparatorEmpty />
                {periodOptionsRenderer()}
                <Col xs={24}>
                  <SeparatorEmpty />
                  <Section title={"Treść powiadomienia"}>
                    <Form.Group controlId={"description"}>
                      <InputGroup style={{ width: "100%" }}>
                        <Form.Control
                          disabled={isInitiallyDisabled}
                          size={"md"}
                          value={targetModel?.description}
                          name={"description"}
                          errorMessage={errors?.description}
                          errorPlacement={"bottomEnd"}
                        />
                        {targetModel?.description && (
                          <InputGroup.Addon
                            onClick={() => {
                              setTargetModel((s) => ({
                                ...s,
                                description: "",
                              }));
                            }}>
                            <CloseIcon style={{ cursor: "pointer" }} />
                          </InputGroup.Addon>
                        )}
                      </InputGroup>
                    </Form.Group>
                    <TagsRender />
                  </Section>
                </Col>
                <Col xs={24}>
                  <Form.Group controlId={"name"}>
                    <Form.ControlLabel>Nazwa</Form.ControlLabel>
                    <InputGroup style={{ width: "100%" }}>
                      <Form.Control
                        disabled={isInitiallyDisabled}
                        size={"md"}
                        value={targetModel?.name}
                        name={"name"}
                        errorMessage={errors?.name}
                        errorPlacement={"bottomEnd"}
                      />
                      {targetModel?.name && (
                        <InputGroup.Addon
                          onClick={() => {
                            setTargetModel((s) => ({ ...s, name: "" }));
                          }}>
                          <CloseIcon style={{ cursor: "pointer" }} />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <SeparatorEmpty />
                </Col>
              </Form>
            </Section>
          )}
          <SeparatorEmpty />
          {mode == "edit" && step == usersDataStep && (
            <>
              <TargetUsers
                projectId={id}
                targetId={_targetId}
                targetModel={targetModel}
                setTargetModel={setTargetModel}
                targetForm={targetForm}
                setTargetForm={setTargetForm}
              />
            </>
          )}
          {mode == "edit" && step == itemDataStep && (
            <ItemDoublePicker
              loading={!targetForm?.items}
              heading={"Przypisania produktów"}
              emptyMessageAssigned={"Dostępny dla wszystkich produktów"}
              emptyMessageNotAssigned={"Brak produktów w systemie"}
              assigned={getItemAssigned()}
              unassigned={getItemUnAssigned()}
              onChange={(assignedIds) => {
                setTargetModel((s) => ({ ...s, items: assignedIds }));
              }}
              filterForm={productFilterData()}
              filterComponent={ItemDoublePicker.Filter}
              itemDataComponent={ItemDoublePicker.Item}
            />
          )}
          {mode == "edit" && step == locationDataStep && (
            <>
              <ItemDoublePicker
                loading={!targetForm?.locations}
                heading={"Przypisania lokalizacji"}
                emptyMessageAssigned={"Dostępny dla wszystkich lokalizacji"}
                emptyMessageNotAssigned={"Brak lokalizacji w systemie"}
                assigned={getLocationsAssigned()}
                unassigned={getLocationsUnAssigned()}
                onChange={(assignedIds) => {
                  setTargetModel((s) => ({ ...s, locations: assignedIds }));
                }}
                filterForm={locationFilterData()}
                filterComponent={ItemDoublePicker.Filter}
                itemDataComponent={ItemDoublePicker.Item}
              />
              <SeparatorEmpty />
              <Message showIcon type={"info"} header={"Uwaga"}>
                <div>
                  Dostępne są tylko lokalizacje użytkowników z ustalonymi
                  Targetami w zakładce &quot;Przypisania użytkowników&quot;
                </div>
                <IconButton
                  appearance={"ghost"}
                  onClick={() => {
                    setStep(usersDataStep);
                  }}
                  icon={<ArrowLeftLineIcon />}>
                  Przypisania użytkowników
                </IconButton>
              </Message>
            </>
          )}
          {isQuestionType && mode == "edit" && step == questionDataStep && (
            <ItemDoublePicker
              loading={!targetForm?.questions}
              heading={"Przypisania pytań"}
              emptyMessageAssigned={"Dostępny dla wszystkich pytań"}
              emptyMessageNotAssigned={"Brak pytań w systemie"}
              assigned={getQuestionsAssigned()}
              unassigned={getQuestionsUnAssigned()}
              onChange={(assignedIds) => {
                setTargetModel((s) => ({ ...s, questions: assignedIds }));
              }}
              filterForm={questionFilterData()}
              filterComponent={ItemDoublePicker.Filter}
              itemDataComponent={ItemDoublePicker.Item}
            />
          )}
        </Col>
        <SeparatorEmpty size={2} />
        <ActionsContainer>
          <Button
            appearance={"ghost"}
            onClick={() => history.push(`/projects/${id}/targets`)}>
            Anuluj
          </Button>
          <IconButton
            onClick={() => {
              handleSubmit();
            }}
            appearance={"ghost"}
            icon={<EditIcon />}>
            Zapisz konfigurację
          </IconButton>
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};
export default TargetsEdit;
