import React, {
  FunctionComponent,
  ReactElement,
  useMemo,
  useState,
} from "react";
import styles from "./styles.module.scss";
import {
  Badge,
  Col,
  Form,
  IconButton,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import { IChecked } from "./DoubleSidePicker";
import { TypeDoubleSidePickerItems } from "../../../utils/types";
import { IOptionBase } from "../../../utils/models";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AngleDoubleDown from "@rsuite/icons/legacy/AngleDoubleDown";
import { IFilterForm, IItemBody } from "./DoubleSideThinPicker";
import { isArray } from "lodash";

export interface IFilter {
  [a: string]: string;
}

interface IItemsThinList {
  handleToggleCheckbox: (itemId: string) => void;
  handleToggleMainCheckbox: (ids: Array<string>) => void;
  checked: Array<string>;
  setChecked: React.Dispatch<React.SetStateAction<IChecked>>;
  data: {
    assigned: TypeDoubleSidePickerItems;
    unassigned: TypeDoubleSidePickerItems;
  };
  dataType: "assigned" | "unassigned";
  emptyMessage: ReactElement | string;
  pickedId: string | null;
  setPickedId: React.Dispatch<React.SetStateAction<string | null>>;
  onSortChange?: (assignedSortedData: TypeDoubleSidePickerItems) => void;
  triggerFilter: number;
  actionOnAssigned?: (event) => void;
  filterForm?: IFilterForm;
  filterComponent?: React.ComponentType<any>;
  itemDataComponent: React.ComponentType<IItemBody>;
}

const ItemsThinList: FunctionComponent<IItemsThinList> = (props) => {
  const resultLimit = props.dataType === "unassigned" ? 999 : 0; // 0 = no limit
  const [filter, setFilter] = useState<IFilter | null>(null);
  const [resultCapped, setResultCapped] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(0);

  const classForCheckedCheckbox = () => {
    let cn = styles.checkboxCounter;
    if (
      props.checked?.length &&
      props.checked?.length === filteredDataMemo.length
    ) {
      cn += " " + styles.checked;
    }
    return cn;
  };

  function getMyTypeData<
    T extends TypeDoubleSidePickerItems = TypeDoubleSidePickerItems,
  >(): T {
    if (props.dataType === "assigned") return props.data.assigned as T;
    return props.data.unassigned as T;
  }

  const getFilterForm = (): JSX.Element => {
    if (props.filterComponent)
      return (
        <props.filterComponent
          filter={filter}
          setFilter={setFilter}
          form={props.filterForm}>
          {Object.entries(props?.filterForm ?? {}).map(([key, item]) => {
            if (!key) return <></>;
            let options = item.options;

            if (item.parentFilterId && isArray(item.parentFilterId)) {
              item.parentFilterId.forEach((parentFilterId, index) => {
                if (
                  filter?.[parentFilterId] &&
                  options?.[index]?.[parentFilterId]
                ) {
                  options = options.filter((o) =>
                    filter[parentFilterId]
                      ? o?.[parentFilterId] == filter[parentFilterId]
                      : true
                  );
                }
              });
            }
            if (item.parentFilterId && typeof item.parentFilterId == "string") {
              const parentFilterId = item?.parentFilterId ?? "";
              options = options.filter((o) =>
                filter?.[parentFilterId]
                  ? o?.[parentFilterId] == filter[parentFilterId]
                  : true
              );
            }
            return (
              <Col xs={24} key={`filter-control-${key}`}>
                <Form.ControlLabel>{item?.filterLabel}</Form.ControlLabel>
                <Form.Control
                  block
                  name={item.filterId}
                  value={filter?.[item.filterId]}
                  labelKey="name"
                  valueKey="id"
                  accepter={SelectPicker}
                  data={options ?? []}
                  onChange={(value) => {
                    setFilter((s) => ({ ...s, [item.filterId]: value }));
                  }}
                />
              </Col>
            );
          })}
        </props.filterComponent>
      );
    return <></>;
  };

  const getFilteredData = (
    filter: IFilter | null
  ): TypeDoubleSidePickerItems => {
    let filteredResult = [];
    const isFilterActive = filter && Object.keys(filter).length > 0;
    if (!isFilterActive) return getMyTypeData();

    filteredResult = getMyTypeData();
    if (filter?.textSearch?.length > 0) {
      filteredResult = filteredResult.filter((item: IOptionBase) =>
        item.name.toLowerCase().includes(filter?.textSearch?.toLowerCase())
      );
    }

    Object.entries(filter).forEach(([key, value]) => {
      if (key !== "textSearch" && value) {
        filteredResult = filteredResult.filter((item) =>
          item?.[key] ? item[key] == value : true
        );
      }
    });

    if (resultLimit > 0 && filteredResult.length > resultLimit) {
      setResultCapped(true);
      return filteredResult.splice(0, resultLimit);
    }
    setResultCapped(false);
    return filteredResult;
  };

  const filteredDataMemo = useMemo<TypeDoubleSidePickerItems>(
    () => getFilteredData(filter),
    [filter, props.triggerFilter, triggerFilter]
  );

  const dataWrapper = (children: any): JSX.Element => {
    const handleOnItemsSortEnd = (event: DragEndEvent) => {
      const activeId = event?.active?.id;
      const overId = event?.over?.id;

      if (activeId !== overId) {
        const oldIndex = getMyTypeData().findIndex((v) => v.id === activeId);
        const newIndex = getMyTypeData().findIndex((v) => v.id === overId);
        if (props.onSortChange) {
          props.onSortChange(arrayMove(getMyTypeData(), oldIndex, newIndex));
        }
      }
      setTriggerFilter(Date.now());
    };

    if (props.onSortChange) {
      return (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[
            restrictToVerticalAxis,
            restrictToWindowEdges,
            restrictToFirstScrollableAncestor,
          ]}
          onDragEnd={handleOnItemsSortEnd}>
          <SortableContext
            items={getMyTypeData().map((val) => val.id)}
            disabled={!filter}
            strategy={verticalListSortingStrategy}>
            {children}
          </SortableContext>
        </DndContext>
      );
    }
    return <>{children}</>;
  };
  const dataMapper = (item): JSX.Element => {
    const baseItem: IOptionBase = item;
    return (
      <props.itemDataComponent
        key={`item-base-body-${baseItem.id}`}
        handleToggleCheckbox={props.handleToggleCheckbox}
        item={baseItem}
        checked={props.checked}
      />
    );
  };

  return (
    <>
      <div style={{ marginBottom: "25px", borderColor: "#ffaf38" }}>
        {getFilterForm()}
        <div className={styles.doubleSideHeading}>
          <div className={styles.itemListWrapper}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!props.checked?.length ? (
                <span
                  className={classForCheckedCheckbox()}
                  //@ts-ignore
                  onClick={props.handleToggleMainCheckbox.bind(
                    null,
                    filteredDataMemo.map((item) => item.id)
                  )}
                />
              ) : (
                <Badge color={"orange"} content={props.checked?.length}>
                  <span
                    className={classForCheckedCheckbox()}
                    //@ts-ignore
                    onClick={props.handleToggleMainCheckbox.bind(
                      null,
                      filteredDataMemo.map((item) => item.id)
                    )}
                  />
                </Badge>
              )}
              <span className={styles.columnAndCounter}>
                {props.dataType === "assigned" ? "Przypisane" : "Nieprzypisane"}{" "}
                ({filteredDataMemo.length}
                {resultCapped && <strong>+</strong>}
                {!filter && <FunnelIcon />})
              </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              gap: "5px",
            }}>
            {typeof props.actionOnAssigned === "function" && (
              <div>
                <Whisper
                  placement={"auto"}
                  trigger={"hover"}
                  speaker={
                    <Tooltip>Edycja pytań, wartość predefiniowana</Tooltip>
                  }>
                  <IconButton
                    onClick={props.actionOnAssigned}
                    appearance={"ghost"}
                    icon={<AngleDoubleDown />}
                  />
                </Whisper>
              </div>
            )}
          </div>
        </div>

        <div
          className={styles.wrapperItemDataList}
          style={
            props.dataType === "assigned" ? { borderColor: "#ffaf38" } : {}
          }>
          {!filteredDataMemo.length ? (
            props.emptyMessage ? (
              filter ? (
                <div className={styles.emptyMessage}>
                  <FunnelIcon />
                  &nbsp;{`Nie znaleziono elementów przy obecnym filtrowaniu`}
                </div>
              ) : (
                <div className={styles.emptyMessage}>{props.emptyMessage}</div>
              )
            ) : (
              <div className={styles.emptyMessage}>
                Brak elementów na liście
              </div>
            )
          ) : (
            dataWrapper(filteredDataMemo.map(dataMapper))
          )}
        </div>
      </div>
    </>
  );
};
export default ItemsThinList;
