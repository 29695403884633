import React, { FunctionComponent, useEffect, useState } from "react";
import ProjectImports, {
  IImportFrom,
} from "../../../../utils/connections/imports";
import { IExportDownloadRequest, TExportType } from "../../../../utils/models";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";
import ButtonSubmit from "../../../../global/atoms/ButtonSubmit";
import {
  Checkbox,
  Col,
  DateRangePicker,
  Form,
  IconButton,
  Input,
  InputGroup,
  List,
  Message,
  Modal,
  Panel,
  Row,
  SelectPicker,
} from "rsuite";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { handleToastRedux } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import download from "downloadjs";
import Section from "../../../../global/atoms/section/Section";

import FormControlLabel from "rsuite/FormControlLabel";
import CloseIcon from "@rsuite/icons/Close";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import dayjs from "dayjs";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

interface IExportModalAdd {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dateFrom?: Date;
  dateTo?: Date;
  defaultType?: TExportType;
  isExportWorking?: boolean;
  setIsExportWorking?: (boolean) => void;
  exportList?: any;
}

const TYPE_TIMETABLE: TExportType = "timetable";
const TYPE_TIMETABLE_ROUTES: TExportType = "timetable_routes";
const TYPE_LOCATIONS: TExportType = "locations";

const ExportModalAdd: FunctionComponent<IExportModalAdd> = (props) => {
  const [exportOptions, setExportOptions] = useState<IImportFrom | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState(false);

  const dateTo = props.dateTo ?? new Date();
  const getLastMonthNum = (currMonth: number) =>
    currMonth - 1 < 0 ? 11 : currMonth - 1;
  const dateFrom =
    props?.dateFrom ??
    new Date(
      dateTo.getFullYear(),
      getLastMonthNum(dateTo.getMonth()),
      dateTo.getDay()
    );
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<IExportDownloadRequest>({
    startDate: dateFrom,
    endDate: dateTo,
    type: props?.defaultType ?? TYPE_TIMETABLE,
    locationCode: false,
    groupByLocationUser: false,
  });
  const [locationState, setLocationState] = useState<{
    locationName: string;
    networkName: string;
    cityName: string;
    voivodeshipName: string;
    locationOrdinalNr: boolean;
  }>({
    locationName: "",
    cityName: "",
    voivodeshipName: "",
    networkName: "",
    locationOrdinalNr: false,
  });

  useEffect(() => {
    if (props.open) {
      ProjectImports.importsForm().then((res) => {
        setExportOptions(res.data);
      });
    }
  }, [props.open]);

  const onSubmit = () => {
    setIsLoading(true);
    handleToastRedux(
      ProjectImports.downloadExport({ ...state, ...locationState }),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      props.setOpen(false);
      setIsLoading(false);
    });
  };
  const getFileFromRepo = (fileHash) => {
    setIsLoading(true);
    handleToastRedux(
      ProjectImports.downloadExportFile(fileHash),
      dispatch
    ).then((res) => {
      download(res.data, res.headers["x-filename"] ?? null);
      props.setOpen(false);
      setIsLoading(false);
    });
  };

  const renderExportFiles = () => {
    return (
      <>
        <Panel
          header={
            <span style={{ fontSize: "12px" }}>Istniejące pliki eksportów</span>
          }>
          <List style={{ maxHeight: "300px" }}>
            {props.exportList &&
              (
                Object.values(props.exportList) as Array<{
                  fileHash: string;
                  date: number;
                  file: string;
                  size: number;
                  unit: string;
                }>
              ).map((el) => {
                const types = el.file.split("_");
                if (types[1] == state.type) {
                  return (
                    <List.Item
                      size={"sm"}
                      style={{ fontSize: "10px" }}
                      key={el.fileHash}>
                      <Row style={{ display: "flex", alignItems: "flex-end" }}>
                        <Col xs={8}>
                          {types[1] == "locations"
                            ? "Lokalizacje: "
                            : types[1] == "timetable"
                            ? "Harmonogramy: "
                            : types[1]}
                        </Col>
                        <Col xs={8}>
                          {dayjs(el.date * 1000).format("YYYY-MM-DD HH:mm:ss")}
                        </Col>
                        <Col xs={4}>
                          {el.size} {el.unit}
                        </Col>
                        <Col xs={4}>
                          <IconButton
                            size={"xs"}
                            appearance={"ghost"}
                            onClick={() => {
                              getFileFromRepo(el.fileHash);
                            }}
                            icon={<FileDownloadIcon />}
                          />
                        </Col>
                      </Row>
                    </List.Item>
                  );
                }
              })}
          </List>
        </Panel>
      </>
    );
  };
  const renderModalBody = () => {
    return (
      <>
        <Col xs={12}>
          <label>Typ eksportu</label>
          <SelectPicker
            disabled={
              isLoading ||
              (props?.defaultType !== null && state.type == props?.defaultType)
            }
            data={exportOptions?.export_types?.options ?? []}
            labelKey={"name"}
            valueKey={"id"}
            block
            onChange={(value) =>
              setState((s) => ({
                ...s,
                type: value as TExportType,
                locationCode: false,
              }))
            }
            searchable={false}
            // style={{ width: '100%', zIndex: 9999 }}
            cleanable={false}
            value={state.type}
          />
        </Col>
        <Col xs={12}>
          <label>Format</label>
          <SelectPicker
            data={[{ label: ".xlsx", value: "xlsx" }]}
            style={{ width: "100%" }}
            cleanable={false}
            value={"xlsx"}
            readOnly={true}
          />
        </Col>
        {state.type == TYPE_TIMETABLE && (
          <>
            <SeparatorEmpty size={2.5} />
            <Section title={"Opcje"} titleSize={"12px"}>
              <Col xs={24}>
                <Checkbox
                  id={"idx_locationCode"}
                  onChange={(value, checked) => {
                    setState((s) => ({ ...s, locationCode: checked }));
                  }}
                  checked={state.locationCode}
                />
                <FormControlLabel htmlFor={"idx_locationCode"}>
                  Kod Klienta dla lokalizacji
                </FormControlLabel>
              </Col>
              <SeparatorEmpty size={1.5} />
            </Section>
          </>
        )}
        {state.type == TYPE_LOCATIONS && (
          <>
            <SeparatorEmpty size={2.5} />
            <Section title={"Eksport lokalizacji: Opcje"} titleSize={"12px"}>
              <Form fluid>
                <Row>
                  <Col xs={24}>
                    <FormControlLabel>Lokalizacja</FormControlLabel>
                    <InputGroup>
                      <Input
                        value={locationState?.locationName}
                        onChange={(value) => {
                          setLocationState((s) => ({
                            ...s,
                            locationName: value,
                          }));
                        }}
                      />
                      {locationState?.locationName && (
                        <InputGroup.Addon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLocationState((s) => ({
                              ...s,
                              locationName: "",
                            }));
                          }}>
                          <CloseIcon />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Col>
                  <Col xs={24}>
                    <FormControlLabel>Sieć</FormControlLabel>
                    <InputGroup>
                      <Input
                        value={locationState?.networkName}
                        onChange={(value) => {
                          setLocationState((s) => ({
                            ...s,
                            networkName: value,
                          }));
                        }}
                      />
                      {locationState?.networkName && (
                        <InputGroup.Addon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLocationState((s) => ({
                              ...s,
                              networkName: "",
                            }));
                          }}>
                          <CloseIcon />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Col>
                  <Col xs={24}>
                    <FormControlLabel>Miejscowość</FormControlLabel>
                    <InputGroup>
                      <Input
                        value={locationState?.cityName}
                        onChange={(value) => {
                          setLocationState((s) => ({
                            ...s,
                            cityName: value,
                          }));
                        }}
                      />
                      {locationState?.cityName && (
                        <InputGroup.Addon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLocationState((s) => ({ ...s, cityName: "" }));
                          }}>
                          <CloseIcon />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Col>

                  <Col xs={24}>
                    <FormControlLabel>Województwo</FormControlLabel>
                    <InputGroup>
                      <Input
                        value={locationState?.voivodeshipName}
                        onChange={(value) => {
                          setLocationState((s) => ({
                            ...s,
                            voivodeshipName: value,
                          }));
                        }}
                      />
                      {locationState?.voivodeshipName && (
                        <InputGroup.Addon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLocationState((s) => ({
                              ...s,
                              voivodeshipName: "",
                            }));
                          }}>
                          <CloseIcon />
                        </InputGroup.Addon>
                      )}
                    </InputGroup>
                  </Col>
                  <Col xs={24}>
                    <FormControlLabel>Kolumny opcjonalne</FormControlLabel>
                    <InputGroup>
                      <Checkbox
                        checked={locationState?.locationOrdinalNr}
                        onChange={(value, checked) => {
                          setLocationState((s) => ({
                            ...s,
                            locationOrdinalNr: checked,
                          }));
                        }}>
                        Nr porządkowy lokalizacji
                      </Checkbox>
                    </InputGroup>
                  </Col>
                </Row>
                <SeparatorEmpty size={1.5} />
              </Form>
            </Section>
          </>
        )}
        {state.type == TYPE_TIMETABLE_ROUTES && (
          <>
            <SeparatorEmpty size={1.5} />
            <Section title={"Opcje"} titleSize={"12px"}>
              <Col xs={24}>
                <Checkbox
                  id={"idx_group_by_location_user"}
                  onChange={(value, checked) => {
                    setState((s) => ({ ...s, groupByLocationUser: checked }));
                  }}
                  checked={state.groupByLocationUser}
                />
                <FormControlLabel htmlFor={"idx_group_by_location_user"}>
                  Zaprezentuj wszystkie skonfigurowane zadania w jednym wierszu
                  (dla pary danych lokalizacja i użytkownik)
                </FormControlLabel>
              </Col>
              <SeparatorEmpty size={1.5} />
            </Section>
          </>
        )}
        <SeparatorEmpty size={1.5} />
        {state.type != TYPE_LOCATIONS && (
          <>
            <Col xs={24}>
              <label>Data od - do</label>
              <DateRangePicker
                block
                value={[state.startDate, state.endDate]}
                ranges={[]}
                onOk={(value) => {
                  // @ts-ignore
                  setState((s) => ({
                    ...s,
                    startDate: value[0],
                    endDate: value[1],
                  }));
                }}
              />
            </Col>
            <SeparatorEmpty />
          </>
        )}
      </>
    );
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false);
          setIsLoading(false);
        }}
        size={"lg"}>
        <Modal.Header>
          <Modal.Title>Nowy eksport</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "100%" }}>
          <Col xs={props?.exportList ? 12 : 24}>{renderModalBody()}</Col>
          {props?.exportList && <Col xs={12}>{renderExportFiles()}</Col>}
          <Col xs={24}>
            {isLoading && (
              <Message
                type={"warning"}
                showIcon
                header={"Trwa operacja pobierania pliku eksportu."}>
                W zależności od wielkości pobieranych danych, operacja{" "}
                <span style={{ fontWeight: "bold" }}>
                  {exportOptions?.export_types?.options.find(
                    (s) => s.id == state.type
                  )?.name ?? ""}
                </span>{" "}
                może trwać od kilku sekund do kilku minut.{" "}
                <b>Nie zamykaj okna!</b>
              </Message>
            )}
          </Col>
        </Modal.Body>
        <Modal.Footer>
          {isLoading && <SpinnerSmall />}
          <div style={{ textAlign: "right" }}>
            <ButtonOutlined
              onClick={() => props.setOpen(false)}
              //disabled={isLoading}
            >
              Anuluj
            </ButtonOutlined>
            &nbsp;
            <ButtonSubmit
              name={"Pobierz"}
              onClick={onSubmit}
              disabled={isLoading}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportModalAdd;
