import React, {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import DashboardConnection from "../../../utils/connections/dashboard";
import { _paginate30 } from "../../../utils/states";
import {
  Checkbox,
  ColumnProps,
  Dropdown,
  Pagination,
  Panel,
  Popover,
  Whisper,
} from "rsuite";
import IconSvg from "../../../global/atoms/IconHelper";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";

import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";

import { RowDataType } from "rsuite/esm/Table";
import { handleSortColumn } from "../../../utils/helpers";
import { IGetPaginationBase } from "../../../utils/models";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import EditIcon from "@rsuite/icons/Edit";
import { Helmet } from "react-helmet-async";
import { isEqual, unionBy } from "lodash";
import MenuIcon from "@rsuite/icons/Menu";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import SortableList from "../../../global/atoms/dnd/SortableList";
import { DragHandle } from "../../../global/atoms/SortableItem";
import Table, {
  getColumnsFromStorage,
  getFiltersFromStorage,
} from "./TableFiltered";
import BadgeStatus from "../../../global/atoms/badge/BadgeStatus";

interface IVStatus {
  id: string;
  name: string;
  color: string;
}

interface IVisitData {
  locationName: string;
  projectName: string;
  networkName: string;
  locationAddressCityName: string;
  startDate: string;
  reportingUserName: string;
  status: { name: string; color: string };
  projectId: string;
  id: string;
  users: Array<{
    roleId: string;
    roleName: string;
    userName: string;
    roleLevel: number;
    isReporting: boolean;
  }>;
}

interface IListData {
  count: number;
  data: Array<IVisitData>;
}

interface IStatusCounter {
  status: IVStatus;
  statusCounter: number;
}

interface IColumn {
  name: string;
  label: string | ReactElement;
  dataKey?: string;
  renderCell?: (rowData: RowDataType<IVisitData>) => React.ReactElement;
  props?: ColumnProps;
  filter?: any;
  filterKey?: string;
  multiselect?: boolean;
  defaultVisible?: boolean;
  visible?: boolean;
}

export interface IFilter extends IGetPaginationBase {
  status?: string;
  locationIds?: Array<string>;
  projectIds?: Array<string>;
}

// Function to transform table data
const transformDataForExcel = (
  data: Array<IVisitData>,
  columns: Array<IColumn>,
  selectedColumns: Array<string>
) => {
  return data.map((row) => {
    const transformedRow: { [key: string]: any } = {};
    columns.forEach((column: IColumn) => {
      if (selectedColumns.includes(column.name)) {
        const _cellData =
          row[column.name] ??
          row.users.find((user) => user.roleId === column.name) ??
          "-";
        if (_cellData instanceof Object) {
          transformedRow[column.label as string] =
            _cellData?.name ?? _cellData?.userName ?? "-";
        } else {
          transformedRow[column.label as string] = _cellData ?? "-";
        }
      }
    });
    return transformedRow;
  });
};

const exportToExcel = (data: Array<IVisitData>, columns: Array<IColumn>) => {
  const selectedColumns = columns.filter((c) => c.visible).map((c) => c.name);
  const transformedData = transformDataForExcel(data, columns, selectedColumns);
  const worksheet = XLSX.utils.json_to_sheet(transformedData);

  // Calculate column widths
  const maxLengths = transformedData.reduce((acc: Array<number>, row) => {
    Object.keys(row).forEach((key, index) => {
      const value = row[key];
      acc[index] = Math.max(acc[index] || 0, value.length);
    });
    return acc;
  }, []);

  worksheet["!cols"] = maxLengths.map((length) => ({ wch: length + 2 }));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Statusy");
  XLSX.writeFile(
    workbook,
    `Wizyty niezrealizowane ${dayjs().format("YYYY-MM-DD HHmmss")}.xlsx`
  );
};

const DashboardVisitsList: FunctionComponent = () => {
  const storageFilterKey = "dashboard-table-columns-config";

  const defaultFilter: IFilter = {
    ..._paginate30,
  };

  const [data, setData] = useState<IListData | undefined>();
  const [statusesCounterData, setStatusesCounterData] = useState<
    Array<IStatusCounter> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [statusesLoading, setStatusesLoading] = useState(false);
  const [form, setForm] = useState<any>();
  const _filters: IFilter = {
    ...defaultFilter,
    ...getFiltersFromStorage(storageFilterKey),
  };
  const [filters, setFilters] = useState<IFilter>(_filters);

  const loadData = () => {
    setLoading(true);
    DashboardConnection.getUnrealizedVisits(filters).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  const getRolesData = useCallback(() => {
    const rolesMap = new Map<
      string,
      { roleName: string; roleLevel: number; isReporting: boolean }
    >();
    data?.data.forEach((item) => {
      item.users.forEach((user) => {
        if (!rolesMap.has(user.roleId)) {
          rolesMap.set(user.roleId, {
            roleName: user.roleName,
            roleLevel: user.roleLevel,
            isReporting: user.isReporting,
          });
        }
      });
    });
    const rolesArray = Array.from(
      rolesMap,
      ([roleId, { roleName, roleLevel, isReporting }]) => ({
        roleId,
        roleName,
        roleLevel,
        isReporting,
      })
    );

    rolesArray.sort((a, b) => {
      if (b.isReporting === a.isReporting) {
        return b.roleLevel - a.roleLevel;
      }
      return b.isReporting ? 1 : -1;
    });
    return rolesArray;
  }, [data]);

  useEffect(() => {
    setStatusesLoading(true);
    DashboardConnection.getUnrealizedFilterForm().then((response) => {
      setForm(response.data);
      setStatusesCounterData(response.data.visitsStatuses.options);
      setStatusesLoading(false);
    });
  }, []);

  const outLineActive = (color: string): CSSProperties => ({
    border: "1px solid",
    borderColor: color,
    borderRadius: "15px",
  });
  const getDataColumns = (): Array<IColumn> => [
    {
      name: "locationName",
      label: "Lokalizacja",
      props: { sortable: true, width: 200, resizable: true },
      filter: "locations",
      filterKey: "locationIds",
      multiselect: true,
      defaultVisible: true,
    },
    {
      name: "projectName",
      label: "Projekt",
      props: { sortable: true, width: 200, resizable: true },
      filter: "projects",
      filterKey: "projectIds",
      defaultVisible: true,
    },
    {
      name: "locationAddressCityName",
      label: "Miejscowość",
      props: { sortable: true, fullText: true, resizable: true },
      defaultVisible: true,
    },
    {
      name: "locationAddress",
      label: "Adres",
      props: { fullText: true, resizable: true },
    },
    {
      name: "startDate",
      label: "Data Wizyty",
      props: { flexGrow: 1, sortable: true },
      defaultVisible: true,
    },
    {
      name: "networkName",
      label: "Sieć",
    },
    {
      name: "taskNames",
      label: "Zadania",
      defaultVisible: true,
      props: { fullText: true, resizable: true, width: 350 },
    },
    ...getRolesData().map((role) => ({
      name: role.roleId,
      label: role.roleName,
      dataKey: role.isReporting ? "reportingUserName" : undefined,
      props: { flexGrow: 1, sortable: role.isReporting },
      defaultVisible: role.isReporting,
      renderCell: (rowData: RowDataType<IVisitData>) => {
        const user = rowData.users.find((user) => user.roleId === role.roleId);
        return <>{user ? user.userName : "-"}</>;
      },
    })),
    {
      name: "status",
      label: "Status",
      renderCell: (rowData: RowDataType<IVisitData>) => {
        return (
          <BadgeStatus color={rowData.status.color}>
            {rowData.status.name}
          </BadgeStatus>
        );
      },
      defaultVisible: true,
      props: { fixed: "right", sortable: true, width: 150 },
    },
  ];

  const getMergedColumns = (columns?: IColumn[]) => {
    const storedColumns = columns ?? getColumnsFromStorage(storageFilterKey);
    const columnsDefinition = getDataColumns();
    return unionBy(storedColumns, columnsDefinition, "name").map((c) => {
      const columnDefinition = columnsDefinition.find(
        (cd) => cd.name == c.name
      );
      return {
        ...c,
        ...columnDefinition,
        visible: c.visible ?? columnDefinition?.defaultVisible,
        props: {
          ...columnDefinition?.props,
          width: c.props?.width ?? columnDefinition?.props?.width,
        },
      };
    });
  };
  const [dataColumns, setDataColumns] = useState<IColumn[]>(getMergedColumns());

  //refreshing dataColumns column list when data columns changes
  useEffect(() => {
    const columns = getMergedColumns();
    if (data) setDataColumns(columns);
  }, [data]);

  const actionsColumn = {
    name: "actions",
    label: (
      <CloseOutlineIcon
        title={"Wyczyść filtry"}
        style={{ cursor: "pointer" }}
        color={!isEqual(defaultFilter, filters) ? "#E09616" : "#CCCC"}
        onClick={() => {
          setDataColumns((prev) => {
            return prev.map((e) => ({ ...e, visible: e.defaultVisible }));
          });
          setFilters(_paginate30);
        }}
      />
    ),
    renderCell: (rowData: any) => {
      return (
        <EditIcon
          style={{ cursor: "pointer" }}
          color={"#E09616"}
          onClick={() => {
            window.open(
              `/projects/${rowData.projectId}/visits/${rowData.id}/edit`,
              "_blank"
            );
          }}
        />
      );
    },
  };

  const renderTableColumnsChooser = () => {
    const handleCheckboxChange = (
      name: ValueType | undefined,
      checked: boolean
    ) => {
      setDataColumns((s) => {
        return s.map((col) => ({
          ...col,
          visible: col.name == name ? checked : col.visible,
        }));
      });
    };
    const onSortEnd = (sortedData: IColumn[]) => {
      setDataColumns(getMergedColumns(sortedData));
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "200px",
          cursor: "pointer",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>Kolumny</div>
          <div>
            <CloseOutlineIcon
              style={{ cursor: "pointer" }}
              color={
                !isEqual(dataColumns, getMergedColumns()) ? "#E09616" : "#CCCC"
              }
              onClick={() => {
                setDataColumns(getDataColumns() as IColumn[]);
              }}
            />
          </div>
        </div>

        <SortableList
          onSortEnd={onSortEnd}
          data={dataColumns.map((c) => ({
            name: c.name,
            label: c.label,
            defaultVisible: c.defaultVisible,
            visible: c.visible,
          }))}
          idKeyName={"name"}
          disabled={false}
          dragHandleActivator={true}
          mapFunction={(column: IColumn, index) => (
            <>
              <div
                key={"sortable-div-" + index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Checkbox
                  key={column.name}
                  value={column.name}
                  checked={column.visible ?? column.defaultVisible}
                  onChange={handleCheckboxChange}>
                  {column.label}
                </Checkbox>
                <DragHandle apperace={"ghost"} size={"xs"} />
              </div>
            </>
          )}
        />
      </div>
    );
  };

  const tableHeaderMenu = () => (
    <Whisper
      placement="bottomEnd"
      trigger="click"
      speaker={
        <Popover>
          <Dropdown.Menu style={{ width: 200 }}>
            <Dropdown.Item
              onClick={() => {
                exportToExcel(data?.data ?? [], dataColumns);
              }}
              icon={<FileDownloadIcon />}>
              Eksport do Excel
            </Dropdown.Item>
            <Dropdown.Separator />
          </Dropdown.Menu>
          {renderTableColumnsChooser()}
        </Popover>
      }>
      <MenuIcon style={{ cursor: "pointer", color: "#E09616" }} />
    </Whisper>
  );

  const AvailableStatuses = () => {
    const internalStatusStyle = {
      fontFamily: "Poppins, sans-serif",
      padding: "5px",
      fontWeight: 500,
      letterSpacing: "0.6px",
    };
    if (statusesCounterData === undefined) return <></>;
    return (
      <>
        {statusesCounterData?.map((counter, index) => (
          <div
            key={`status-${counter.status.id}-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "flex-start",
              gap: "5px",
              paddingRight: "10px",
              cursor: "pointer",
              ...(filters?.status == counter.status.id
                ? outLineActive(counter.status.color)
                : {}),
            }}
            onClick={() => {
              setFilters((f) => {
                if (!f) return { ...defaultFilter, status: counter.status.id };
                return { ...f, status: counter.status.id };
              });
            }}>
            <span
              style={{
                flexGrow: 1,
                ...internalStatusStyle,
                color: counter.status.color,
                borderColor: counter.status.color,
              }}>
              {IconSvg(
                faDotCircle,
                counter.status.name,
                false,
                counter.status.color
              )}
              {counter.status.name}
            </span>
            <span
              key={counter.status.id}
              style={{
                fontSize: "10px",
                fontWeight: 700,
                backgroundColor: counter.status.color,
                border: "1px solid",
                borderColor: counter.status.color,
                borderRadius: "10px",
                padding: "1px 5px 0px 5px",
                color: "white",
              }}>
              {counter.statusCounter}
            </span>
          </div>
        ))}
      </>
    );
  };

  if (statusesLoading) return <Spinner />;

  return (
    <>
      <Panel
        bordered
        style={{ backgroundColor: "white", padding: "10px" }}
        bodyFill>
        <Table.TableFilter
          storageFilterKey={storageFilterKey}
          columns={dataColumns}
          setColumns={setDataColumns}
          filters={filters}
          setFilters={setFilters}
          loadData={loadData}>
          <>
            <div
              key={"status-filter"}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <div style={{ width: "200px" }}>Stan realizacji wizyt:</div>
                <AvailableStatuses />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseOutlineIcon
                    style={{
                      cursor: "pointer",
                      color: filters?.status ? "#E09616" : "#CCCC",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      if (filters?.status)
                        setFilters({ ...filters, status: "" });
                    }}
                  />
                </div>
              </div>
              {tableHeaderMenu()}
            </div>
          </>
        </Table.TableFilter>
        <SeparatorEmpty />
        <Table.Table
          rowHeight={35}
          cellBordered
          loading={loading}
          height={500}
          affixHeader
          data={data?.data ?? []}
          sortColumn={filters?.requestOrder?.field}
          sortType={filters?.requestOrder?.order}
          onSortColumn={(dataKey, sortType) =>
            handleSortColumn(dataKey, sortType, setFilters)
          }>
          {dataColumns
            ?.filter((c) => c.visible ?? c.defaultVisible ?? false)
            .map((column) => {
              return (
                <>
                  <Table.Column
                    {...column.props}
                    onResize={(columnWidth, columnKey) => {
                      setDataColumns((columns) => {
                        return columns.map((column) => ({
                          ...column,
                          props: {
                            ...column.props,
                            width:
                              column.name == columnKey
                                ? columnWidth
                                : column.props?.width,
                          },
                        }));
                      });
                    }}>
                    <Table.HeaderCell>
                      {column.label}
                      {column?.filter && (
                        <Table.ColumnFilter
                          filtersData={form}
                          column={column}
                          filters={filters}
                          setFilters={setFilters}
                        />
                      )}
                    </Table.HeaderCell>
                    {column.renderCell ? (
                      <Table.Cell dataKey={column?.dataKey ?? column.name}>
                        {column.renderCell}
                      </Table.Cell>
                    ) : (
                      <Table.Cell dataKey={column.name} />
                    )}
                  </Table.Column>
                </>
              );
            })}
          <Table.Column fixed={"right"} width={50} align="center">
            <Table.HeaderCell title={"Wyczyść filtry"}>
              {actionsColumn.label}
            </Table.HeaderCell>
            <Table.Cell title={"Idź do wizyty"}>
              {actionsColumn.renderCell}
            </Table.Cell>
          </Table.Column>
        </Table.Table>
        <SeparatorEmpty />

        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "|", "pager", "|", "skip", "|", "total"]}
          total={data?.count ?? 0}
          activePage={(filters ?? defaultFilter)?.requestPaginate?.page}
          limit={(filters ?? defaultFilter)?.requestPaginate?.limit}
          onChangePage={(page) => {
            setFilters((f) => {
              return {
                ...f,
                requestPaginate: { ...f?.requestPaginate, page: page },
              };
            });
          }}
          onChangeLimit={(limit) => {
            setFilters((f) => {
              return {
                ...f,
                requestPaginate: {
                  ...f?.requestPaginate,
                  limit: limit,
                  page: 1,
                },
              };
            });
          }}
          limitOptions={[10, 30, 100, 1000]}
        />
        <SeparatorEmpty />
      </Panel>
      <div style={{ marginBottom: "10px" }}>&nbsp;</div>
      <Helmet>
        <style>{`
        .rs-checkbox {
          height: 25px;
        }
        `}</style>
      </Helmet>
    </>
  );
};

export default DashboardVisitsList;
