import dayjs from "dayjs";
import { IHourData, IScheduleModalData } from "../ScheduleModal";
import { IUserSimple, SetState } from "../../../../utils/models";
import { sortBy } from "lodash";

export const COMMON_FOR_WEEK = "COMMON_FOR_WEEK";
export const DAILY = "DAILY";
export type THoursStrategy = "COMMON_FOR_WEEK" | "DAILY";
export const HOURS_EXPLICITLY = "HOURS_EXPLICITLY";
export const TIME_STRING = "TIME";
enum ScheduleInterval {
  Weekly = 1,
  Weekly2 = 2,
  Weekly3 = 3,
  Weekly4 = 4,
  Single = 0,
}

export interface IScheduleModalBatchAddData {
  type: ScheduleInterval;
  startDate: Date | null;
  endDate: Date | null | undefined;
  days: Array<number>;
  projectId: string;
  networkId: string;
  locations: Array<string>;
  taskId: string;
  userId: string;
  hours: IHourData[] | undefined;
  users?: Array<IUserSimple>;
  frequency?: number;
  avoidDuplicates?: boolean;
}

export const intervals = {
  [ScheduleInterval.Weekly]: "Raz na tydzień",
  [ScheduleInterval.Weekly2]: "Raz na 2 tygodnie",
  [ScheduleInterval.Weekly3]: "Raz na 3 tygodnie",
  [ScheduleInterval.Weekly4]: "Raz na cztery tygodnie",
};

const normalizeDay = (day) => {
  if (!day) {
    return;
  }
  if (day?.type == HOURS_EXPLICITLY) {
    day.start = null;
    day.end = null;
    day.hours = day.hours ? Number(day.hours) : null;
  } else if (day?.type == TIME_STRING) {
    day.hours = null;
  }
  return day;
};

export const validateHoursData = (
  setErrors: SetState<any>,
  scheduleType: ScheduleInterval,
  hoursDataSingle: IHourData | null,
  hoursData: Array<IHourData> | null
) => {
  setErrors(null);
  let days: Array<IHourData> = [];
  if (scheduleType == ScheduleInterval.Single) {
    const day = hoursDataSingle;
    if (
      day?.start &&
      day?.end &&
      dayjs(day.start, "HH:mm").isAfter(dayjs(day.end, "HH:mm"))
    ) {
      setErrors((e) => ({
        ...e,
        hours: [
          {
            day: undefined,
            start: "Godz. rozp. nie może być większe niż zak.",
            end: "",
          },
        ],
      }));
    }
    if (day?.type == TIME_STRING && !day?.end !== !day?.start) {
      setErrors((e) => ({
        ...e,
        hours: [
          {
            day: undefined,
            start: !day?.start ? "To pole jest wymagane" : "",
            end: !day?.end ? "To pole jest wymagane" : "",
          },
        ],
      }));
    }
  } else {
    days = hoursData ?? [];
    days.forEach((d) => {
      if (
        d?.start &&
        d?.end &&
        dayjs(d.start, "HH:mm").isAfter(dayjs(d.end, "HH:mm"))
      ) {
        setErrors((e) => ({
          ...e,
          hours: [
            {
              day: undefined,
              start: "Godz. rozp. nie może być większa niż godz. zak.",
              end: "",
            },
            ...days.map((day: IHourData) => ({
              day: day.day,
              start: "Godz. rozp. nie może być większa niż godz. zak.",
              end: "",
            })),
          ],
        }));
      }
      if (d.type == TIME_STRING && !d.start !== !d.end) {
        setErrors((e) => ({
          ...e,
          hours: [
            {
              day: undefined,
              start: !d.start ? "To pole jest wymagane" : "",
              end: !d.end ? "To pole jest wymagane" : "",
            },
            ...days.map((day: IHourData) => ({
              day: day.day,
              start: !d.start ? "To pole jest wymagane" : "",
              end: !d.end ? "To pole jest wymagane" : "",
            })),
          ],
        }));
      }
    });
  }
};

export const parseHourData = (
  scheduleType: ScheduleInterval,
  hoursDataSingle: IHourData | null,
  hoursData: Array<IHourData> | null,
  modalDataDys: number[] | null
) => {
  let days: Array<IHourData> = [];
  if (scheduleType == ScheduleInterval.Single) {
    const day = normalizeDay(hoursDataSingle);
    day.day = 0;
    if (day) {
      days = [day];
    }
  } else {
    days = hoursData ?? [];
    days = sortBy(days, "day").filter(
      (d) => modalDataDys?.includes(d.day ?? -1)
    );
  }

  days = days
    .map((d) => normalizeDay(d))
    .filter((fd) => {
      return fd?.type == TIME_STRING ? !!fd.start && !!fd.end : !!fd.hours;
    });

  return days;
};
export const parseData = (
  scheduleType: ScheduleInterval,
  hoursDataSingle: IHourData | null,
  hoursData: Array<IHourData> | null,
  modalData: IScheduleModalData | null,
  currentDate?: Date
) => {
  const days = parseHourData(
    scheduleType,
    hoursDataSingle,
    hoursData,
    modalData?.days ?? []
  );

  return {
    ...modalData,
    hours: days,
    fromDate: modalData?.fromDate
      ? dayjs(modalData.fromDate).format("YYYY-MM-DD")
      : null,
    startDate: dayjs(currentDate).format("YYYY-MM-DD"),
    endDate: modalData?.endDate
      ? dayjs(modalData.endDate).format("YYYY-MM-DD")
      : null,
  };
};

export const hourDay = (
  dayNumber: number,
  hoursData: IHourData[] | null
): IHourData | undefined => {
  return hoursData?.find((d) => d.day == dayNumber);
};
export const getTimeToDate = (
  dayKey: number,
  field: string,
  hoursData: IHourData[] | null
) => {
  const day = hourDay(dayKey, hoursData);
  const value = day?.[field];
  return value ? dayjs(value, "HH:mm").toDate() : undefined;
};
export const isNumberRange = (
  dayNumber: number,
  hoursData: IHourData[] | null
) => {
  const day = hourDay(dayNumber, hoursData);
  return day?.type != TIME_STRING;
};
