import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  IconButton,
  Loader,
  Message,
  Panel,
  Placeholder,
  Popover,
  Radio,
  RadioGroup,
  Row,
  Table,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { Helmet } from "react-helmet-async";
import SeparatorEmpty from "../../global/atoms/separators/SeparatorEmpty";
import { FCC, IDeleteTimeTable, IVisitStatus } from "../../utils/models";
import PlusIcon from "@rsuite/icons/Plus";
import SchedulesConnection, {
  ScheduleListStatus,
} from "../../utils/connections/schedules";
import {
  getProjectIdFromUrl,
  handleToast,
  NotCancelErrorPromise,
  parseTimeForBackend,
} from "../../utils/helpers";
import ScheduleTile from "./Elements/ScheduleTile";
import ScheduleModal from "./Elements/ScheduleModal";
import ScheduleRemoveModal from "./Elements/ScheduleRemoveModal";
import { WEEK_DAYS_ISO } from "../../utils/dictionaries";
import SeparatorLine from "../../global/atoms/separators/SeparatorLine";
import { confirmModalCallback } from "../../redux/store";
import { TypeVisitStatus } from "../../utils/types";
import ModalUserReplacementList from "./Elements/ModalUserReplacementList";
import { useParams } from "react-router-dom";
import _, { isEmpty } from "lodash";
import style from "./schedule.module.scss";
import useForm from "./Hooks/useForm";
import useRequestData, { IScheduleRequestData } from "./Hooks/useRequestData";
import useScheduleData from "./Hooks/useScheduleData";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import {
  FormDefault,
  FormGroupMultiSelect,
  FormGroupSelect,
} from "utils/formHelper";
import ScheduleDatePicker, {
  getViewType,
  ViewMode,
} from "./ScheduleDatePicker";
import { addDays, differenceInCalendarDays, getISODay } from "date-fns";
import isoWeek from "dayjs/plugin/isoWeek";
import dayjs from "dayjs";
import Spinner from "global/atoms/Spinner/Spinner";
import NavOptionsWrapper from "../../utils/NavOptionsWrapper";
import ModalExportSchedule from "./Elements/ModalExportSchedule";
import ScheduleViewMonth from "./Elements/ScheduleViewMonth";

import FlexRows from "global/FlexRows";
import ScheduleMoveModal from "./Elements/ScheduleMoveModal";
import { RowDataType } from "rsuite/esm/Table";
import ScheduleModalBatchAdd from "./Elements/ScheduleModalBatchAdd";
import ActionsContainer from "../../global/atoms/ActionsContainer";
import VisitFrequencyLegend from "./Elements/VisitFrequencyLegend";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import RemindFillIcon from "@rsuite/icons/RemindFill";
import { THoursStrategy } from "./Elements/ScheduleModalElements/functions";

dayjs.extend(isoWeek);

const { Column, HeaderCell, Cell } = Table;

export interface IScheduleItemVisit {
  deadline: null | Date;
  projectId: string;
  visitId: string;
  status: IVisitStatus;
}

export interface IScheduleItemSchedule {
  username: string;
  userId: string;
  replacedUsername: null;
  timeTable: string;
  dayNumber: number;
  date: string;
  frequency: number;
  hours: number;
  hoursRange: string;
  visit: IScheduleItemVisit;
}

export interface IScheduleItem {
  id: string;
  projectId: string;
  projectName: string;
  locationId: string;
  locationName: string;
  networkId: string;
  networkName: string;
  taskName: string;
  taskId: string;
  days: Array<IScheduleItemSchedule>;
}

export type TModalType =
  | "create"
  | "view"
  | "modify"
  | "move"
  | "remove"
  | "batchAdd";

export interface IModalCellData {
  type: TModalType;
  timeTableId: string | null;
  rowId: string; // TimeTableVisitWeeklyPivotWeek id
  date: Date;
  hoursStrategy?: THoursStrategy;
}
export interface IModalBatchAddData {
  type: "batchAdd";
  date: Date;
}

export enum ScheduleInterval {
  Weekly = 1,
  Weekly2 = 2,
  Weekly3 = 3,
  Weekly4 = 4,
  Single = 0,
}

export enum ESource {
  schedule,
  manual,
}
/** @ViewType use `zDupy` */
export enum ViewType {
  view = "view",
  create = "create",
  routes = "routes",
}

const OPTION_REMOVE_SINGLE_DAY = 1;
const OPTION_REMOVE_ALL_DAYS = 2;
//const OPTION_REMOVE_DAY_OF_THE_WEEK_PERMANENTLY = 3;

const Schedule: FCC = () => {
  const { id } = useParams<{ id: string }>();
  const [modalCellData, setModalCellData] = useState<IModalCellData | null>(
    null
  );
  const [hoveredScheduleId, setHoveredScheduleId] = useState<string | null>(
    null
  );
  const [modalUserReplacementOpen, setModalUserReplacementOpen] =
    useState(false);
  const [modalScheduleExportOpen, setModalScheduleExportOpen] = useState(false);
  const [formInitialised, setFormInitialised] = useState(false);

  const [
    requestData,
    updateRequest,
    loadRequestPreset,
    presetRequestDataLoaded,
  ] = useRequestData();
  const [form, formLoad, formIsLoading] = useForm();
  const [scheduleData, loadData, dataLoading, fetchedAll] = useScheduleData();
  const [scrollLoadTriggered, setScrollLoadTriggered] = useState(false);
  const [tableScrollTop, setTableScrollTop] = useState(0);
  const [listStatuses, setListStatuses] = useState<ScheduleListStatus | null>(
    null
  );
  const [modalBatchAddOpen, setModalBatchAddOpen] = useState(false);
  const [modalBatchAddData, setModalBatchAddData] =
    useState<IModalBatchAddData>();
  const [removeOption, setRemoveOption] = useState<1 | 2 | 3>(2);
  const loadListStatuses = (_requestData: IScheduleRequestData) => {
    SchedulesConnection.listStatuses(_requestData).then((res) => {
      setListStatuses(res.data);
    }, NotCancelErrorPromise);
  };

  const isViewMonth =
    getViewType(requestData.dateFrom, requestData.dateTo) === ViewMode.month;
  const isDayView =
    getViewType(requestData.dateFrom, requestData.dateTo) === ViewMode.day;
  useEffect(() => {
    setRemoveOption(
      isDayView ? OPTION_REMOVE_SINGLE_DAY : OPTION_REMOVE_ALL_DAYS
    );
  }, [requestData.dateFrom, requestData.dateTo]);

  const postRU = (pageReset: boolean, _requestData: IScheduleRequestData) => {
    if (pageReset) {
      // table scroll top
      setTableScrollTop((s) => ++s);
      // load status counters
    }
    loadListStatuses(_requestData);
  };

  useEffect(() => {
    loadRequestPreset();
  }, []);

  useEffect(() => {
    if (presetRequestDataLoaded) {
      loadListStatuses(requestData);
      if (!isViewMonth || (isViewMonth && requestData.userId)) {
        loadData(requestData);
      }

      if (!formInitialised) {
        formLoad(requestData, () => setFormInitialised(true));
      }
    }
  }, [presetRequestDataLoaded]);

  const loadDataResetPage = () => {
    loadData(
      updateRequest(
        {
          requestPaginate: {
            ...requestData.requestPaginate,
            page: 1,
          },
        },
        postRU
      )
    );
  };

  const onModalSubmit = () => {
    loadDataResetPage();
    setTimeout(() => setModalCellData(null));
  };

  const onScheduleRemove = (
    timeTableId: string,
    date: Date,
    removeType: 1 | 2
  ) => {
    setModalCellData(null);

    const deleteData: IDeleteTimeTable = {
      id: timeTableId,
      date: parseTimeForBackend(date.getTime()),
      option: removeType,
    };

    handleToast(SchedulesConnection.remove(deleteData)).then(loadDataResetPage);
  };

  const recurFindDateFromWeekDay = (date: Date, weekDayNum: number): Date => {
    let tmp = dayjs(date);
    if (tmp.isoWeekday() === weekDayNum) return tmp.toDate();
    tmp = tmp.add(1, "day");
    return recurFindDateFromWeekDay(tmp.toDate(), weekDayNum);
  };

  const ScheduleTileOnClick = (
    row: IScheduleItem,
    schedule: IScheduleItemSchedule,
    day: number
  ) => {
    setModalCellData({
      type: "view",
      timeTableId: schedule.timeTable,
      rowId: row.id,
      date: recurFindDateFromWeekDay(requestData.dateFrom, day),
    });
  };

  const getDayStatusBadges = (date: Date) => {
    if (!listStatuses || !listStatuses[dayjs(date).format("YYYY-MM-DD")])
      return <div>&nbsp;</div>;
    const statuses = listStatuses[dayjs(date).format("YYYY-MM-DD")];
    return statuses.map((v) => {
      const badge = (
        <Badge
          style={{ background: v.status.color, marginRight: "2px" }}
          key={`status-date-${v.status.status}`}
          content={v.count > 99 ? "99+" : v.count}
        />
      );
      if (v.count > 99) {
        return (
          <Whisper
            key={`status-date-whisper-${v.status.status}`}
            speaker={<Tooltip>{v.count}</Tooltip>}>
            {badge}
          </Whisper>
        );
      }
      return badge;
    });
  };

  const ScheduleTableCellRenderer = (
    row: IScheduleItem,
    day: number
  ): JSX.Element => {
    const cellDate = recurFindDateFromWeekDay(requestData.dateFrom, day);
    const schedules = row.days?.filter((s) =>
      dayjs(s.date).isSame(dayjs(cellDate), "day")
    );

    if (schedules && schedules.length > 0) {
      return (
        <>
          {schedules.map((schedule, key) => (
            <ScheduleTile
              userName={schedule.username}
              key={`st-${day}-${key}`}
              dayInMonth={dayjs(cellDate).date()}
              date={dayjs(cellDate)}
              projectId={row.projectId}
              userReplacementName={schedule.replacedUsername ?? undefined}
              scheduleId={schedule.timeTable}
              recurringSettingsType={schedule.frequency}
              //  activities={row.activityGroups}
              activities={[]} // todo: --
              hoveredScheduleId={hoveredScheduleId}
              setHoveredScheduleId={setHoveredScheduleId}
              visit={schedule.visit}
              hours={schedule.hours}
              hoursRange={schedule.hoursRange}
              onAddNew={() =>
                setModalCellData({
                  type: "create",
                  timeTableId: null,
                  rowId: row.id,
                  date: cellDate,
                  hoursStrategy: scheduleData?.hoursStrategy,
                })
              }
              viewType={requestData.viewType}
              onMoveSchedule={() =>
                setModalCellData({
                  type: "move",
                  rowId: row.id,
                  timeTableId: schedule.timeTable,
                  date: cellDate,
                  hoursStrategy: scheduleData?.hoursStrategy,
                })
              }
              onModifySchedule={() =>
                setModalCellData({
                  type: "modify",
                  rowId: row.id,
                  timeTableId: schedule.timeTable,
                  date: cellDate,
                  hoursStrategy: scheduleData?.hoursStrategy,
                })
              }
              onDeleteSchedule={() =>
                setModalCellData({
                  type: "remove",
                  rowId: row.id,
                  timeTableId: schedule.timeTable,
                  date: cellDate,
                })
              }
              onClick={ScheduleTileOnClick.bind(null, row, schedule, day)}
              disabledElements={scheduleData?.disabledElements ?? []}
            />
          ))}
        </>
      );
    }

    if (dayjs(cellDate).isBefore(dayjs().add(1, "day"), "day")) {
      return <></>;
    }

    if (requestData.viewType === ViewType.create) {
      return (
        <IconButton
          size={"lg"}
          style={{
            position: "absolute",
            top: "10px",
            bottom: "10px",
            left: "10px",
            width: "130px",
            lineHeight: "10px",
          }}
          appearance={"subtle"}
          icon={<PlusIcon style={{ color: "#FFAF38", fontSize: "0.85em" }} />}
          onClick={() =>
            setModalCellData({
              type: "create",
              rowId: row.id,
              timeTableId: null,
              date: cellDate,
              hoursStrategy: scheduleData?.hoursStrategy,
            })
          }
        />
      );
    }

    return <></>;
  };

  const ScheduleTableEmptyRenderer = (): ReactNode => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}>
        <div style={{ color: "#cbcbcb" }}>
          <span>{"Brak danych"}</span>
        </div>
      </div>
    );
  };

  const handleSortColumn = (
    dataKey: string,
    sortType: "asc" | "desc" | undefined
  ) => {
    loadData(
      updateRequest(
        {
          requestOrder: {
            order: sortType ?? "",
            field: sortType ? dataKey ?? "" : "",
          },
          requestPaginate: {
            ...requestData.requestPaginate,
            page: 1,
          },
        },
        postRU
      )
    );
  };

  const handleTableRowHeight = (rowData: IScheduleItem): number => {
    let multiplier = 1;
    if (rowData && rowData.days) {
      const sameDateSchedule: { [dateString: string]: number } = {};
      rowData.days.forEach((s) => {
        const d = s.date;
        if (!sameDateSchedule[d]) sameDateSchedule[d] = 0;
        sameDateSchedule[d]++;
      });

      const arr = Object.values(sameDateSchedule);
      if (arr.length > 0) multiplier = Math.max(...arr);
    }
    return 70 * multiplier;
  };

  const handleClear = (
    filter: any,
    rowData: IScheduleItem,
    startDay: string
  ) => {
    const _dataRequest = {
      option: removeOption,
      startDate: dayjs(startDay).isBefore(dayjs(), "day")
        ? dayjs().add(1, "day").toDate()
        : startDay,
      filter: {
        ...filter,
        userId: requestData.userId,
        networkId: requestData.networkId,
      },
    };
    confirmModalCallback(
      <>
        {rowInfo(rowData)}
        <span>
          Usuwasz zadanie dla wybranej lokalizacji w dniu{" "}
          {dayjs(_dataRequest.startDate).format("YYYY-MM-DD (dddd)")}
          {removeOption == OPTION_REMOVE_ALL_DAYS
            ? " i późniejsze wynikające z ustawień reguły"
            : ""}
          .
        </span>
      </>,
      () => {
        handleToast(SchedulesConnection.clear(_dataRequest)).then(
          loadDataResetPage
        );
      }
    );
  };

  const onScrollLoadMore = () => {
    if (dataLoading || scrollLoadTriggered || fetchedAll) return;

    setScrollLoadTriggered(true);
    loadData(
      updateRequest(
        {
          requestPaginate: {
            ...requestData.requestPaginate,
            page: requestData.requestPaginate.page + 1,
          },
        },
        postRU
      ),
      true,
      () => {
        setScrollLoadTriggered(false);
      }
    );
  };

  const statusOnClick = (status: TypeVisitStatus | null): void => {
    const selectedStatuses: Array<TypeVisitStatus | null> =
      requestData.statuses.filter((s) => s !== status);
    if (requestData.statuses.find((s) => s === status) === undefined)
      selectedStatuses.push(status);
    loadData(updateRequest({ statuses: selectedStatuses }, postRU));
  };

  const sourceChange = (_source: ESource) => {
    let tmp: ESource[];
    if (requestData.source.includes(_source))
      tmp = requestData.source.filter((s) => s !== _source);
    else tmp = [...requestData.source, _source];
    if (tmp.length === 0)
      tmp.push(_source === ESource.manual ? ESource.schedule : ESource.manual); // = [ESource.manual, ESource.schedule]
    loadData(updateRequest({ source: tmp }, postRU));
  };

  const getWeekDaysFromRange = (): Array<{
    dayName: string;
    isoWeekNum: number;
    stringDate: string;
    date: Date;
  }> => {
    const result: Array<{
      dayName: string;
      isoWeekNum: number;
      stringDate: string;
      date: Date;
    }> = [];

    if (requestData.dateFrom && requestData.dateTo) {
      const rangeIter =
        differenceInCalendarDays(requestData.dateTo, requestData.dateFrom) + 1;
      for (let i = 0; i < rangeIter; i++) {
        const date = addDays(requestData.dateFrom, i);
        const num = getISODay(date);

        result.push({
          dayName: WEEK_DAYS_ISO[num],
          stringDate: dayjs(date).format("DD-MM-YYYY"),
          date: date,
          isoWeekNum: num,
        });
      }
    }

    return result;
  };

  const filterSoftReset = () => {
    const rd = updateRequest(
      {
        requestOrder: {
          field: undefined,
          order: undefined,
        },
        requestPaginate: {
          page: 1,
          limit: requestData.requestPaginate.limit,
        },
        locations: null,
        customerId: null,
        userId: null,
        networkId: null,
        projects: !getProjectIdFromUrl() ? null : requestData.projects,
        taskId: null,
        statuses: [],
      },
      postRU
    );

    setTableScrollTop((s) => ++s);
    loadListStatuses(rd);
    loadData(rd);
    // wymagane ze względu na samoograniczanie listy projektów tylko do wybranych
    if (!id) {
      formLoad(rd);
    }
  };

  const panelShadowCss: CSSProperties = {
    boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
    background: "rgb(246, 246, 246)",
  };

  const isScheduleBatchCreationAllowed = (): boolean => {
    return (
      requestData.viewType === ViewType.create &&
      (!scheduleData?.disabledElements?.includes(
        "SYSTEM_GLOBAL-TIMETABLE_PERMISSION_BATCH_ADD"
      ) ??
        false)
    );
  };
  const hasDeletePermission = (): boolean => {
    return (
      requestData.viewType === ViewType.create &&
      (!scheduleData?.disabledElements?.includes(
        "SYSTEM_GLOBAL-TIMETABLE_PERMISSION_DELETE"
      ) ??
        false)
    );
  };

  const parseFormStatuses = (): IVisitStatus[] => {
    return form?.statuses ?? [];
  };

  const rowInfo = (rowData) => {
    return (
      <div>
        <strong>{rowData.projectName}</strong>
        <br />
        <strong style={{ fontSize: "1.5em" }}>{rowData.taskName}</strong>
        <br />
        <small style={{ color: "#d3d3d3" }}>{rowData.locationName}</small>
        <br />
        <small style={{ color: "#d3d3d3" }}>
          {rowData.locationAddressString}
        </small>
        <SeparatorLine />
      </div>
    );
  };

  if (!presetRequestDataLoaded) return <Spinner />;
  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper>
          <Dropdown.Item onClick={() => setModalUserReplacementOpen(true)}>
            Lista zastępstw
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setModalScheduleExportOpen(true)}>
            Eksport harmonogramu - miesięczny
          </Dropdown.Item>
        </NavOptionsWrapper>
      </HeaderButtons>

      <FlexRows>
        <FormDefault
          onSubmit={() => {
            console.log("submit");
          }}
          onChange={(data) => {
            loadData(updateRequest(data, postRU), false);
          }}
          state={requestData}>
          <div style={{ position: "relative" }}>
            {/* STATIC PART OF FILTER */}
            <Row>
              <Col xs={24}>
                <div
                  style={{ display: "flex", columnGap: "13px", zIndex: 999 }}>
                  <ScheduleDatePicker
                    dateFrom={requestData.dateFrom}
                    dateTo={requestData.dateTo}
                    maxDateTo={form?.maxDateTo}
                    onChange={(dateFrom, dateTo) => {
                      const rd = updateRequest(
                        {
                          dateFrom: dateFrom,
                          dateTo: dateTo,
                          requestPaginate: {
                            ...requestData.requestPaginate,
                            page: 1,
                          },
                        },
                        postRU
                      );

                      setTableScrollTop(Date.now());
                      const _isViewMonth =
                        getViewType(dateFrom, dateTo) === ViewMode.month;
                      if (!_isViewMonth || (_isViewMonth && rd.userId)) {
                        loadData(rd, false);
                      }
                      formLoad(rd);
                    }}
                  />

                  {!isViewMonth && (
                    <>
                      <div>
                        <div style={{ color: "#999", marginRight: "10px" }}>
                          Tryb:
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <ButtonToolbar>
                            <ButtonGroup size={"xs"}>
                              <Button
                                disabled={
                                  !requestData.availableViews.includes(
                                    ViewType.view
                                  )
                                }
                                onClick={() =>
                                  loadData(
                                    updateRequest(
                                      { viewType: ViewType.view },
                                      postRU
                                    )
                                  )
                                }
                                appearance={
                                  requestData.viewType === ViewType.view
                                    ? "primary"
                                    : "default"
                                }>
                                Podgląd
                              </Button>
                              <Button
                                disabled={
                                  !requestData.availableViews.includes(
                                    ViewType.create
                                  )
                                }
                                onClick={() =>
                                  loadData(
                                    updateRequest(
                                      { viewType: ViewType.create },
                                      postRU
                                    )
                                  )
                                }
                                appearance={
                                  requestData.viewType === ViewType.create
                                    ? "primary"
                                    : "default"
                                }>
                                Kreator
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div>
                        <div style={{ color: "#999", marginRight: "10px" }}>
                          Źródło:
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <ButtonToolbar>
                            <ButtonGroup size={"xs"}>
                              <Button
                                onClick={() => sourceChange(ESource.schedule)}
                                appearance={
                                  requestData.source.includes(ESource.schedule)
                                    ? "primary"
                                    : "default"
                                }>
                                Harmonogram
                              </Button>
                              <Button
                                onClick={() => sourceChange(ESource.manual)}
                                appearance={
                                  requestData.source.includes(ESource.manual)
                                    ? "primary"
                                    : "default"
                                }>
                                Ręcznie
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <SeparatorEmpty size={1} />

          {/* DYNAMIC PART OF FILTER */}
          {!isViewMonth ? (
            <Panel
              collapsible
              header={"Filtry"}
              defaultExpanded
              style={{
                ...panelShadowCss,
                padding: "10px",
                position: "relative",
              }}>
              {formIsLoading === null && (
                <div>
                  <Message showIcon type={"error"}>
                    Nie można załadować filtrów
                  </Message>
                </div>
              )}
              {formIsLoading === true ? (
                <div>
                  <Placeholder.Paragraph rows={4} />
                  <Loader
                    backdrop
                    content="Trwa ładowanie filtrów..."
                    vertical
                  />
                </div>
              ) : (
                formIsLoading === false && (
                  <>
                    <Row>
                      {!id && (
                        <Col xs={4}>
                          <FormGroupMultiSelect
                            size={"sm"}
                            label={"Projekty"}
                            fieldName={"projects"}
                            labelKey={"name"}
                            valueKey={"id"}
                            options={form?.projects ?? []}
                            sortNameAsc={true}
                            onChange={(value) => {
                              if (isEmpty(value))
                                formLoad({ ...requestData, projects: null });
                            }}
                          />
                        </Col>
                      )}
                      <Col xs={5}>
                        <FormGroupMultiSelect
                          size={"sm"}
                          label={"Lokalizacja"}
                          fieldName={"locations"}
                          labelKey={"label"}
                          valueKey={"id"}
                          // for filtering only locations with addresses
                          options={
                            form?.locations?.map((l) => ({
                              ...l,
                              label: `${l.name} ${l.locationAddressString}`,
                            })) ?? []
                          }
                          sortNameAsc={true}
                          renderMenuItem={(label, node) => {
                            return (
                              <div
                                style={{
                                  position: "relative",
                                  padding: "0px",
                                }}>
                                <div>{node.name}</div>
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "-15px",
                                    fontSize: "10px",
                                    fontStyle: "italic",
                                    color: "#999999",
                                  }}>
                                  {node.locationAddressString}
                                </div>
                              </div>
                            );
                          }}
                        />
                      </Col>

                      <Col xs={5}>
                        <FormGroupSelect
                          size={"sm"}
                          label={"Uzytkownik"}
                          fieldName={"userId"}
                          labelKey={"name"}
                          valueKey={"id"}
                          options={form?.users ?? []}
                          sortNameAsc={true}
                        />
                      </Col>
                      <Col xs={5}>
                        <FormGroupSelect
                          size={"sm"}
                          label={"Zadanie"}
                          fieldName={"taskId"}
                          labelKey={"name"}
                          valueKey={"id"}
                          options={form?.tasks ?? []}
                          sortNameAsc={true}
                        />
                      </Col>
                      <Col xs={5}>
                        <FormGroupSelect
                          size={"sm"}
                          label={"Sieć"}
                          fieldName={"networkId"}
                          labelKey={"name"}
                          valueKey={"id"}
                          options={form?.networks ?? []}
                          sortNameAsc={true}
                        />
                      </Col>
                    </Row>

                    <SeparatorEmpty size={1} />

                    <Row>
                      <Col xs={20}>
                        {/* STATUS FILTER */}
                        <div className={style.statusFilterRow}>
                          <div>
                            <div
                              style={{ color: "#999", marginBottom: "10px" }}>
                              {"Status wizyty"}&nbsp;
                              <Toggle
                                checked={!_.isEmpty(requestData.statuses)}
                                disabled={
                                  (parseFormStatuses().length ?? 0) === 0
                                }
                                size="sm"
                                onChange={(value) => {
                                  loadData(
                                    updateRequest(
                                      {
                                        statuses: value
                                          ? form?.statuses.map(
                                              (s) => s.status
                                            ) ?? []
                                          : [],
                                      },
                                      postRU
                                    ),
                                    false
                                  );
                                }}
                              />
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                              {form &&
                                parseFormStatuses().map((status) => (
                                  <Button
                                    key={`btn-status-${status.id}`}
                                    appearance={
                                      requestData.statuses.includes(
                                        status.status
                                      ) || _.isEmpty(requestData.statuses)
                                        ? "primary"
                                        : "ghost"
                                    }
                                    size="xs"
                                    className={`${
                                      requestData.statuses.includes(
                                        status.status
                                      ) || _.isEmpty(requestData.statuses)
                                        ? style.statusButtonActive
                                        : style.statusButton
                                    }`}
                                    onClick={statusOnClick.bind(
                                      null,
                                      status.status
                                    )}
                                    style={{
                                      color: status.color,
                                      borderColor: status.color,
                                      backgroundColor: status.color,
                                      opacity: _.isEmpty(requestData.statuses)
                                        ? 0.5
                                        : 1,
                                    }}>
                                    {status.name}
                                  </Button>
                                ))}
                            </div>
                            {parseFormStatuses().length === 0 && <>-</>}
                          </div>
                        </div>
                      </Col>
                      <Col xs={4} style={{ textAlign: "right" }}>
                        <div style={{ color: "#999", marginBottom: "10px" }}>
                          &nbsp;
                        </div>
                        <Button
                          onClick={filterSoftReset}
                          size={"xs"}
                          appearance={"ghost"}>
                          Resetuj
                        </Button>
                      </Col>
                    </Row>
                  </>
                )
              )}
            </Panel>
          ) : (
            <>
              <FormGroupSelect
                size={"sm"}
                label={"*Użytkownik"}
                fieldName={"userId"}
                labelKey={"name"}
                valueKey={"id"}
                options={form?.users ?? []}
                sortNameAsc={true}
              />
            </>
          )}
        </FormDefault>
        {isViewMonth ? (
          <ScheduleViewMonth
            dateFrom={requestData.dateFrom}
            dateTo={requestData.dateTo}
            data={scheduleData?.data ?? []}
          />
        ) : (
          <>
            <div style={{ flexGrow: 1 }}>
              <Table
                data={scheduleData?.data ?? []}
                bordered
                cellBordered
                virtualized
                fillHeight
                shouldUpdateScroll={false}
                headerHeight={65}
                scrolltop={tableScrollTop}
                onScroll={(x, y) => {
                  if (!scheduleData?.data.length || dataLoading) {
                    return;
                  }
                  const wrapper = document
                    .getElementsByClassName("rs-table-body-row-wrapper")
                    .item(0);
                  const bodyHeight = _.sum(
                    scheduleData?.data.map(handleTableRowHeight)
                  );

                  if (wrapper?.clientHeight && !scrollLoadTriggered) {
                    const absScroll = Math.abs(
                      y - (bodyHeight - (wrapper?.clientHeight ?? 0))
                    );
                    if (absScroll < 100) onScrollLoadMore();
                  }
                }}
                // @ts-ignore
                rowHeight={handleTableRowHeight}
                sortColumn={
                  !requestData.requestOrder.field
                    ? undefined
                    : requestData.requestOrder.field
                }
                // @ts-ignore
                sortType={
                  !requestData.requestOrder.order
                    ? undefined
                    : requestData.requestOrder.order
                }
                loading={dataLoading}
                onSortColumn={handleSortColumn}
                renderEmpty={ScheduleTableEmptyRenderer}>
                <Column width={300} sortable>
                  <HeaderCell style={{ fontSize: "0.8em" }}>{`Zadanie${
                    id === undefined ? "/Projekt" : ""
                  }/Lokalizacja`}</HeaderCell>
                  <Cell style={{ fontSize: "0.8em" }} dataKey={"locationName"}>
                    {(rowData: RowDataType<IScheduleItem>) => {
                      const startDate = dayjs(requestData.dateFrom).isAfter(
                        dayjs()
                      )
                        ? dayjs(requestData.dateFrom).format("YYYY-MM-DD")
                        : dayjs().add(1, "day").format("YYYY-MM-DD");
                      return (
                        <>
                          {/* <small>{++index}.</small> */}
                          <Whisper
                            trigger={"click"}
                            disabled={!hasDeletePermission()}
                            placement={"top"}
                            speaker={
                              <Popover>
                                <div
                                  style={{ width: "400px", padding: "15px" }}>
                                  {rowInfo(rowData)}
                                  <SeparatorEmpty size={1} />
                                  <div style={{ textAlign: "left" }}>
                                    <RadioGroup
                                      name="option-remove"
                                      defaultValue={removeOption}
                                      onChange={(value) => {
                                        setRemoveOption(
                                          Number(value) as 1 | 2 | 3
                                        );
                                      }}>
                                      <Radio value={OPTION_REMOVE_SINGLE_DAY}>
                                        Usuń generowanie wizyty dnia {startDate}
                                      </Radio>
                                      {!isDayView && (
                                        <Radio value={OPTION_REMOVE_ALL_DAYS}>
                                          Usuń generowanie wizyty dnia{" "}
                                          {startDate} oraz późniejsze dni
                                          wynikające z... ustawień reguły
                                        </Radio>
                                      )}
                                    </RadioGroup>
                                    {dayjs(requestData.dateFrom).isBefore(
                                      dayjs()
                                    ) && (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}>
                                        <RemindFillIcon
                                          style={{
                                            fontSize: "24px",
                                            color: "#E09616",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>
                                          Zostaną wyczyszczone tylko wpisy
                                          przyszłych harmonogramów
                                        </span>
                                      </div>
                                    )}
                                    <ActionsContainer>
                                      <Button
                                        onClick={() =>
                                          handleClear(
                                            {
                                              projectId: rowData.projectId,
                                              taskId: rowData.taskId,
                                              locationId: rowData.locationId,
                                            },
                                            rowData as IScheduleItem,
                                            dayjs(requestData.dateFrom).format(
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        }
                                        size={"xs"}
                                        appearance={"primary"}
                                        color={"red"}>
                                        Wyczyść zadanie
                                      </Button>
                                    </ActionsContainer>
                                  </div>
                                </div>
                              </Popover>
                            }>
                            <div
                              style={{ cursor: "pointer", lineHeight: "15px" }}>
                              <div>{rowData.taskName}</div>
                              {id === undefined && (
                                <div>
                                  <small>{rowData.projectName}</small>
                                </div>
                              )}
                              <small style={{ color: "#a9a9a9" }}>
                                {rowData.locationName}
                              </small>
                              <br />
                              <small style={{ color: "#a9a9a9" }}>
                                {rowData.locationAddressString}
                              </small>
                              {hasDeletePermission() && (
                                <CloseOutlineIcon
                                  style={{
                                    color: "#FFAF38FF",
                                    position: "absolute",
                                    bottom: "15px",
                                    right: "15px",
                                  }}
                                />
                              )}
                            </div>
                          </Whisper>
                        </>
                      );
                    }}
                  </Cell>
                </Column>

                {getWeekDaysFromRange().map((d) => (
                  <Column
                    key={`tt-weekday-${d.isoWeekNum}`}
                    width={150}
                    align={"left"}>
                    <HeaderCell
                      style={{ lineHeight: "11px", cursor: "pointer" }}>
                      <>
                        <div
                          onClick={() =>
                            loadData(
                              updateRequest(
                                { dateFrom: d.date, dateTo: d.date },
                                postRU
                              )
                            )
                          }>
                          {!dataLoading && (
                            <>
                              <div style={{ marginBottom: "4px" }}>
                                {getDayStatusBadges(d.date)}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <div>
                                  <div>{d.dayName}</div>
                                  <small
                                    style={{ color: "#bbb", fontWeight: 100 }}>
                                    {d.stringDate}
                                  </small>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {id &&
                          isScheduleBatchCreationAllowed() &&
                          dayjs(d.date).isAfter(dayjs(), "day") && (
                            <>
                              <IconButton
                                size={"lg"}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  // width: "10px",
                                  // lineHeight: "10px",
                                }}
                                appearance={"subtle"}
                                icon={
                                  <PlusIcon
                                    style={{
                                      color: "#FFAF38",
                                      fontSize: "0.85em",
                                    }}
                                  />
                                }
                                onClick={() => {
                                  setModalBatchAddOpen(true);
                                  setModalBatchAddData({
                                    type: "batchAdd",
                                    date: d.date,
                                  });
                                }}
                              />
                            </>
                          )}
                      </>
                    </HeaderCell>
                    <Cell style={{ padding: 0 }}>
                      {(rowData: RowDataType<IScheduleItem>) =>
                        ScheduleTableCellRenderer(
                          rowData as IScheduleItem,
                          d.isoWeekNum
                        )
                      }
                    </Cell>
                  </Column>
                ))}

                <Column align={"right"} flexGrow={1} width={90}>
                  <HeaderCell style={{ fontSize: "0.8em" }}>Godzin</HeaderCell>
                  <Cell>
                    {(rowData: RowDataType<IScheduleItem>) =>
                      parseFloat(
                        (rowData.days?.map((s) => s.hours) ?? [])
                          .reduce((ps, v) => (ps ?? 0) + (v ?? 0), 0)
                          ?.toFixed(1)
                      )
                    }
                  </Cell>
                </Column>
              </Table>
            </div>
            <SeparatorEmpty size={2.5} />
            <VisitFrequencyLegend />
          </>
        )}
      </FlexRows>
      {modalCellData && (
        <ScheduleModal
          modalCellData={modalCellData}
          requestData={requestData}
          onModalSubmit={onModalSubmit}
          onModalClose={() => {
            if (modalCellData?.type !== "remove") setModalCellData(null);
          }}
          onScheduleRemove={() => {
            setModalCellData((s) =>
              s
                ? {
                    ...s,
                    type: "remove",
                  }
                : null
            );
          }}
        />
      )}
      {modalBatchAddOpen && (
        <ScheduleModalBatchAdd
          open={modalBatchAddOpen}
          hoursStrategy={scheduleData?.hoursStrategy}
          setOpen={setModalBatchAddOpen}
          modalConfig={modalBatchAddData}
          requestData={requestData}
          onModalSubmit={loadDataResetPage}
          form={form}
        />
      )}
      <ScheduleMoveModal
        modalData={modalCellData}
        onModalClose={() => {
          setModalCellData(null);
        }}
        reload={loadDataResetPage}
      />

      <ScheduleRemoveModal
        modalData={modalCellData}
        onModalClose={() => {
          setModalCellData(null);
        }}
        onScheduleRemove={onScheduleRemove}
      />

      <ModalUserReplacementList
        open={modalUserReplacementOpen}
        onModalClose={() => setModalUserReplacementOpen(false)}
        requestData={requestData}
      />
      <ModalExportSchedule
        open={modalScheduleExportOpen}
        setOpen={setModalScheduleExportOpen}
        data={requestData}
      />

      <Helmet>
        <style>{`
        .ui-worktime-quoter {
          display: block;
          float: left;
          width: 16px;
          height: 16px;
        }

        .ui-worktime-desc {
          float: left;
          margin-left: 10px;
          margin-right: 10px;
        }

        .ui-worktime-oneweek {
          background-color: #f5f2ad;
        }
        .ui-worktime-twomonth {
          background-color: #daec9f;
        }
        .ui-worktime-threeweeks {
          background-color: #cae8f5;
        }
        .ui-worktime-onemonth {
          background-color: #f9ddc6;
        }
        .ui-worktime-once {
          background-color: #e6e6e6;
        }
        .ui-is-not-reporting {
          border: 5px solid #FFEF32;
        }


        .schedule-tile {
          top: -10px
          left: -10px;
          opacity: 0.5;
          position:relative;
          width: 100%;
          height: 70px;
          text-align: center;
          font-size: .8em;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px #eee solid;
        }

        .schedule-tile > div {
          white-space: break-space;
          width: 100%;
        }

        .schedule-tile.active,
        .schedule-tile:hover {
          opacity: 1;
          cursor: pointer;
          font-weight: bold;
        }


        .noselect {
          -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
             -khtml-user-select: none; /* Konqueror HTML */
               -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
        }

        .rs-form-control-label {
          color: #999;
        }

        .rs-table-body-row-wrapper {
          height: 100% !important;
        }
        .rs-panel-header{
          display:flex;
          justify-content:space-between;
          align-items:center;
          padding-top:5px;
          padding-bottom:5px;
        }
        .rs-panel-collapsible > .rs-panel-header > .rs-icon {
          top:10px!important;
        }

      `}</style>
      </Helmet>
    </>
  );
};

export default Schedule;
