import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import _ from "lodash";
import React from "react";
import SortableItem from "../SortableItem";
import { FCC } from "utils/models";

interface ISortableList {
  onSortEnd: (sortedData: Array<any>) => void;
  data: Array<any>;
  idKeyName: string;
  mapFunction: (item: any, itemKey: number) => JSX.Element;
  dragHandleActivator?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  floating?: boolean;
  overlay?: boolean;
}

const SortableList: FCC<ISortableList> = (props) => {
  const ids = props.data.map((d) => d[props.idKeyName]);
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const onSortEnd = (event: DragEndEvent) => {
    const active = event.active.id;
    const over = event.over?.id ?? 0;

    if (active !== over && props.data !== null) {
      const oldIndex = props.data.findIndex(
        (v) => v[props.idKeyName] === active
      );
      const newIndex = props.data.findIndex((v) => v[props.idKeyName] === over);
      props.onSortEnd(arrayMove(_.cloneDeep(props.data), oldIndex, newIndex));
    }
    setActiveId(null);
  };

  const onDragStart = (event: DragEndEvent) => {
    const { id: activeId } = event.active;
    setActiveId(activeId as string);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={
        props.floating
          ? [restrictToWindowEdges]
          : [restrictToParentElement, restrictToWindowEdges]
      }
      onDragEnd={onSortEnd}
      onDragStart={onDragStart}>
      <SortableContext
        items={ids}
        disabled={props.disabled ?? false}
        strategy={
          !props?.horizontal
            ? rectSortingStrategy
            : horizontalListSortingStrategy
        }>
        {props.data.map((item, itemKey) => (
          <SortableItem
            style={
              activeId == item?.[props.idKeyName]
                ? { backgroundColor: "light", opacity: "0.5" }
                : {}
            }
            id={item[props.idKeyName]}
            dragHandleActivator={props.dragHandleActivator ?? false}
            key={`si-${item[props.idKeyName]}`}>
            {props.mapFunction(item, itemKey)}
          </SortableItem>
        ))}
        {props.overlay && activeId && (
          <DragOverlay
            style={{
              borderRadius: "5px",
              backgroundColor: "rgba(241,225,210,0.5)",
              opacity: "0.9",
            }}
            adjustScale={false}>
            {activeId ? (
              props.mapFunction(
                props.data.find((e) => e[props.idKeyName] == activeId),
                props.data.findIndex((e) => e[props.idKeyName] == activeId)
              )
            ) : (
              <></>
            )}
          </DragOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
