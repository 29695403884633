import AxiosHelper from "../AxiosHelper";
import {
  getBaseApiUrl,
  getOrganizationId,
  getProjectIdFromUrl,
} from "../helpers";
import { AxiosPromise } from "axios";
import { IGpsSimple, IGpsUserViewRequest } from "../models";
import { IGpsReportRequest } from "../../views/projects/components/gps/GpsRouteReport";

const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/gps`;

const GpsConnection = {
  form: (): AxiosPromise<any> => {
    const projectId = getProjectIdFromUrl();
    return AxiosHelper.get(`${baseUrl}/${projectId}/gps-user-view-form`);
  },
  points: (requestData: IGpsUserViewRequest): AxiosPromise<IGpsSimple[]> => {
    // IBodyResponse<IGpsSimple>
    const projectId = getProjectIdFromUrl();
    return AxiosHelper.post(
      `${baseUrl}/${projectId}/gps-user-view`,
      requestData
    );
  },
  routeReport: (requestData: IGpsReportRequest): AxiosPromise<any> => {
    const projectId = getProjectIdFromUrl();
    return AxiosHelper.post(
      `${baseUrl}/${projectId}/gps-route-report-view`,
      requestData
    );
  },
};

export default GpsConnection;
