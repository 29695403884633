import React, { FunctionComponent, useEffect, useState } from "react";
import {
  IActivityListModel,
  IFormActivityState,
  IFormTaskActivityEdit,
  IProjectSettings,
} from "../../../../utils/models";
import styles from "./styles.module.scss";
import lockClosed from "../../../../assets/svg/lock_closed.svg";
import lockOpened from "../../../../assets/svg/lock_open.svg";
import ColorCircleLabelText from "../../../../global/atoms/ColorCircleLabelText";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";
import ActivitiesConnection from "../../../../utils/connections/activities";
import { useParams } from "react-router-dom";
import { projectSettings as PS } from "../../../../utils/states";
import { DeleteSingleButton } from "../../../../utils/toggleToDeleteHelper";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACTIVITY_EDIT } from "../../../../redux/actions";
import { IRoot } from "../../../../redux/models";
import ActivityItemForm from "./ActivityItemForm";
import { IconButton, Toggle, Tooltip, Whisper } from "rsuite";
import CopyIcon from "@rsuite/icons/Copy";

import { getProjectIdFromUrl } from "utils/helpers";
import { DragHandle } from "global/atoms/SortableItem";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import CloneActivityModal from "../cloneModal/CloneActivityModal";

interface IActivityItem {
  item: IActivityListModel;
  handleSubmit: (
    stateItem: IFormActivityState,
    formItem: IFormTaskActivityEdit,
    submitCallback: () => void
  ) => void;
  handleLock: (event: any) => void;
  handleRemove: (activityId: string) => void;
  onTriggerLoad: () => void;
}

const ActivityItem: FunctionComponent<IActivityItem> = (props) => {
  const dispatch = useDispatch();
  const activityRedux = useSelector((state: IRoot) => state.activity);
  const { taskId } = useParams<{ taskId: string }>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [form, setForm] = useState<IFormTaskActivityEdit | null>(null);
  const [state, setState] = useState<IFormActivityState | null>(null);
  const [projectSettings, setProjectSettings] = useState<IProjectSettings>(PS);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [activityToggleLoading, setActivityToggleLoading] = useState(false);
  const [isActive, setActive] = useState(props.item.active as boolean);
  const [clonedData, setClonedData] = useState<IActivityListModel | null>();

  const toggleForm = () => {
    if (loading !== null) closeForm();
    else {
      // load
      dispatch({
        type: SET_ACTIVITY_EDIT,
        payload: { activeEditId: props.item.activityId },
      });
      setLoading(true);
      ActivitiesConnection.getFormTaskActivityEdit(
        taskId,
        props.item.activityId
      ).then((data) => {
        setState(data.data.model);
        setForm(data.data);
        setProjectSettings(data.data.projectSettings);
        setLoading(false);
      });
    }
  };

  const toggleActivity = () => {
    setActivityToggleLoading(true);
    ActivitiesConnection.toggleActivity(taskId, props.item.activityId)
      .then(() => {
        setActivityToggleLoading(false);
        setActive(!isActive);
        props.item.active = !isActive;
      })
      .catch(() => {
        setActivityToggleLoading(false);
      });
  };

  const closeForm = (): void => {
    setLoading(null);
    setState(null);
    setForm(null);
    dispatch({ type: SET_ACTIVITY_EDIT, payload: { activeEditId: null } });
  };

  useEffect(() => {
    if (
      loading !== null &&
      activityRedux.activeEditId &&
      activityRedux.activeEditId !== props.item.activityId
    ) {
      closeForm();
    }
  }, [activityRedux.activeEditId]);
  if (!props.item) return <></>;
  return (
    <>
      <li
        className={styles.containerActivity}
        style={{ opacity: isActive ? 1 : 0.5 }}>
        <section
          className={`${styles.containerActivityHeader} ${
            loading !== null ? styles.active : ""
          }`}>
          <div className={styles.activityHandlers}>
            {activityRedux.activeEditId === null && <DragHandle />}
            <img
              alt={"lock"}
              src={props.item.locked ? lockClosed : lockOpened}
              data-id={props.item.activityId}
              onClick={props.handleLock}
            />
            <Whisper
              trigger={"hover"}
              placement="auto"
              speaker={
                <Tooltip>
                  {isActive ? "Wyłącz aktywność" : "Włącz aktywność"}
                </Tooltip>
              }>
              <div>
                <Toggle
                  onChange={toggleActivity}
                  loading={activityToggleLoading}
                  checked={isActive}
                  checkedChildren={<CheckIcon />}
                  unCheckedChildren={<CloseIcon />}
                />
              </div>
            </Whisper>
          </div>
          <div
            style={{
              flex: 5,
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={toggleForm}>
            {props.item.name}
            <div style={{ color: "#13131352", fontSize: "0.7em" }}>
              {(props.item.scheduleDate || props.item.endDate) && (
                <span>Aktywne</span>
              )}
              {props.item.scheduleDate && (
                <span> od: {props.item.scheduleDate ?? "--"}</span>
              )}
              {props.item.endDate && (
                <span> do: {props.item.endDate ?? "--"}</span>
              )}
            </div>
          </div>
          <span style={{ flex: 3, textAlign: "center" }}>
            <ColorCircleLabelText color={props.item.activityGroupColor}>
              {props.item.activityGroupName}
            </ColorCircleLabelText>
          </span>
          <span style={{ flex: 1, textAlign: "center" }}>
            {props.item.activityTypeName}
          </span>
          <span style={{ flex: 1, textAlign: "center" }}>
            {props.item.locationsCount}
          </span>
          <span style={{ flex: 1, textAlign: "center" }}>
            {props.item.itemsCount}
          </span>
          <div className={styles.activityActions}>
            <IconButton
              icon={<CopyIcon color={"#ffaf38"} />}
              appearance={"subtle"}
              onClick={() => {
                setCopyModalOpen(true);
                setClonedData(props.item);
              }}
            />

            <DeleteSingleButton
              callback={props.handleRemove.bind(null, props.item.activityId)}
              form={null}
            />
          </div>
        </section>

        {loading === true && <SpinnerSmall />}
        {form && state && loading === false && (
          <ActivityItemForm
            form={form}
            setForm={setForm}
            projectSettings={projectSettings}
            state={state}
            // @ts-ignore
            setState={setState}
            onClose={closeForm}
            handleSubmit={props.handleSubmit}
          />
        )}
      </li>

      <CloneActivityModal
        activityName={clonedData?.name}
        open={copyModalOpen}
        projectId={getProjectIdFromUrl() ?? ""}
        taskId={taskId}
        activityId={props.item.activityId}
        handleClose={(reload: boolean) => {
          setCopyModalOpen(false);
          if (reload) props.onTriggerLoad();
        }}
      />
    </>
  );
};

export default ActivityItem;
