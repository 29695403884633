import { DatePicker, DatePickerProps, InputGroup } from "rsuite";
import React, { SetStateAction } from "react";
import dayjs from "dayjs";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";

interface PickerSlideProps extends DatePickerProps {
  setData?: SetStateAction<any>;
  reloadTrigger?: () => void;
}

const DatePickerSlider: React.FunctionComponent<PickerSlideProps> = (props) => {
  const { setData, reloadTrigger, ...restProps } = props;
  const monthly = props.format == "yyyy-MM";
  const _name = props.name ?? "";
  const _onChange =
    props.onChange ??
    ((value) => {
      setData((d) => {
        if (!d) return d;
        return {
          ...d,
          [_name]: value
            ? dayjs(value).format(props.format?.toUpperCase())
            : null,
        };
      });
      if (props?.reloadTrigger) props.reloadTrigger();
    });

  const datePicker = () => {
    return (
      <DatePicker
        style={props.style ?? { width: "200px" }}
        oneTap
        isoWeek
        showWeekNumbers
        format={props.format}
        value={props.value ? dayjs(props.value).toDate() : null}
        onChange={_onChange}
        {...restProps}
      />
    );
  };

  if (!monthly) return datePicker();
  const isReadOnly = props.disabled || props.readOnly;
  return (
    <InputGroup>
      {monthly && (
        <InputGroup.Addon
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (isReadOnly) return;
            setData((f) => ({
              ...f,
              [_name]: dayjs(f?.[_name] ?? dayjs().format("YYYY-MM"))
                .subtract(1, "month")
                .format("YYYY-MM"),
            }));
            if (props?.reloadTrigger) props.reloadTrigger();
          }}>
          <ArrowLeftLineIcon
            color={isReadOnly ? "#a3a0a0" : "#E09616"}
            style={{ fontSize: "20px" }}
          />
        </InputGroup.Addon>
      )}
      {datePicker()}
      {monthly && (
        <InputGroup.Addon
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (isReadOnly) return;
            setData((f) => ({
              ...f,
              [_name]: dayjs(f?.[_name] ?? dayjs().format("YYYY-MM"))
                .subtract(-1, "month")
                .format("YYYY-MM"),
            }));
            if (reloadTrigger) reloadTrigger();
          }}>
          <ArrowRightLineIcon
            color={isReadOnly ? "#a3a0a0" : "#E09616"}
            style={{ fontSize: "20px" }}
          />
        </InputGroup.Addon>
      )}
    </InputGroup>
  );
};

export default DatePickerSlider;
